<template>
  <v-container
    id="parametros"
    fluid
    tag="section"
    class="pa-1"
  >
    <main-header />
    <v-row>
      <v-col cols="12" class="pb-1">
        <v-card tile>
          <v-card-title>
            <v-row>
              <v-col 
                cols="12" 
                class="d-flex align-center"
                :class="{'flex-row': $vuetify.breakpoint.smAndUp}">
                <search-expand 
                  v-model="search" 
                  :load="loadingParams"
                  placeholder="Buscar parámetro"
                />
                <template v-if="$vuetify.breakpoint.smAndUp">
                  <v-btn
                    color="icono"
                    small
                    outlined
                    class="ml-md-2 ml-lg-2 ml-sm-2"
                    @click="getParametros"
                  >               
                    <v-icon 
                      size="22" 
                      :left="$vuetify.breakpoint.mdAndUp" 
                      v-text="'mdi-update'"
                    />             
                    <span v-if="$vuetify.breakpoint.mdAndUp">Actualizar</span>          
                  </v-btn> 
                  <v-btn
                    v-if="$hasPermission('administracion.bancos.crear')"
                    small
                    color="icono"
                    class="ml-2"
                    dark
                    @click="actionParam({edit:false})"
                  >
                    <v-icon
                      size="22" 
                      :left="$vuetify.breakpoint.mdAndUp" 
                    >
                      mdi-plus
                    </v-icon>
                    Crear
                  </v-btn>
                </template>
              </v-col>
            </v-row>
            <v-row v-if="$vuetify.breakpoint.xsOnly" class="mt-2">
              <v-col cols="6" class="d-flex align-center">
                <v-btn
                  color="icono"
                  small
                  block
                  outlined
                  class="ml-md-2 ml-lg-2"
                  @click="getParametros"
                >               
                  <v-icon 
                    size="22" 
                    left 
                    v-text="'mdi-update'"
                  />             
                  <span>Actualizar</span>          
                </v-btn> 
              </v-col>
              <v-col cols="6" class="d-flex align-center">
                <v-btn
                  small
                  color="icono"
                  class="ml-md-2 ml-lg-2"
                  dark
                  block
                  @click="actionParam({edit:false})"
                >
                  <v-icon
                    size="22" 
                    left
                  >
                    mdi-plus
                  </v-icon>
                  Crear
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <v-divider />
          <v-card-text class="pa-0">
            <params-data
              :datos="paramsData"
              :search="search"
              :loading-data="loadingParams"   
              @update="actionParam"   
              @procesado="getParametros"        
            />                
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <modal-param
      v-model="showGestion" 
      :edit="editParam" 
      :data="paramData" 
      @procesado="getParametros"
    />
  </v-container>
</template>
<script>
import { getParams } from '@/services/params'
export default {
  name: 'Bancos',
  components: {
    ParamsData: () => import(
      /* webpackChunkName: "params-data" */
      './ParamsData.vue'
    ),
    ModalParam: () => import(
      /* webpackChunkName: "modal-param" */
      './ModalParam.vue'
    )
  },
  data() {
    return {
      paramsData: [],
      loadingParams: false,
      loadSesion: false,
      search: '',
      editParam: false,
      showGestion: false,
      paramData: {},
    }
  },
  created() {
    this.getParametros()
  },
  methods:{
    async getParametros () {
      try {
        this.loadingParams = true;
        this.paramsData = await getParams()
      } catch (error) {
        this.$root.$showAlert(
          'Lo siento, hubo un error al intentar obtener los parámetros.',
          'error'
        )
      }
      this.loadingParams = false
    },
    actionParam ({edit, item=null }) {
      this.paramData = { ...item }
      this.editParam = edit
      this.showGestion = true
    },
  },
}
</script>
