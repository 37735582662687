<template>
  <v-container
    id="bancos"
    fluid
    tag="section"
    class="pa-1"
  >
    <main-header />
    <v-row>
      <v-col cols="12" class="pb-1">
        <v-card tile>
          <v-card-title>
            <v-row>
              <v-col 
                cols="12" 
                class="d-flex align-center"
                :class="{'flex-row': $vuetify.breakpoint.smAndUp}">
                <search-expand 
                  v-model="search" 
                  :load="loadingPermisos"
                  placeholder="Buscar permiso"
                />
                <template v-if="$vuetify.breakpoint.smAndUp">
                  <v-btn
                    color="icono"
                    small
                    outlined
                    class="ml-md-2 ml-lg-2 ml-sm-2"
                    @click="getPermisos"
                  >               
                    <v-icon 
                      size="22" 
                      :left="$vuetify.breakpoint.mdAndUp" 
                      v-text="'mdi-update'"
                    />             
                    <span v-if="$vuetify.breakpoint.mdAndUp">Actualizar</span>          
                  </v-btn> 
                  <v-btn
                    v-if="$hasPermission('configuracion.permisos.crear')"
                    small
                    color="icono"
                    class="ml-2"
                    dark
                    @click="crearPermiso"
                  >
                    <v-icon
                      size="22" 
                      :left="$vuetify.breakpoint.mdAndUp" 
                    >
                      mdi-plus
                    </v-icon>
                    Crear
                  </v-btn>
                </template>
              </v-col>
            </v-row>
            <v-row v-if="$vuetify.breakpoint.xsOnly" class="mt-2">
              <v-col cols="6" class="d-flex align-center">
                <v-btn
                  color="icono"
                  small
                  block
                  outlined
                  @click="getPermisos"
                >               
                  <v-icon 
                    size="22" 
                    left 
                    v-text="'mdi-update'"
                  />             
                  <span>Actualizar</span>          
                </v-btn> 
              </v-col>
              <v-col cols="6" class="d-flex align-center">
                <v-btn
                  small
                  color="icono"
                  dark
                  block
                  @click="crearPermiso"
                >
                  <v-icon
                    size="22" 
                    left 
                  >
                    mdi-plus
                  </v-icon>
                  Crear
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <v-divider />
          <v-card-text class="pa-0">
            <permisos-data
              :datos="Permisos"
              :search="search"
              :loading-data="loadingPermisos"   
              @update="editMotivo"           
            />                
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <modal-permiso
      v-model="showGestion" 
      :action="actionMot" 
      :data="permisoData" 
      @procesado="getPermisos"
    />
  </v-container>
</template>
<script>
export default {
  name: 'Permisos',
  components: {
    PermisosData: () => import(
      /* webpackChunkName: "permisos-data" */
      './PermisosData.vue'
    ),
    ModalPermiso: () => import(
      /* webpackChunkName: "modal-permiso" */
      './ModalPermiso.vue'
    )
  },
  data() {
    return {
      Permisos: [],
      loadingPermisos: false,
      search: '',
      actionMot: 'crear',
      showGestion: false,
      permisoData: {},
    }
  },
  created() {
    this.getPermisos();
  },
  methods:{
    async getPermisos() {
      try {
        this.loadingPermisos = true;
        const { data } = await this.$api.get('users/permisos');

        this.Permisos = data.data;
      } catch (error) {
        this.$root.$showAlert(
          'Lo siento, hubo un error al intentar obtener los permisos.',
          'error'
        )
      }
      this.loadingPermisos = false;
    },
    crearPermiso() {
      this.permisoData = {}
      this.actionMot = 'crear'
      this.showGestion = true
    },
    editMotivo(data) {
      this.permisoData = {}
      this.actionMot = 'editar'
      this.permisoData = {...data}
      this.showGestion = true
    },
  },
}
</script>
