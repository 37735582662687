<template>
  <v-container
    id="inventario"
    fluid
    tag="section"
    class="pa-1"
  >
    <main-header />
    <v-row>
      <v-col cols="12" class="pb-1">
        <v-card tile color="transparent" flat>
          <v-card-title class="white">
            <v-row>
              <v-col cols="12" sm="12" md="6" class="d-flex align-center">
                <v-text-field
                  v-model="search"
                  placeholder="Buscar por código, nombre, ect.."
                  dense
                  filled
                  hide-details
                  class="theme-custom rounded-lg"
                >
                  <template v-slot:append>
                    <v-icon color="secondary">mdi-magnify</v-icon>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="12" 
                sm="12" 
                md="6" 
                class="d-flex align-center"
                :class="{'justify-end': $vuetify.breakpoint.mdAndUp, 'justify-center': $vuetify.breakpoint.smAndDown }"
              >     
                <v-btn-toggle
                  v-model="toggle_view"
                  mandatory
                  dense
                  color="primary"
                  v-if="$vuetify.breakpoint.mdAndUp"
                >
                  <v-btn 
                    small 
                    class="px-2"
                    value="list">
                    <v-icon color="blue-grey" size="20">mdi-format-list-bulleted-type</v-icon>
                  </v-btn>
                  <v-btn 
                    small 
                    class="px-2"
                    value="grid">
                    <v-icon color="blue-grey" size="20">mdi-view-grid-outline</v-icon>
                  </v-btn>                  
                </v-btn-toggle>
                <v-divider class="mx-2" vertical inset v-if="$vuetify.breakpoint.mdAndUp"></v-divider>
                 <!-- <v-btn-toggle
                    v-model="moneda"
                    dense
                    color="primary"
                    @change="getArticulos"          
                  >
                    <v-btn small value="bolivares" :disabled="load_art">
                      <span class="font-weight-bold display">
                        Bs.
                      </span>
                    </v-btn>

                    <v-btn small value="dolares" :disabled="load_art">
                      <span class="font-weight-bold display">
                        $
                      </span>
                    </v-btn>
                </v-btn-toggle> 
                <v-divider class="mx-2" vertical inset></v-divider> -->
                <botton-pv
                  v-model="selected_pventa"
                  :lista="items_pventas"
                />
                <v-divider
                  class="mx-2"
                  vertical
                  inset
                />
                <columns-views
                  :columns="headers"
                  v-if="$vuetify.breakpoint.mdAndUp"
                />
                <v-divider
                  class="mx-2"
                  vertical
                  inset
                  v-if="$vuetify.breakpoint.mdAndUp"
                />
                <v-btn
                  small
                  color="primary"
                  @click="filters = !filters"
                >
                  <v-icon left>mdi-tune</v-icon>
                  Filtros
                </v-btn>
              </v-col>
            </v-row>            
          </v-card-title>
          <v-card-text class="pa-0">
            <v-fade-transition>
              <v-row  
                v-if="filters_view.length > 0"
                class="py-1 px-4 white ma-0"
              >
                <v-col cols="12" class="d-flex flex-column pt-0">
                  <v-divider class="mb-3" />
                  <span class="text-h6 font-weight-medium primary--text">Filtros Aplicados</span>
                  <div class="py-3">
                    <v-chip 
                      v-for="(f,i) in filters_view" 
                      :key="'filtrosa-'+i" 
                      class="mr-2"
                      color="blue-grey lighten-5"
                      small
                      label
                      pill
                    >
                    <span class="font-weight-medium"> {{f}} </span>
                    </v-chip>
                  </div>
                </v-col>
              </v-row>
            </v-fade-transition>
            <v-row>               
              <v-col cols="12" class="pb-0">
                <list-table
                  :headers="headers"
                  :data="itesmProducts"
                  :search="search"
                  :loading="load_art"
                  :view="toggle_view"
                  :moneda="simbolo"
                  :sort="order"
                  :OrderDesc="orderDesc"
                  @changeOrder="getOrder"
                  :pventa="selected_pventa"
               />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <avanced-filters
      v-model="filters"
      :orders="headers"
      :items-categories="apiCategories"
      :items-subcatg="apiSubcategories"
      @filtrar="FiltersAplicated"
    />
  </v-container>
</template>
<script>
import ListTable from './Components/ListaTable'
import AvancedFilters from './Components/AvancedFilters'
import ColumnsViews from '@/widgets/ColumnsViews'
import BottonPv from './Components/BottonPV'
export default {
  name: 'Inventario',
  components: {
    ListTable,
    ColumnsViews,
    AvancedFilters,
    BottonPv,
  },
  data () { 
    return {
      show: false,
      init: false,
      filters: false,
      filters_view: [],
      filtros_val: {
        lineas: '',
        sub_lineas: '',
      },
      colors_pventa: {
        prec_vta2: '#24404E',
        prec_vta3: '#00CAE3',
        prec_vta4: '#009688',
        prec_vta5: '#795548',
      },
      search: '',
      toggle_view: 'list',
      articulos: [],
      apiCategories: [],
      apiSubcategories: [],
      load_art: false,
      pagination: {
        current: 1,
        per_page: null,
        totalArt: null,
      },
      orderDesc: false,
      sort: 'art_des',
      order: 'co_art',
      moneda: 'dolares',
      headers: [
        {
          text: 'Código',
          align: 'start',
          value: 'co_art',
          class: 'header-data pr-0',
          active: true,
          sortable: true,
          width: '105',
        },
        { text: 'Artículo', value: 'art_des', class: 'header-data', active: true, sortable: true, },
        { text: 'Precio', value: 'prec_vta3', class: 'header-data', active: true, sortable: true, width: '200', align: 'center' },
        { text: 'Stock', value: 'stock_act', class: 'header-data', active: true, sortable: true, },
        { text: '', value: 'actions', class: 'header-data', active: true, sortable: false, },
      ],
      selected_pventa: 'prec_vta3',
      items_pventas: [
        { name: 'Precio de venta 3', value: 'prec_vta3' },
        { name: 'Precio de venta 4', value: 'prec_vta4' },
        { name: 'Precio de venta 5', value: 'prec_vta5' },
      ]
    }
  },
  created() {    
    this.getArticulos();
  },
  methods: {
    actionPagination(page) {
      this.pagination.current = page;
      this.$router.replace({query: {pag: this.pagination.current}});
      this.getArticulos();
    },
    async getArticulos() {
      try {
        this.load_art = true;
        this.articulos = [];
        const { data } = await this.$api.get(`articulos?moneda=${this.moneda}&text=${this.search}`);

        this.articulos = data.data || []
        this.apiCategories = data.lin_art || []
        this.apiSubcategories = data.sub_lin || []
      } catch (error) {
        this.$root.$showAlert(
          'Lo siento, hubo un error al intentar obtener los Productos.',
          'error'
        )
      }
      this.load_art = false;        
    },
    getOrder(val) {
      this.orderDesc = val;
      this.pagination.current = 1;
      this.getArticulos();
    },
    actionOrder() {
      this.orderDesc = false;
      this.pagination.current = 1;
      this.getArticulos();
    },
    FiltersAplicated(filtros) {
      this.filtros_val.lineas = filtros.lineas;
      this.filtros_val.sub_lineas = filtros.sub_lineas;

      this.filters_view = []
      if(this.filtros_val.lineas.co_lin)
        this.filters_view.push(`Linea: ${this.filtros_val.lineas.lin_des}`)

      if(this.filtros_val.sub_lineas.length) {
        this.filtros_val.sub_lineas.forEach(element => {
          this.filters_view.push(`Sub-Linea: ${element.subl_des}`)
        });
      }
    },
  },
  computed: {
    simbolo() {
      return this.moneda === 'dolares' ? '$' : 'Bs.'
    },
    itesmProducts () {
      if (!this.filtros_val.lineas.co_lin) return this.articulos

      return this.articulos.filter(item => {
        if (!this.filtros_val.sub_lineas.length) return item.co_lin === this.filtros_val.lineas.co_lin
        else return item.co_lin === this.filtros_val.lineas.co_lin && this.filtros_val.sub_lineas.some(sub => sub.co_subl === item.co_subl)
      })
    },    
  },
}
</script>