<template>
  <div>
    <v-row class="mb-8">
      <v-col cols="12" class="pt-5">
        <v-card tile flat>
          <v-card-text class="pa-0 ma-0">
            <v-row class="py-1 px-4" :class="{'flex-column-reverse': $vuetify.breakpoint.xsOnly}">
              <v-col cols="5" class="d-flex align-center">
                <search-expand
                  v-model="search"
                  placeholder="Buscar plantilla"
                />
                <!-- <v-btn
                color="primary"
                class="py-0 ml-2"
                :class="{'px-2': $vuetify.breakpoint.smAndDown}"
                dark
                icon
              >
                <v-icon
                  size="24"
                  v-text="'mdi-tune'"
                />
              </v-btn> -->
              </v-col>
              <v-col cols="7" class="d-flex align-center justify-end spacing-2">
                <!-- v-if="$hasPermission('mensajeria.enviar')" -->
                <v-btn
                  color="icono"
                  class="py-0"
                  :class="{'px-2': $vuetify.breakpoint.smAndDown}"
                  dark
                  small
                  depressed
                  style="height:35px !important; "
                  @click="crearPlantilla"
                >
                  <v-icon
                    size="24"
                    left
                    v-text="'mdi-account-multiple-plus'"
                  />
                  <span v-if="$vuetify.breakpoint.mdAndUp">Nueva Plantilla</span>
                </v-btn>

              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="pb-0">
                <v-divider />
              </v-col>
              <v-col cols="12" class="py-0">
                <data-plantilla
                  :search="search"
                  :dataTemplate="templates"
                  :loading="loading"
                  @edit="editPlantilla"
                  @deleted="getTemplatesData"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <create-edit
      v-model="showManagement"
      :data="templateData"
      :action="action"
      @procesado="getTemplatesData"
    />
  </div>
</template>
<script>
import { getTemplates } from '@/services/mensajeria'

export default {
  name: 'Plantillas',
  components: {
    DataPlantilla: () => import(
      /* webpackChunkName: 'data-plantilla' */
      './Components/Plantillas/DataPlantilla.vue'
    ),
    CreateEdit: () => import(
      /* webpackChunkName: 'create-edit' */
      './Components/Plantillas/CreateEdit.vue'
    ),
  },
  data: () => ({
    search: '',
    templates: [],
    loading: false,
    showManagement: false,
    templateData: null,
    action: 'crear'
  }),
  created() {
    this.getTemplatesData()
  },
  methods: {
    async getTemplatesData () {
      this.loading = true
      try {
        const data  = await getTemplates()
        this.templates = data
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },
    crearPlantilla() {
      this.templateData = {}
      this.action = 'crear'
      this.showManagement = true
    },
    editPlantilla(data) {
      this.templateData = {}
      this.action = 'editar'
      this.templateData = {...data}
      this.showManagement = true
    },
  }
}
</script>
