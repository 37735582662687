<template>
   <v-container
    id="tareas"
    fluid
    tag="section"
    class="pa-1"
  >
    <main-header />
    <v-row>
      <template v-for="(task,i) in tasks">
        <v-col
          :key="'cardTask-'+i"
          cols="12"
          sm="6"
          md="4"
        >
          <card-task :details="task" @update="getTasksLogs" />
        </v-col>
      </template>
      <v-col
        :key="'cardTask-'+ (tasks.length + 1)"
        cols="12"
        sm="6"
        md="4"
      >
        <card-task
          :details="{
            action: 'reportes',
            label: 'reportes',
            title: 'Guardar visitas',
            icon: 'mdi-content-save-all-outline' }"
          @update="getTasksLogs"
        >
          <template v-slot:switch>
            <v-switch
              v-model="showDateModal"
              inset
              dense
              hide-details
              class="mt-0 mr-n2"
              :disabled="showDateModal"
            />
          </template>
        </card-task>
      </v-col>
      <v-col
        :key="'cardTaskIP-'+ (tasks.length + 1)"
        cols="12"
        sm="6"
        md="4"
      >
        <task-iniciar-pagos
          @update="getTasksLogs"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12 mt-3">
        <v-card tile class="elevation-0">
          <v-card-title
            class="d-flex"
            :class="{'justify-space-between': $vuetify.breakpoint.smAndUp, 'flex-column align-start': $vuetify.breakpoint.xsOnly }"
          >
            <h3 class="blue-grey--text">Historial de Tareas</h3>
            <div :class="{ 'mt-3': $vuetify.breakpoint.xsOnly }">
             <consultar-rango-fecha @consultar="getRangeTasksLog" />
            </div>
          </v-card-title>
          <v-card-text>
            <logs-table :items="logsTasks" :loading="loadTask" />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <modal-fecha
      v-model="showDateModal"
      @updateLogs="getTasksLogs"
    />
  </v-container>
</template>
<script>
import { getLogsTasks } from '@/services/tasks.js'
import moment from 'moment'
const ITEMS_TASKS = [
  {
    action: 'actualizar',
    label: 'actualizar',
    task: 'clientes',
    title: 'Clientes',
    icon: 'mdi-account-group-outline',
  },
  {
    action: 'actualizar',
    label: 'actualizar',
    task: 'documfinan',
    title: 'facturas financieras',
    icon: 'mdi-text-box-check-outline',
  },
  {
    action: 'actualizar',
    label: 'actualizar',
    task: 'documfiscal',
    title: 'facturas fiscales',
    icon: 'mdi-file-refresh-outline',
  },
  {
    action: 'actualizar',
    label: 'actualizar',
    task: 'controlpedidos',
    title: 'control pedidos',
    icon: 'mdi-package-variant-closed',
  },
  {
    action: 'actualizar',
    label: 'actualizar',
    task: 'vendedores',
    title: 'vendedores',
    icon: 'mdi-account-multiple-outline',
  },
  // {
  //   label: 'tarea',
  //   action: null,
  //   task: 'actualizarvisitasprofit',
  //   title: 'Actualizar Visitas Profit',
  //   icon: 'mdi-home-city',
  // },
  {
    action: 'actualizar',
    label: 'actualizar',
    task: 'iniciarvisitas',
    title: 'Iniciar Visitas',
    icon: 'mdi-map-marker-up',
    method: 'post'
  },
  {
    action: 'actualizar',
    label: 'actualizar',
    task: 'actualizardescuen',
    title: 'Descuento',
    icon: 'mdi-margin mdi-rotate-90',
  },
  {
    action: 'actualizar',
    label: 'actualizar',
    task: 'actualizarstalmac',
    title: 'Stock',
    icon: 'mdi-form-select',
  },
  {
    action: 'actualizar',
    label: 'actualizar',
    task: 'actualizarlinart',
    title: 'Linea',
    icon: 'mdi-file-table-outline',
  },
  {
    action: 'actualizar',
    label: 'actualizar',
    task: 'actualizarsublin',
    title: 'Sub-Linea',
    icon: 'mdi-file-table-box-multiple-outline',
  },
  {
    action: 'actualizar',
    label: 'actualizar',
    task: 'actualizarartfinan',
    title: 'Artículos',
    icon: 'mdi-package-variant',
  },
  // {
  //   action: 'reportes',
  //   title: 'Guardar visitas',
  //   icon: 'mdi-content-save-all-outline',
  //   query: {
  //     fecha: '2022-01-15',
  //   },
  // },
]
export default {
  name: 'Tasks',
  components: {
    CardTask: () => import(
      /* webpackChunkName: "card-task" */
      './cardTask.vue'
    ),
    TaskIniciarPagos: () => import(
      /* webpackChunkName: "task-iniciar-pago" */
      './Especiales/taskIniciarPagos.vue'
    ),
    LogsTable: () => import(
      /* webpackChunkName: "logs-table" */
      './LogsTable.vue'
    ),
    ModalFecha: () => import(
      /* webpackChunkName: "modal/fecha" */
      './ModalFecha.vue'
    ),
    ConsultarRangoFecha: () => import (
      /* webpackChunkName: "consultar-rango-fecha" */
      '@/widgets/ConsultarRangoFecha.vue'
    ),
  },
  data: () => ({
    loadTask: false,
    showDateModal: false,
    logsTasks: [],
    rangeLog: {
      ini: moment().startOf('week').day(1).format("YYYY-MM-DD"),
      fin: moment().format('YYYY-MM-DD'),
    },
  }),
  computed: {
    tasks () {
      return ITEMS_TASKS
    },
  },
  created () {
    this.getTasksLogs()
  },
  methods: {
    async getTasksLogs () {
      this.loadTask = true
      try {
        const { data } = await getLogsTasks({ dateIni: this.rangeLog.ini, dateEnd: this.rangeLog.fin })
        this.logsTasks = data
      } catch (error) {
        this.$root.$showAlert(
          'Lo sentimos, hubo un error al intentar obtener el Historial del Servidor.',
          'error',
        )
      }
      finally {
        this.loadTask = false
      }
    },
    getRangeTasksLog (range) {
      this.rangeLog = { ...range }
      this.getTasksLogs()
    },
  },
}
</script>
