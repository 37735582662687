<template>
  <div>
    <v-row class="mb-8">
      <v-col cols="12" class="pt-5">
         <v-card tile flat>
           <v-card-text class="pa-0 ma-0">
            <v-row class="py-1 px-4" :class="{'flex-column-reverse': $vuetify.breakpoint.xsOnly}">
              <v-col cols="5" class="d-flex align-center">
                <search-expand
                  v-model="searchSMS"
                  placeholder="Buscar Mensaje"
                />
                <!-- <v-btn
                color="primary"
                class="py-0 ml-2"
                :class="{'px-2': $vuetify.breakpoint.smAndDown}"
                dark
                icon
              >
                <v-icon
                  size="24"
                  v-text="'mdi-tune'"
                />
              </v-btn> -->
              </v-col>
              <v-col cols="7" class="d-flex align-center justify-end spacing-2">
                <consultar-rango-fecha @consultar="" />
                <v-btn
                  v-if="$hasPermission('mensajeria.enviar')"
                  color="icono"
                  class="py-0"
                  :class="{'px-2': $vuetify.breakpoint.smAndDown}"
                  dark
                  small
                  depressed
                  style="height:30px !important; "
                  @click="showSend = true"
                >
                  <v-icon
                    size="24"
                    v-text="'mdi-plus'"
                  />
                  <!-- <span v-if="$vuetify.breakpoint.mdAndUp">Nuevo</span> -->
                </v-btn>

              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="pb-0">
                <v-divider />
              </v-col>
              <v-col cols="12" class="py-0">
                <data-messages
                  :search="searchSMS"
                  :data-sms="messages"
                  :loading="loading"
                  @detailsMessage="showDetailsMessage"
                />
              </v-col>
            </v-row>
          </v-card-text>
         </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Settings</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              dark
              text
              @click="dialog = false"
            >
              Save
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
      </v-card>
    </v-dialog>
    <view-message v-model="showMessage" :details="message" />
    <send
      v-model="showSend"
      @sended="getMessagesData"
    />
  </div>
</template>
<script>
const msgDefautl = () => ({
  co_cli: '',
  destinatario: '',
  fecha_hora: '',
  id: '',
  mensaje: '',
  mensaje_id: '',
  nombre_cliente: '',
  remitente: '',
  status_code: '',
  status_text: '',
})

export default {
  name: 'mensajeria',
  components: {
    DataMessages: () => import(
      /* webpackChunkName: 'data-messages' */
      './Components/DataMesagges.vue'
    ),
    ViewMessage: () => import(
      /* webpackChunkName: 'view-message' */
      './Components/ViewMessage.vue'
    ),
    Send: () => import(
      /* webpackChunkName: 'send' */
      './Send.vue'
    ),
    ConsultarRangoFecha: () => import(
      /* webpackChunkName: "consultar-rango-fecha" */
      '@/widgets/ConsultarRangoFecha.vue'
    ),

  },
  data: () => ({
    searchSMS: '',
    dialog: false,
    showMessage: false,
    showSend: false,
    message: msgDefautl(),
    messages: [],
    loading: false,
  }),
  created () {
    this.getMessagesData()
  },
  methods: {
    showDetailsMessage (message) {
      console.log(message)
      this.showMessage = true
      this.message = { ...message }
    },
    async getMessagesData () {
      this.loading = true
      try {
        const { data } = await this.$api.get('mensajes')
        this.messages = data.data
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    }

  }
}
</script>
<style>
.spacing-2 {
  gap: 12px;
}
</style>
