<template>
  <!-- <v-app> -->
    <v-main>
      <v-row no-gutters>
        <v-col cols="12" md="6" lg="6" class="ma-0 pa-0">
          <v-container class="pa-0" :class="{'d-flex': !$vuetify.breakpoint.mobile, 'light': $vuetify.breakpoint.mobile, 'white': !$vuetify.breakpoint.mobile}" style="height:100vh">
            <v-row no-gutters v-if="$vuetify.breakpoint.mobile">
              <v-col cols="12" class="ma-0 pa-0 primary white--text">
                <v-img
                  :src="require('@/assets/BG_LOGIN.jpg')"
                  class="white--text"
                  gradient="rgba(22, 67, 96, .2), rgba(22, 67, 96, .7)"
                  :aspect-ratio="1"
                  position="initial"
                  height="12rem">
                </v-img>
              </v-col>
            </v-row>
            <v-row :class="{'align-center':!$vuetify.breakpoint.mobile}" class="ma-0">
              <v-col cols="12" sm="10" md="9" lg="9" class="mx-auto px-4">
                <v-card :flat="!$vuetify.breakpoint.mobile" :class="{'mt-n15': $vuetify.breakpoint.mobile}">
                  <v-row class="py-8 mx-0">
                    <v-col cols="12">
                      <v-img
                        :src="require('@/assets/IDENTIDAD.png')"
                        max-width="200"
                        class="mx-auto"
                      />
                    </v-col>
                  </v-row>
                  <v-card-text>
                    <validation-observer ref="LOGIN_FORM">
                      <v-row>
                        <v-col cols="12" class="mb-0 pb-0">
                          <validation-provider name="Correo Electrónico" vid="email" rules="required|email" v-slot="{ errors }">
                            <v-text-field
                              v-model="credentials.email"
                              outlined
                              label="Correo Electrónico"
                              clearable
                              :error-messages="errors[0]"
                              :disabled="LoadingLogin"
                            >
                              <template slot="prepend-inner">
                                <v-icon color="primary">mdi-account</v-icon>
                              </template>
                            </v-text-field>
                          </validation-provider>
                        </v-col>
                        <v-col cols="12" class="mb-0 py-0">
                          <validation-provider name="Contraseña" vid="password" rules="required" v-slot="{ errors }">
                            <v-text-field
                              v-model="credentials.password"
                              outlined
                              :type="ShowPassword ? 'text' : 'password'"
                              label="Contraseña"
                              prepend-inner-icon="mdi-lock"
                              :append-icon="ShowPassword ? 'mdi-eye' : 'mdi-eye-off'"
                              @click:append="ShowPassword = !ShowPassword"                              
                              clearable
                              :error-messages="errors[0]"
                              :disabled="LoadingLogin"
                              @keyup.enter="login"
                            >
                              <template slot="prepend-inner">
                                <v-icon color="primary">mdi-lock</v-icon>
                              </template>
                            </v-text-field>
                          </validation-provider>
                        </v-col>
                      </v-row>                     
                      <v-row>
                        <v-col cols="12" sm="11" md="8" lg="8" class="text-center mx-auto">
                        <v-btn
                            tile
                            color="secondary"
                            class="px-12"
                            block
                            @click="login"
                            :loading="LoadingLogin"
                          >
                            Acceder
                            <v-icon right>
                              mdi-arrow-right
                            </v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </validation-observer>
                    <!-- <v-row>
                      <v-col cols="12" class="my-4 text-center">
                       <a href="/" @click.prevent="$router.push({path: '/auth/resetear-contrasena'})" class="text-decoration-none text-uppercase text-caption font-weight-regular primary--text">
                        <v-icon left color="primary" class="mt-n2">mdi-account-cog-outline</v-icon>
                        <span>Autogestión de usuario</span> 
                      </a>
                      </v-col>
                    </v-row> -->
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
        <v-col cols="6" class="ma-0 pa-0 primary white--text" style="height:100vh" v-if="!$vuetify.breakpoint.mobile">
          <v-img
          :src="require('@/assets/BG_LOGIN.jpg')"
          class="white--text"
          gradient="rgba(22, 67, 96, .2), rgba(22, 67, 96, .7)"
          :aspect-ratio="1"
          position="center"
          height="100vh">
            <!-- <v-container fluid class="d-flex" style="height:100vh">
              <v-row class="align-center">
                <v-col cols="9" class="mx-auto">
                  <h1>Sistema Administrativo</h1>
                </v-col>
              </v-row>
            </v-container> -->
          </v-img>
        </v-col>        
      </v-row>
      <material-snackbar 
        type="error"
        v-model="error.active" 
      >{{error.message}}</material-snackbar>
    </v-main>
  <!-- </v-app> -->
</template>
<script>
export default {
  name: 'Login',
  data(){
    return {
      credentials: {
        email: '',
        password: '',
        web: true,
      },
      ShowPassword: false,
      LoadingLogin: false,
      error:{
        active: false,
        message: '',
      },
    }
  },
  methods:{
    async login() {
      const valid = await this.$refs.LOGIN_FORM.validate();
      if(valid) {
        this.LoadingLogin = true;
        this.$store.dispatch('user/login',this.credentials).then(response => {						
          this.$router.push({ path: '/'});
          this.LoadingLogin = false;
        }).catch(e => {
          this.error = {
            active: true,
            message: e.response ? e.response.data.message[0] : 'Lo sentimos, hubo un error al intentar conectar con el Servidor.'
          };
          this.LoadingLogin = false;
        });						
      }
		},
  }
  
}
</script>