<template>
  <v-container
    id="clientes-ubicacion"
    tag="section"
    class="pa-0"
    style="max-width: 100%;"
  >
    <div v-if="toggle_options_map" class="v-overlay v-overlay--active theme--dark" style="z-index: 5;"><div class="v-overlay__scrim" style="opacity: 0.46; background-color: rgb(33, 33, 33); border-color: rgb(33, 33, 33);"></div><div class="v-overlay__content"></div></div>
    <v-row no-gutters>
      <v-col cols="12" class="mb-4" v-if="$vuetify.breakpoint.mobile">
        <v-btn color="blue-grey" small depressed dark @click="toggle_options_map = true">
          <v-icon left>mdi-tune</v-icon>
          Información
        </v-btn>
      </v-col>
      <v-col
        cols="11"
        sm="12"
        md="5"
        class="content-scroll"
        :class="{'open-info-map': toggle_options_map && $vuetify.breakpoint.mobile, 'close-info-map': !toggle_options_map && $vuetify.breakpoint.mobile}"
        :style="height_mapa"
        style="overflow:auto;transition: all 0.3s ease-out"
        v-show="showInfoData"
      >
        <v-card flat tile class="fill-height">
          <v-card-title class="primary--text grey lighten-5 py-0">
            <div
              v-if="$vuetify.breakpoint.mobile"
              class="d-flex justify-space-between align-center fill-width pt-1"
            >
              <span class="font-weight-medium text-h5">Información</span>
              <v-btn icon @click="mobileClose">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
            <v-tabs
              v-model="tabsUbication"
              show-arrows
              background-color="grey lighten-5"
              slider-color="blue-grey lighten-4"
              slider-size="3"
            >
              <v-tab>
                <span class="text-h5 font-weight-medium text-capitalize">
                  <v-icon
                    size="20"
                    v-text="'mdi-tune'"
                  ></v-icon>
                  Filtros
                </span>
              </v-tab>
              <v-tab>
                <span class="text-h5 font-weight-medium text-capitalize">
                  <v-icon
                    size="20"
                    v-text="'mdi-account-tie'"
                  ></v-icon>
                  Clientes
                </span>
              </v-tab>
              <v-tab>
                <span class="text-h5 font-weight-medium text-capitalize">
                  <v-icon
                    size="20"
                    v-text="'mdi-account-supervisor-circle'"
                  ></v-icon>
                  Vendedores
                </span>
              </v-tab>
            </v-tabs>
          </v-card-title>
          <v-tabs-items v-model="tabsUbication" style="z-index:200; hight:100%">
            <!-- TAB FILTROS -->
            <v-tab-item>
              <lista-estatus :statusClients="active_status" />
              <v-divider class="my-3"></v-divider>
              <v-row class="px-5">
                <v-col cols="12" class="d-flex flex-column">
                  <span class="text-h6 py-3 font-weight-bold blue-grey--text text--lighten-2">Linea</span>
                  <v-autocomplete
                    v-model="filters.line"
                    :items="lines"
                    item-text="lin_des"
                    item-value="co_lin"
                    dense
                    outlined
                    clearable
                    class="field-white custom-chip"
                    hide-details
                    label="Seleccione"
                    deletable-chips
                    multiple
                    small-chips
                    @change="resetFilters"
                    />
                </v-col>
              </v-row>
              <v-row class="px-5 pb-6 pt-0">
                <!-- <v-col cols="12">
                </v-col> -->
                <v-col cols="12" class="pb-0">
                  <v-switch
                    v-model="filters.sales_month"
                    color="info"
                    :disabled="filters.line.length === 0"
                    hide-details
                  >
                    <template v-slot:label>
                      <span class="caption font-weight-medium mx-4">
                        <v-icon
                          color="info"
                          size="20"
                          class="mr-1"
                          v-text="'mdi-calendar-check'"
                        ></v-icon>
                        Ventas del Mes
                      </span>
                    </template>
                  </v-switch>
                </v-col>
                <v-col cols="12" class="pb-0">
                  <v-switch
                    v-model="filters.sales_three_months"
                    color="info"
                    :disabled="filters.line.length === 0"
                    hide-details
                  >
                    <template v-slot:label>
                      <span class="caption font-weight-medium mx-4">
                        <v-icon
                          color="info"
                          size="20"
                          class="mr-1"
                          v-text="'mdi-calendar-arrow-right'"
                        ></v-icon>
                        Ventas en tres meses
                      </span>
                    </template>
                  </v-switch>
                </v-col>
                <v-col cols="12" class="pb-0">
                  <v-switch
                    v-model="filters.sales_more_three_months"
                    color="info"
                    :disabled="filters.line.length === 0"
                    hide-details
                    >
                    <template v-slot:label>
                      <span class="caption font-weight-medium mx-4">
                        <v-icon
                          color="info"
                          size="20"
                          class="mr-1"
                          v-text="'mdi-calendar-arrow-left'"
                        ></v-icon>
                        Ventas mayor a tres meses
                      </span>
                    </template>
                  </v-switch>
                </v-col>
                <v-col cols="12" class="pb-0">
                  <v-switch
                    v-model="filters.no_sales"
                    color="info"
                    :disabled="filters.line.length === 0"
                    hide-details
                  >
                    <template v-slot:label>
                      <span class="caption font-weight-medium mx-4">
                        <v-icon
                          size="20"
                          class="mr-1"
                          color="info"
                          v-text="'mdi-calendar-remove'"
                        ></v-icon>
                        Sin ventas
                      </span>
                    </template>
                  </v-switch>
                </v-col>
              </v-row>
            </v-tab-item>

            <!-- TAB CLIENTES -->
            <v-tab-item>
              <v-card-text class="px-0">
                <v-row>
                  <v-col cols="12" class="pt-4 pb-6 px-8">
                    <search-expand
                      v-model="search_cliente"
                      :load="loading"
                      placeholder="Buscar Cliente"
                    />
                  </v-col>
                </v-row>
                <v-data-iterator
                  :items="itemsClients"
                  :loading="loading"
                  :search="search_cliente"
                  :footer-props="{itemsPerPageText: 'Mostrar'}"
                  class="mb-0 pb-0"
                >
                  <template v-slot:default="props">
                    <v-expansion-panels focusable hover flat accordion>
                      <template v-for="(cliente,i) in props.items">
                        <v-expansion-panel
                          active-class="blue-grey lighten-5"
                          :readonly="!cliente.status_cuenta_vencida"
                          :key="'clientMap'+i"
                          @click="centerCliente(cliente)"
                        >
                          <v-expansion-panel-header
                            class="px-2 py-0"
                            :hide-actions="!cliente.status_cuenta_vencida"
                          >
                            <v-list class="py-0 transparent" dense>
                              <v-list-item class="py-2">
                                <v-list-item-icon class="mr-2 mb-1 d-flex align-self-center">
                                  <v-icon
                                    :color="cliente.status_cuenta_vencida ? status.rojo : cliente.status_cuenta_dolar ? status.amarillo : status.verde"
                                    v-text="cliente.lat != null ? 'mdi-map-marker' : 'mdi-map-marker-off'"
                                  ></v-icon>
                                </v-list-item-icon>
                                <v-list-item-content class="d-flex align-center">
                                  <v-list-item-title
                                    style="white-space: normal !important"
                                  >
                                    {{cliente.cli_des }} <strong>({{cliente.co_cli}}) {{cliente.co_ven}}</strong>
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list>
                          </v-expansion-panel-header>
                          <v-expansion-panel-content color="white" class="cxc-content">
                            <v-row no-gutters>
                              <v-col cols="12" class="px-0 mx-0">
                                <template v-if="cliente.status_cuenta_vencida && cliente.cuenta_usd">
                                  <v-list style="width: 100%">
                                    <v-subheader class="pl-0 pt-5 pb-2 blue-grey--text" style="height: 0px !important">Cuenta en Dolares</v-subheader>
                                    <v-list-item class=" mt-2 px-0 grey lighten-4 rounded-lg ">
                                      <v-list-item-content class="px-4">
                                        <v-row no-gutters>
                                          <v-col cols="6">
                                            <span class="caption blue-grey--text text--darken-3">Saldo Neto</span>
                                          </v-col>
                                          <v-col cols="6" class="text-right">
                                            <v-list-item-title class="text-h4 font-weight-bold primary--text">
                                              <span class="mx-1 text-h4 font-weight-bold primary--text" v-text="'$'"></span>
                                              {{cliente.cuenta_usd.monto_net | FormatCurrency}}
                                            </v-list-item-title>
                                          </v-col>
                                        </v-row>
                                      </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item class=" mt-2 px-0 grey lighten-4 rounded-lg ">
                                      <v-list-item-content class="px-4">
                                        <v-row no-gutters>
                                          <v-col cols="6">
                                            <span class="caption blue-grey--text text--darken-3">Saldo</span>
                                          </v-col>
                                          <v-col cols="6" class="text-right">
                                            <v-list-item-title class="text-h4 font-weight-bold red--text">
                                              <span class="mx-1" v-text="'$'"></span>
                                              {{cliente.cuenta_usd.saldo | FormatCurrency}}
                                            </v-list-item-title>
                                          </v-col>
                                        </v-row>
                                      </v-list-item-content>
                                    </v-list-item>
                                  </v-list>
                                </template>
                              </v-col>
                            </v-row>
                          </v-expansion-panel-content>
                          <v-divider />
                        </v-expansion-panel>
                      </template>
                    </v-expansion-panels>
                  </template>
                  <template #loading>
                    <v-skeleton-loader
                      type="list-item-two-line, list-item, list-item-two-line"
                    ></v-skeleton-loader>
                  </template>
                  <template v-slot:no-data>
                  <div class="text-center pa-4">
                    <span class="blue-grey--text font-italic">No se encontraron Registros disponibles</span>
                  </div>
                  </template>
                </v-data-iterator>
              </v-card-text>
            </v-tab-item>

            <!-- TAB VENDEDORES -->
            <v-tab-item>
              <v-card-text class="px-0">
                <v-row>
                  <v-col cols="12" md="4" class="pt-4 pb-2 pl-7 d-flex align-center">
                    <v-checkbox
                        v-model="toggle_ven"
                        color="info"
                      >
                        <template #label>
                          <span class="ml-4 font-weight-medium">Todos</span>
                        </template>
                      </v-checkbox>
                  </v-col>
                  <v-col cols="12" md="8" class="pt-4 pb-2 px-8 d-flex align-center justify-end">
                    <search-expand
                      v-model="search_vend"
                      :load="loading"
                      placeholder="Buscar Vendedor"
                      expand
                    />
                  </v-col>
                </v-row>
                <v-data-iterator
                  :items="itemsVend"
                  :loading="loading"
                  :search="search_vend"
                  :footer-props="{itemsPerPageText: 'Mostrar'}"
                  class="mb-0 pb-0"
                >
                  <template v-slot:default="props">
                    <v-list
                      subheader
                      two-line
                      flat
                      class="pt-0"
                    >
                      <template v-for="(vendedor,i) in props.items">
                        <v-list-item :key="'vendMap-'+i">
                          <v-list-item-action>
                            <v-checkbox
                              v-model="vendedor.active"
                              color="info"
                            ></v-checkbox>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>
                              <v-icon left :color="vendedor.color">mdi-checkbox-blank-circle</v-icon>
                              <span class="font-weight-medium">{{vendedor.ven_des}} ({{vendedor.co_ven}})</span>
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider :key="'vendiv-'+i" />
                      </template>
                    </v-list>
                  </template>
                  <template #loading>
                    <v-skeleton-loader
                      type="list-item-two-line, list-item, list-item-two-line"
                    ></v-skeleton-loader>
                  </template>
                  <template v-slot:no-data>
                  <div class="text-center pa-4">
                    <span class="blue-grey--text font-italic">No se encontraron Registros disponibles</span>
                  </div>
                  </template>
                </v-data-iterator>
              </v-card-text>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>

      <!--  SECTION MAP -->
      <v-col cols="12" sm="12" :md="showInfoData ? '7' : '12' " :style="height_mapa">
        <view-maps
          :points="markers_clientes"
          :loadData="loading"
          info
          :centerable="center_cli"
          :animation="center_cli"
          area
          @exportar="exportarClientes"
          @details="detailsClientes"
          :iconSize="sizeIcon"
          :zoom="zoom_map"
        />
         <v-overlay
            v-if="loading"
            :value="true"
            color="white"
            absolute
            opacity="1"
            key="mapaClientesOverlay"
            style="z-index: 1002 !important"
          >
            <div class="d-flex flex-column justify-center">
              <v-progress-circular
                indeterminate
                size="64"
                color="primary"
                class="mx-6"
              ></v-progress-circular> <br>
              <span class="primary--text py-5">Obteniendo Datos</span>
            </div>
          </v-overlay>
        <toggle-info v-model="showInfoData"  v-if="$vuetify.breakpoint.mdAndUp"/>
      </v-col>
    </v-row>
    <size-market v-model="sizeIcon" />
    <modal-cliente-export
      v-model="showClientExport"
      :clients="clientsExport"
    />
    <modal-cliente-map
      v-model="showClientMap"
      :client="clientDetails"
    />
  </v-container>
</template>
<script>
import { get, sync } from 'vuex-pathify'
import moment from 'moment'

export default {
  name: 'UbicacionClientes',
  components:{
    ViewMaps: () => import(
      /* webpackChunkName: "view-maps" */
      '@/widgets/ViewMaps'
    ),
    ListaEstatus: () => import(
      /* webpackChunkName: "lista-estatus" */
      './Components/ListaEstatus'
    ),
    ModalClienteExport: () => import(
      /* webpackChunkName: "modal-cliente-export" */
      './Components/ModalClienteExport.vue'
    ),
    SizeMarket: () => import(
      /* webpackChunkName: "size-market" */
      './Components/SizeMarket.vue'
    ),
    ToggleInfo: () => import(
      /* webpackChunkName: "toggle-info" */
      './Components/ToggleInfo.vue'
    ),
    ModalClienteMap: () => import(
      /* webpackChunkName: "modal-client-map" */
      './Components/ModalClienteMap.vue'
    ),
  },
  data: () => ({
    search_cliente: '',
    search_vend: '',
    toggle_ven: true,
    showInfoData: true,
    loading: false,
    itemsClients: [],
    itemsVend: [],
    active_status:{
      rojo: true,
      verde: true,
      amarillo: true
    },
    center_cli:{
      lat: null,
      lng: null,
    },
    tabsUbication: '',
    toggle_options_map: false,
    show_info_map: false,
    clientsExport: [],
    lines: [],
    showClientExport: false,
    sizeIcon: 'default',
    zoom_map: 7,
    filters: {
      line: [],
      sales_three_months: false,
      sales_more_three_months: false,
      no_sales: false,
      sales_month: false
    },
    showClientMap: false,
    clientDetails: {}
  }),
  created(){
    this.getClientMap();
    this.mini = true;
  },
  watch:{
    toggle_ven(val){
      this.itemsVend.forEach(element => element.active = val);
    },
    $vuetify: {
      deep: true,
      handler: (val) => {
        if(val.breakpoint.smAndDown) {
          this.showInfoData = true;
        }
      }
    }
  },
  methods:{
    async getClientMap(){
      this.loading = true;
      try {
        const { data } = await this.$api.get('clientes/maps');
        this.itemsClients = data?.data;
        this.itemsVend = data?.vendedores && data?.vendedores.length > 0 ? data.vendedores.map(item => ({
          ...item,
          active: true,
        })) : [];
        this.lines = data?.lineas
      } catch (error) {
        console.error({error})
      } finally {
        this.loading = false;
      }
    },
    centerCliente(cli) {
      if(cli.lat != null && cli.lng != null) {
        this.center_cli = {
          lat: parseFloat(cli.lat.trim()),
          lng: parseFloat(cli.lng.trim()),
        }
        this.zoom_map = 15;
      }
    },
    mobileClose(){
      this.toggle_options_map = false;
    },
    exportarClientes (vertices) {
      this.clientsExport = [];
      this.clientsExport = vertices;
      this.showClientExport = true;
    },
    getFiltersDate({fecha, cantidad}){
      const date_init_three_months = moment().subtract(3, 'months').format("YYYY-MM-DD")
      const date_fin_three_months = moment().format("YYYY-MM-DD")
      const isValidDate = moment(fecha).isValid()
      const isSalesQuarterly = isValidDate && moment(fecha).isBetween(date_init_three_months, date_fin_three_months, undefined, '[]')

      if(!this.filters.sales_three_months && !this.filters.sales_more_three_months && !this.filters.no_sales && !this.filters.sales_month)
        return true

      return (this.filters.sales_three_months && isSalesQuarterly) || (this.filters.sales_more_three_months && !isSalesQuarterly) || (this.filters.no_sales && !this.filters.no_sales) || (this.filters.sales_month && cantidad > 0)

    },
    detailsClientes(info) {
      this.showClientMap = true
      this.clientDetails = {...info}
    },
    resetFilters() {
      if(this.filters.line.length === 0){
        this.filters.no_sales = false
        this.filters.sales_month = false
        this.filters.sales_more_three_months = false
        this.filters.sales_three_months = false
      }
    }
  },
  computed:{
    status: get('app/statusClient'),
    mini: sync('app/mini'),
    height_mapa(){
      return {
        height: this.$vuetify.breakpoint.mobile ? '100vh' : '120vh',
      }
    },
    height_content_cliente(){
      return {
        'max-height': '100vh',
      }
    },
    data_cliente() {
      if(this.itemsClients.length === 0) return []

      return this.itemsClients.filter(cli => {
        if(cli === null || cli.lat === null) return false
        return (cli.status_cuenta_vencida && this.active_status.rojo) || (cli.status_cuenta_dolar && this.active_status.amarillo) || (!cli.status_cuenta_vencida && !cli.status_cuenta_dolar && this.active_status.verde)
      }).filter(vend => {
        const indexVend = this.itemsVend.findIndex(v => v.co_ven === vend.co_ven);
        return indexVend >= 0 ? this.itemsVend[indexVend].active : false;
      })
    },
    markers_clientes () {
      return this.data_cliente.length > 0
        ? this.data_cliente.filter(cli => {
          if(this.filters.line.length === 0) return true
          return cli?.rpv?.lineas && cli?.rpv?.lineas.some(({linea, cantidad, fecha}) => this.filters.line.includes(linea) && this.getFiltersDate({fecha, cantidad}))
        }).map(item => ({
            name: item.cli_des,
            co_cli: item.co_cli,
            co_ven: item.co_ven,
            rif: item.rif,
            ven: item.ven_des,
            position: {
              lat: parseFloat(item.lat.trim()),
              lng: parseFloat(item.lng.trim()),
            },
            icon:{
              markerColor: item.status_cuenta_vencida && this.active_status.rojo ? this.status.rojo : item.status_cuenta_dolar && this.active_status.amarillo ? this.status.amarillo : this.status.verde,
              statusColor: item.color,
            },
            animation: null,
            lineas: this.filters.line.length > 0 ? item?.rpv?.lineas.filter(({linea, fecha, cantidad}) => this.filters.line.includes(linea) && this.getFiltersDate({fecha, cantidad}) ) : item?.rpv?.lineas
          }))
        : []
    }
  }
}
</script>
<style lang="sass">
.open-info-map
  position: fixed
  z-index: 6
  top: 0
  transform: translateX(0%)
  margin-left: -12px

.close-info-map
  position: fixed
  z-index: 6
  top: 0
  transform: translateX(-100%)
  visibility: hidden

.infoClients
  transition: all 5s ease-out
  -webkit-transition: all 5s ease-out

.custom-chip .v-chip--select
  margin-top: 4px !important
  margin-bottom: 4px !important

</style>
