<template>
  <v-container
    id="dashboard-view"
    fluid
    tag="section"
    class="py-0"
    :class="{'px-12': $vuetify.breakpoint.xlOnly, 'px-3': $vuetify.breakpoint.mdAndUp}"
  >
    <v-overlay
      v-if="load"
      :value="true"
      color="white"
      absolute
      opacity="1"
      key="DashboardOverlay"
      style="z-index: 2 !important"
    >
      <div class="d-flex flex-column justify-center">
        <v-progress-circular
          indeterminate
          size="64"
          color="primary"
          class="mx-auto"
        ></v-progress-circular>
        <span class="primary--text py-5">Obteniendo Dashboard</span>
      </div>
    </v-overlay>
    <header>
      <v-row class="py-3">
        <v-col cols="12" class="d-flex flex-row align-center">
          <v-avatar
            color="green"
            rounded
            size="60"
            class="mx-3 elevation-1"
          >
            <v-img height="44" contain :src="require('@/assets/IF.svg')" />
          </v-avatar>
          <div class="d-flex flex-column">
            <span
              class="font-weight-medium primary--text"
              :class="{'text-h3': $vuetify.breakpoint.smAndUp, 'text-h5': $vuetify.breakpoint.xsOnly }"
            >
              Bienvenido, {{user.username}}
            </span>
            <span
              class="blue-grey--text subtitle-2"
              v-text="now"
            />
          </div>
        </v-col>
      </v-row>
    </header>
    <section>
      <v-row class="py-3">
        <v-col cols="12">
          <span class="primary--text text-h4 font-weight-medium ml-2">Contadores del día</span>
        </v-col>
        <v-col cols="12">
          <div
            class="grid gap-4"
            :class="{'grid-cols-4': $vuetify.breakpoint.mdAndUp, 'grid-cols-2': $vuetify.breakpoint.smOnly, 'grid-cols-1': $vuetify.breakpoint.xsOnly}"
          >
            <div class="">
              <contador
                color="indigo lighten-1"
                icon="mdi-briefcase-outline"
                color-icon="indigo"
                label="Pedidos"
                cickliable
                :count="contadoresDia.cantidad_pedidos"
                :details="{
                  label: 'Cajas',
                  icon: 'mdi-package-variant-closed',
                  amount: contadoresDia.cantidad_cajas
                }"
                @click="detailsPedidos"
              >
                <template v-if="contadoresDia.cantidad_clientes_activos > 0">
                  <span class="text-count font-weight-bold ml-2"> / {{contadoresDia.cantidad_clientes_activos}}</span>
                  <span class="font-weight-medium pt-1">
                    <v-icon small color="white" class="mt-n1">mdi-account</v-icon>
                    Activos
                  </span>
                </template>


              </contador>
            </div>
            <div class="">
              <contador
                color="light-blue lighten-3"
                icon="mdi-text-box-check-outline"
                color-icon="blue"
                label="Cobros"
                cickliable
                :count="contadoresDia.cobros_realizados"
                :details="{
                  label: 'Saldo Cobrado',
                  icon: 'mdi-currency-usd',
                  amount: contadoresDia.saldo_cobrado,
                  prefix: '$'
                }"
                @click="detailsCobros"
              />
            </div>
            <div class="">
              <contador
                color="red lighten-1"
                icon="mdi-cash"
                color-icon="red"
                label="Efectivo a mas de 24hrs"
                prefix="$"
                cickliable
                :count="contadoresDia.total_cobros_efectivo"
                @click="detailsEfectivo"
              />
            </div>
            <div class="">
              <contador
                color="orange lighten-2"
                icon="mdi-shield-alert-outline"
                color-icon="orange"
                label="Incidencias"
                cickliable
                :count="incidencias.count"
                @click="detailsIncidencias"
              />
            </div>
          </div>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="pa-md-3 mb-4">
        <v-col cols="12">
          <v-card flat tile :loading="capturing">
            <v-card-text id="CuentasPorCobrar">
              <header-screenshot
                v-show="capturing"
              />
              <v-row class="py-3">
                <v-col
                  cols="12"
                  class="d-flex justify-space-between"
                >
                <div class="d-flex flex-column">
                  <!-- <img
                    :src="require('@/assets/Logos/anzoategui.png')"
                    width="150"
                    class="ml-2 mb-2"
                  > -->
                  <span
                    class="primary--text font-weight-bold ml-2"
                    :class="{'text-h4': $vuetify.breakpoint.smAndUp, 'text-h6': $vuetify.breakpoint.xsOnly }"
                  >Consolidado de Cuentas por Cobrar</span>
                </div>
                  <v-btn
                    id="btnCapture"
                    color="primary"
                    dark
                    small
                    class="ml-2 py-0"
                    :class="{'px-2': $vuetify.breakpoint.smAndDown}"
                    @click="converCuentasToImage"
                  >
                    <v-icon
                      size="22"
                      :left="$vuetify.breakpoint.mdAndUp"
                      v-text="'mdi-camera-plus-outline'"
                    />
                    <span v-if="$vuetify.breakpoint.smAndUp">Capturar</span>
                  </v-btn>
                </v-col>
               <v-col
                  cols="12"
                  sm="6"
                  md="4"
                  class="d-flex flex-column pt-1 justify-end white"
                >
                  <span class="text-h2 font-weight-black primary--text pb-0 d-flex align-center">
                    <v-icon color="primary" large>mdi-currency-usd</v-icon>
                    {{saldoVencido.Total | FormatCurrency}}
                  </span>
                  <span class="blue-grey--text text--lighten-1 font-italic caption pl-8 mt-n3">Total Cuentas por Cobrar</span>
                  <div v-if="$vuetify.breakpoint.mdAndUp" class="fill-height d-flex align-end white">
                    <v-list dense class="mb-3 white">
                      <v-list-item v-for="(legend,i) in legends" :key="'graficaLegend'+i" class="white">
                        <v-list-item-icon class="mr-1">
                          <v-icon :color="legend.color">mdi-checkbox-blank</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title v-text="legend.text" />
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </div>
                </v-col>
                <v-col cols="12" sm="12" md="8" class="px-md-12">
                  <deudas-grafica
                    :datos="saldoVencido"
                    tipo="bar"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <!-- <visitas /> -->
        </v-col>
      </v-row>
    </section>
    <modal-detalles-cobros
      v-model="showDetails"
      :cobros="contadoresDia.cobros_efectivo_alerta"
    />
    <modal-detalles-cobros
      v-model="showCobros"
      details
      :cobros="contadoresDia.cobros"
    />
    <modal-detalles-pedidos
      v-model="showPedidos"
      :items="contadoresDia.pedidos"
    />
    <modal-detalles-incidencias
      v-model="showIncidencias"
      :items="incidencias.data"
    />
  </v-container>
</template>

<script>
  // Utilities
  import { get } from 'vuex-pathify'
  import moment from 'moment'
  import { screenshot } from '@/util/CaptureData'
  import {total} from '@/util/helpers'

  const hasincidents = (venData) => {
    const VISITAS_MINIMA = 15
    const NO_VISITAS = '00:00:00'
    const HORAS_MINIMA = 3
    const {visitas_totales = null, total_horas_trabajadas = null, hora_inicio_visita = null } = venData
    const [horas_trabajadas, ...time] = total_horas_trabajadas.split(':')

    return visitas_totales <= VISITAS_MINIMA || horas_trabajadas < HORAS_MINIMA || hora_inicio_visita === NO_VISITAS

  }

  export default {
    name: 'DashboardView',
    components: {
      DeudasGrafica: () => import(
        /* webpackChunkName: "deudas-grafica" */
        './Dashboard/DeudasGrafica.vue'
      ),
      Contador: () => import(
        /* webpackChunkName: "contador" */
        './Dashboard/Contador.vue'
      ),
      ModalDetallesCobros: () => import(
        /* webpackChunkName: "modal-detalles-cobros" */
        './Dashboard/ModalDetallesCobros.vue'
      ),
      ModalDetallesPedidos: () => import(
        /* webpackChunkName: "modal-detalles-pedidos" */
        './Dashboard/ModalDetallesPedidos.vue'
      ),
      ModalDetallesIncidencias: () => import(
        /* webpackChunkName: "modal-detalles-incidencias" */
        './Dashboard/ModalDetallesIncidencias.vue'
      ),
    },
    data: () => ({
      load: false,
      dataDashboard: '',
      saldoVencido: {},
      contadoresDia: {
        cantidad_cajas: 0,
        cantidad_pedidos: 0,
        cobros_realizados: 0,
        saldo_cobrado: 0,
        total_cobros_efectivo: 0,
        cobros_efectivo_alerta: [],
        incidencias: []
      },
      capturing: false,
      showDetails: false,
      showPedidos: false,
      showCobros: false,
      showIncidencias: false,
    }),
    computed: {
      user: get('user/infoBasic'),
      basic: get('user/infoBasic'),
      LOGO () {
        const URL_API = "http://apidev.isolafoods.com/"
        return URL_API + this.basic.logo
      },
      now () {
        moment.locale('es')
        return moment().format("DD MMMM, YYYY")
      },
      legends () {
        const label = this.saldoVencido.data ? Object.keys(this.saldoVencido.data) : []
        const colors = ['#4A148C', '#009688', '#FF5722', '#E91E63', '#FFD600']

        return label.length
          ? label.map((item, key) => ({
            text: item,
            color: colors[key]
          }))
          : []
      },
      totalSaldoCobrado() {
        var numberFormat = new Intl.NumberFormat('es-ES');
        return this.contadoresDia.saldo_cobrado
          ? numberFormat.format(this.contadoresDia.saldo_cobrado)
          : 0
      },
      incidencias () {
        if(this.contadoresDia.incidencias.length === 0) return {count: 0, data: []}

        let count = this.contadoresDia?.incidencias.reduce((acc,item) => {
            return hasincidents(item)
              ? acc + 1
              : acc
          }, 0)

        const data = this.contadoresDia.incidencias.filter(item => hasincidents(item))

        return {count, data}
      }
    },
    created () {
      this.getDashboard()
    },
    methods: {
      async getDashboard () {
        this.load = true
        try {
          const { data } = await this.$api.get('dashboard/inicio')
          this.saldoVencido = data.saldo_vencido
          this.contadoresDia = data.contadores_por_dia
          this.contadoresDia['total_cobros_efectivo'] = total({
            items: data?.contadores_por_dia?.cobros_efectivo_alerta,
            key: 'monto'
          })
        } catch (error) {
          this.$root.$showAlert(
            'Lo siento, hubo un error al intentar obtener los Pagos.',
            'error',
          )
        }
        this.load = false
      },
      async converCuentasToImage () {
        this.capturing = true
        const btn = document.getElementById('btnCapture')
        const ID_ELEMENT = 'CuentasPorCobrar'
        const NAME_IMAGE = 'Capture_consolidado_cuentas_por_cobrar'
        try {
          btn.classList.add("d-none")
          // await wait(125)
          await screenshot({ id: ID_ELEMENT, title: NAME_IMAGE, width: this.$vuetify.breakpoint.xsOnly ? 500 : null })
          btn.classList.remove("d-none")
          this.$root.$showAlert(
            'Capture Exitoso, espere la descarga.',
            'success'
          )
        } catch (error) {
          this.$root.$showAlert(
            'Lo siento, hubo un error al intentar capturar el Reporte.',
            'error',
          )
        } finally {
          this.capturing = false
        }
      },
      detailsEfectivo() {
        this.showDetails = true
      },
      detailsPedidos() {
        this.showPedidos = true
      },
      detailsCobros() {
        this.showCobros = true
      },
      detailsIncidencias() {
        this.showIncidencias = true
      },
    }
  }
</script>
<style lang="sass">
.cliente-titulo
    font-size: 0.9rem !important
    font-weight: 500
    line-height: 1.5em
    letter-spacing: 0.0125em !important
    font-family: "Open Sans" !important
    text-transform: uppercase !important

.gap-4
  gap: 1rem

.grid-cols-5
    grid-template-columns: repeat(5,minmax(0,1fr))
.grid-cols-4
    grid-template-columns: repeat(4,minmax(0,1fr))
.grid-cols-3
    grid-template-columns: repeat(3,minmax(0,1fr))
.grid-cols-2
    grid-template-columns: repeat(2,minmax(0,1fr))
.grid-cols-1
    grid-template-columns: repeat(1,minmax(0,1fr))

.grid
    display: grid


</style>
