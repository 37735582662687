<template>
  <v-container
    id="vendedor-detalles"
    tag="section"
    class="pa-0"
    fluid
  >
    <v-row>
      <v-col cols="12" class="pa-0 light d-flex flex-row justify-space-between align-center pa-3">
        <div>
          <span
            class="font-weight-bold mr-1"
            :class="{'text-h4': $vuetify.breakpoint.smAndUp, 'subtitle-1': $vuetify.breakpoint.xsOnly}"
          >
            Ruta Vendedor
          </span>
            <span
              class="primary--text space-normal" v-text="vend.ven_des"
              :class="{'display-1': $vuetify.breakpoint.smAndUp, 'subtitle-1': $vuetify.breakpoint.xsOnly}"
            />
            <span
              v-if="vend.co_ven"
              class="primary--text font-weight-bold"
              :class="{'display-1': $vuetify.breakpoint.smAndUp, 'subtitle-1': $vuetify.breakpoint.xsOnly}"
              v-text="' ('+vend.co_ven+')'"
            />
        </div>
        <v-btn depressed plain class="py-1" :to="{name: 'Vendedores'}">
          <v-icon
            size="20"
            :left="$vuetify.breakpoint.smAndUp"
          >mdi-arrow-left-bold-box</v-icon>
          <span class="hidden-xs-only">Regresar</span>
        </v-btn>
      </v-col>
      <v-col cols="12" class="px-0 pt-0 mb-4">
        <v-tabs
          v-model="tabsGeneral"
          background-color="blue-grey"
          show-arrows
          dark
          slider-color="blue-grey lighten-4"
          active-class="tabs-general--active"
          slider-size="3"
          class="fill-height"
          @change="getTabVen"
        >
          <v-tab v-for="(tab,i) in tabsVenGeneral" :key="'detailsVenGeneral-'+i">
            <v-icon size="20" left v-text="tab.icon"></v-icon>
            <span v-text="tab.name"></span>
          </v-tab>
          <v-tab :class="{'d-none': tabsGeneral != tabsVenGeneral.length }">Detalles #{{select_pedido.Numero}}</v-tab>
        </v-tabs>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
         <v-tabs-items v-model="tabsGeneral" touchless class="tabs-general--items">
            <v-tab-item v-if="tabsVenDetails.length > 0">
              <v-row>
                <v-col cols="12">
                  <v-card>
                    <v-row>
                      <v-col cols="12" class="py-3">
                        <span class="text-h5 font-weight-bold px-6">
                          Actividad
                          <v-avatar
                            size="18"
                            color="blue-grey lighten-3"
                            class="mx-2 mt-n1"
                          >
                            <v-icon size="20" dark>mdi-information-variant</v-icon>
                          </v-avatar>
                        </span>
                      </v-col>
                      <v-col cols="12" class="pt-0"><v-divider></v-divider></v-col>
                    </v-row>
                    <v-card-title class="pa-0">
                      <v-tabs
                        v-model="actividad"
                        background-color="grey lighten-5"
                        color="secondary"
                        show-arrows
                        active-class="blue-grey lighten-5"
                      >
                        <v-tab class="text-capitalize" v-for="(tven,i) in tabsVenDetails" :key="'tven-'+i">
                          <v-icon size="16" class="mr-1" v-text="tven.icon"></v-icon>
                          <span class="hidden-xs-only" v-text="tven.name" />
                        </v-tab>
                          <v-tab :class="{'d-none': this.actividad != tabsVenDetails.length}">
                          Visita:
                          <span
                            class="d-inline-block text-truncate mx-1"
                            style="max-width: 150px;"
                          >{{select_visita.cli_des}}</span>
                          </v-tab>
                      </v-tabs>
                    </v-card-title>
                    <v-divider />
                    <v-card-text class="pa-0">
                      <v-tabs-items v-model="actividad" touchless>
                        <v-tab-item v-if="$hasPermission('ruta-vendedores.visitas-programadas.consulta')">
                          <programa />
                        </v-tab-item>
                        <!-- <v-tab-item v-if="$hasPermission('ruta-vendedores.visitas-programadas.consulta')">
                          <programa-old />
                        </v-tab-item> -->
                        <v-tab-item v-if="$hasPermission('ruta-vendedores.historico-visitas.consulta')">
                          <historico @showVisita="getVisita" />
                        </v-tab-item>
                        <v-tab-item v-if="$hasPermission('ruta-vendedores.clientes.consulta')">
                          <clientes-vendedor :clientes="clientes_ven" :loading="loadVenCli" @change="getClientes" @update="getClientes" />
                        </v-tab-item>
                        <!-- <v-tab-item v-if="$hasPermission('ruta-vendedores.estadisticas.consulta')">
                          <estadisticas :datos="datos_estadisticas" :loadData="loadEstadis" @change="getEstadisticas"/>
                        </v-tab-item>   -->
                        <v-tab-item v-if="$hasPermission('ruta-vendedores.ruta.consulta')">
                          <item-ruta :seller="vend" />
                        </v-tab-item>
                      </v-tabs-items>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item v-if="$hasPermission('ruta-vendedores.ruta.consulta')">
               <v-row>
                <v-col cols="12" class="pt-0">
                  <v-card>
                    <rutas :ubications="routes_data" :loading="loadRoute" @change="getRuta"/>
                  </v-card>
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item v-if="$hasPermission('ruta-vendedores.pedidos.consulta')">
              <v-row>
                <v-col cols="12" class="pt-0">
                  <v-card>
                    <pedidos-vendedor
                      :pedidosv="pedidosVen"
                      :headers="headPed"
                      :loading="loadVen"
                      @showDetails="getPedido"
                      @update="getPedidos"
                      @updateData="getPedidosRangoFecha"
                      :loadDetail="loadDetails || loadPedidos"
                    />
                  </v-card>
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item v-if="$hasPermission('ruta-vendedores.cobranzas.consulta')">
              <v-row>
                <v-col cols="12" class="pt-0">
                  <v-card>
                    <data-cxc modo="Cliente" :datacxc="cxcVen" :loading="loadVen || loadCXC" @update="getCXCs" @updateDataCXC="getCXCRangoFecha" />
                  </v-card>
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item v-if="$hasPermission('ruta-vendedores.estadisticas.consulta')">
              <estadisticas :vendedor="vend" />
            </v-tab-item>
             <v-tab-item v-if="$hasPermission('ruta-vendedores.cobros.consulta')">
              <cobros mode="vendedor" />
            </v-tab-item>
            <v-tab-item v-if="$hasPermission('ruta-vendedores.pedidos.consulta')">
              <v-row>
                <v-col cols="12">
                  <v-card>
                    <v-row class="px-3">
                      <v-col cols="12" class="grey lighten-5 d-flex justify-space-between py-0">
                        <span class="text-h6 font-weight-bold pa-4">Detalles del Pedido</span>
                        <div class="mx-3">
                          <!-- <v-btn
                            small
                            text
                            class="my-3 mx-3"
                          >
                            <v-icon left>mdi-printer</v-icon>
                            Imprimir
                          </v-btn> -->
                          <v-btn
                            small
                            text
                            class="my-3 px-1"
                            @click="tabsGeneral = 2"
                          >
                            <v-icon size="20" class="mr-1">mdi-arrow-left</v-icon>
                            Volver
                          </v-btn>
                        </div>
                      </v-col>
                    </v-row>
                    <details-pedido :cliente="select_pedido" :articulos="articulos_pedido"/>
                  </v-card>
                </v-col>
               </v-row>
            </v-tab-item>
         </v-tabs-items>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { get, sync } from 'vuex-pathify'
import moment from 'moment'
export default {
  name: 'DetailsVendedor',
  components:{
    InfoBasica: () => import(
      /* webpackChunkName: "info-basic" */
      './Components/InfoVendedor.vue'
    ),
    PedidosVendedor: () => import(
      /* webpackChunkName: "pedidos-vendedor" */
      './Components/PedidosVendedor.vue'
    ),
    DataCxc: () => import(
      /* webpackChunkName: "data-cxc" */
      './Components/DataCXC.vue'
    ),
    DetailsPedido: () => import(
      /* webpackChunkName: "details-pedido" */
      './Components/DetailsPedido.vue'
    ),
    Programa: () => import(
      /* webpackChunkName: "programa" */
      './Components/ProgramaVisitas/Programa.vue'
    ),
    ProgramaOld: () => import(
      /* webpackChunkName: "programa-old" */
      './Components/ProgramaVisitas/ProgramaActual.vue'
    ),
    Historico: () => import(
      /* webpackChunkName: "historico" */
      './Components/HistoricoVisitas/Historico.vue'
    ),
    ClientesVendedor: () => import(
      /* webpackChunkName: "clientes-vendedor" */
      './Components/ClientesVendedor.vue'
    ),
    Estadisticas: () => import(
      /* webpackChunkName: "estadisticas" */
      './Components/Estadisticas/index.vue'
    ),
    Rutas: () => import(
      /* webpackChunkName: "rutas" */
      './Components/Rutas.vue'
    ),
    ItemHistoria: () => import(
      /* webpackChunkName: "item-historia" */
      './Components/HistoricoVisitas/ItemHistoria.vue'
    ),
    ItemRuta: () => import(
      /* webpackChunkName: "item-historia" */
      './Components/RutasVendedor/ItemRuta.vue'
    ),
    Cobros: () => import(
      /* webpackChunkName: "cobros" */
      '@/views/Clientes/Pagos/index'
    ),
  },
  data: () => ({
    vend: {},
    select_pedido: {},
    select_visita: {},
    articulos_pedido: [],
    pedidosVen: [],
    cxcVen: {},
    hist_visitas: [],
    clientes_ven: [],
    datos_estadisticas: {},
    routes_data: [],
    actividad: null,
    tabsGeneral: null,
    loadVen: false,
    loadRuta: false,
    loadVenCli: false,
    loadDetails: false,
    loadEstadis: false,
    loadRoute: false,
    loadPedidos: false,
    loadHistVisita: false,
    loadProgVisita: false,
    loadCXC: false,
    fecha_ped_ini: '',
    fecha_ped_fin: '',
    fecha_cxc_ini: '',
    fecha_cxc_fin: '',
    fecha_visita_ini: '',
    fecha_visita_fin: '',
    headPed: [
      { text: 'Nro.', value: 'Numero', class: 'header-data', width: '100' },
      { text: 'Cliente', value: 'cli_des', class: 'header-data'},
      { text: 'Fecha', value: 'Fecha', class: 'header-data',width: '120'},
      { text: 'Observaciones', value: 'Observaciones', class: 'header-data', sortable: false },
      { text: 'Precio Venta', value: 'TipoPrecio', class: 'header-data', align: 'center' },
      { text: 'Procesado', value: 'Procesado', class: 'header-data', align: 'center', sortable: false },
      { text: '', value: 'actions', class: 'header-data', width: '80' },
    ],
    tabs_general:[
      {
        name: 'Detalles del Vendedor',
        icon: 'mdi-text-box-outline',
        permiso: 'ruta-vendedores.consulta',
        index: 0
      },
      {
        name: 'ruta',
        icon: 'mdi-map-marker-path',
        permiso: 'ruta-vendedores.ruta.consulta',
        index: 1
      },
      {
        name: 'pedidos',
        icon: 'mdi-cart-arrow-down',
        permiso: 'ruta-vendedores.pedidos.consulta',
        index: 2
      },
      {
        name: 'cobranzas',
        permiso: 'ruta-vendedores.cobranzas.consulta',
        icon: 'mdi-file-alert-outline',
        index: 3
      },
      {
        name: 'Estadísticas',
        permiso: 'ruta-vendedores.estadisticas.consulta',
        icon: 'mdi-chart-bar',
        index: 4
      },
      {
        name: 'Cobros',
        permiso: 'ruta-vendedores.estadisticas.consulta',
        icon: 'mdi-text-box-check-outline',
        index: 5
      },
    ],
    tabs_ven:[
      {
        name: 'programa visita',
        permiso: 'ruta-vendedores.visitas-programadas.consulta',
        icon: 'mdi-truck-fast-outline',
        index: 0
      },
      // {
      //   name: 'programa visita Anterior',
      //   permiso: 'ruta-vendedores.visitas-programadas.consulta',
      //   icon: 'mdi-truck-fast-outline',
      //   index: 1
      // },
      {
        name: 'historico de visitas',
        permiso: 'ruta-vendedores.historico-visitas.consulta',
        icon: 'mdi-alarm-check',
        index: 1
      },
      {
        name: 'clientes',
        permiso: 'ruta-vendedores.clientes.consulta',
        icon: 'mdi-account-supervisor-circle',
        index: 2
      },
      {
        name: 'Rutas',
        permiso: 'ruta-vendedores.ruta.consulta',
        icon: 'mdi-map-search-outline',
        index: 3
      },
      // {
      //   name: 'Estadísticas',
      //   permiso: 'ruta-vendedores.estadisticas.consulta',
      //   icon: 'mdi-chart-bar',
      //   index: 4
      // },
    ]
  }),
  created() {
    this.mini = true;
    this.fecha_ped_ini = this.fecha_cxc_ini = moment().startOf('week').day(1).format("YYYY-MM-DD");
    this.fecha_ped_fin = this.fecha_cxc_fin = moment().format('YYYY-MM-DD');
    this.getVendedor();

    if(this.$hasPermission('ruta-vendedores.clientes.consulta')) {
      this.getClientes();
    }

    if(this.$hasPermission('ruta-vendedores.ruta.consulta')) {
      this.getRuta(moment().format('YYYY-MM-DD'));
    }

    // if(this.$hasPermission('ruta-vendedores.estadisticas.consulta')) {
    //   this.getEstadisticas(moment().format('YYYY-MM-DD'));
    // }

    if(this.$route.query.tab) {
      const current_act = this.tabs_general.filter(tab => tab.name === this.$route.query.tab);
      this.tabsGeneral = current_act[0].index;
      if(current_act[0].index === 2){
        this.getPedidos();
      }
      if(current_act[0].index === 3){
        this.getCXCs();
      }
    }
  },
  methods:{
    async getVendedor() {
      this.loadVen = true;
      try {
        const { data } = await this.$api.get(`vendedores/${this.id}`);
        this.vend = data.data;
      } catch (error) {
        this.$root.$showAlert(
          'Lo siento, hubo un error al intentar obtener los datos del Vendedor.',
          'error'
        )
      }
      this.loadVen = false;
    },
    // async getProgVisita() {
    //   this.loadProgVisita = true;
    //   try {
    //     const prog_visita = await this.$api.get(`vendedores/${this.id}/programavisita`);
    //     this.programa_visitas = prog_visita.data.data;
    //   } catch (error) {
    //      this.$root.$showAlert(
    //       'Lo siento, hubo un error al intentar obtener los datos del Programa de Visita.',
    //       'error',
    //     )
    //   }
    //   this.loadProgVisita = false;
    // },
    // async getRuta() {
    //   this.loadRuta = true;
    //   try {
    //     const ven_ruta = await this.$api.get(`vendedores/${this.id}/ruta/${moment().format('YYYY-MM-DD')}`);
    //     this.routes_data = ven_ruta.data.data;
    //   } catch (error) {
    //      this.$root.$showAlert(
    //       'Lo siento, hubo un error al intentar obtener los datos de la Ruta Vendedor.',
    //       'error'
    //     )
    //   }
    //   this.loadRuta = false;
    // },
    async getPedido(item) {
      this.loadDetails = true;
      try {
        const { data } = await this.$api.get(`vendedores/${item.Numero}/pedidos/detalles?fecha=${moment(item.Fecha).format("YYYY-MM-DD")}`);

        this.articulos_pedido = data.data;
        this.select_pedido = item;
        this.tabsGeneral = this.tabsVenGeneral.length;
      }
      catch (error) {
        this.$root.$showAlert(
          'Lo siento, hubo un error al intentar obtener los Articulos del pedido.',
          'error'
        )
      }
      this.loadDetails = false;
    },
    async getPedidos() {
       this.loadPedidos = true;
      try {
        const ven_pedidos = await this.$api.get(`vendedores/${this.id}/pedidos?fecha_inicio=${this.fecha_ped_ini}&fecha_final=${this.fecha_ped_fin}`);
        this.pedidosVen = ven_pedidos.data.data;
      } catch (error) {
        this.$root.$showAlert(
          'Lo siento, hubo un error al intentar obtener los Pedidos del Vendedor.',
          'error'
        )
      }
      this.loadPedidos = false;
    },
    getPedidosRangoFecha(fechas) {
      this.fecha_ped_ini = fechas.ini;
      this.fecha_ped_fin = fechas.fin;
      this.getPedidos();
    },
    getCXCRangoFecha(fechas) {
      this.fecha_cxc_ini = fechas.ini;
      this.fecha_cxc_fin = fechas.fin;
      this.getCXCs();
    },
    getVisitasRangoFecha(fechas) {
      this.fecha_visita_ini = fechas.ini;
      this.fecha_visita_fin = fechas.fin;
      this.getHistVisitas();
    },
    async getHistVisitas() {
       this.loadHistVisita = true;
       this.hist_visitas = [];
      try {
        const visitas = await this.$api.get(`vendedores/${this.id}/visitas?fecha_inicio=${this.fecha_visita_ini}&fecha_final=${this.fecha_visita_fin}`);
        this.hist_visitas = visitas.data.data;
      } catch (error) {
        this.$root.$showAlert(
          'Lo siento, hubo un error al intentar obtener las Visitas del Vendedor.',
          'error'
        )
      }
      this.loadHistVisita = false;
    },
    async getCXCs() {
       this.loadCXC = true;
       this.cxcVen = {};
      try {
        const ven_cxc = await this.$api.get(`vendedores/${this.id}/cxc?fecha_inicio=${this.fecha_cxc_ini}&fecha_final=${this.fecha_cxc_fin}`);
        this.cxcVen = ven_cxc.data;
      } catch (error) {
        this.$root.$showAlert(
          'Lo siento, hubo un error al intentar obtener las Cuentas por Cobrar del Vendedor.',
          'error'
        )
      }
      this.loadCXC = false;
    },
    getTabVen(item) {
      this.$router.replace({query:{tab: this.tabsVenGeneral[item].name}});
      if(this.tabsVenGeneral[item].index === 2) {
        this.getPedidos();
      }
      if(this.tabsVenGeneral[item].index === 3) {
        this.getCXCs();
      }
    },
    actionsTabsDetails(tab){
      if(tab === 2){
        this.getHistVisitas();
      }
    },
    getVisita(item){
      this.select_visita = item;
      this.actividad = this.tabsVenDetails.length;
    },
    async getEstadisticas(date){
      this.loadEstadis = true;
      try {
        const {data} = await this.$api.get(`vendedores/${this.id}/estadisticas/${date}`);
        this.datos_estadisticas = data.data;
      } catch (error) {
        this.$root.$showAlert(
          'Lo siento, hubo un error al intentar obtener los datos consultados. Por favor vuelva a intentarlo.',
          'error'
        )
      }
      this.loadEstadis = false;
    },
    async getRuta(date){
      this.loadRoute = true;
      try {
        const {data} = await this.$api.get(`vendedores/${this.id}/ruta/${date}`);
        this.routes_data = data.data;

      } catch (error) {
        this.$root.$showAlert(
          'Lo siento, hubo un error al intentar obtener los datos de la Ruta. Por favor vuelva a intentarlo.',
          'error'
        )
      }
      this.loadRoute = false;
    },
    async getClientes(){
      this.loadVenCli = true;
      this.clientes_ven = [];
      try {
        const {data } = await this.$api.get(`vendedores/${this.id}/clientes`);
        this.clientes_ven = data.data;
      } catch (error) {
        this.$root.$showAlert(
          'Lo siento, hubo un error al intentar obtener los datos de los clientes del Vendedor.',
          'error'
        )
      }
      this.loadVenCli = false;
    },
  },
  computed:{
    id: get('route/params@cod'),
    mini: sync('app/mini'),
    nroVend() {
      return this.vend.co_ven ? '#'+this.vend.co_ven : '';
    },
    tabsVenDetails() {
      return this.tabs_ven.filter(tab => this.$hasPermission(tab.permiso))
    },
    tabsVenGeneral() {
      return this.tabs_general.filter(tab => {
        if(tab.index === 0 && this.tabsVenDetails.length === 0)
          return false

        return this.$hasPermission(tab.permiso)
      })
    },
  }
}
</script>
<style lang="sass" scoped>
.tabs-general--active
  background-color: rgba(255,255,255, 0.12) !important
  border-color: rgba(255,255,255, 0.12) !important

</style>
