<template>
  <v-container id="Resumen_semenal" fluid tag="section" class="pa-1">
    <v-row>
      <v-col cols="12">
        <main-header />
        <v-row>
          <v-col cols="12" class="pt-0">
            <v-card id="ResumenPorVendedor" tile>
              <header-screenshot v-show="capturing" class="ma-0" />
              <v-card-title class="white">
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                    md="7"
                    lg="7"
                    class="d-flex align-center"
                  >
                    <div>
                      <v-icon size="20" left> mdi-calendar </v-icon>
                      <span class="text-h5 blue-grey--text text-capitalize"
                        >{{ selected_text }}:
                      </span>
                      <span class="font-weight-bold text-h6 blue-grey--text">
                        {{ fecha_inicio | largeDate }} -
                        {{ fecha_fin | largeDate }}
                      </span>
                    </div>
                  </v-col>
                  <v-col
                    id="btnsActions"
                    cols="12"
                    sm="6"
                    md="5"
                    lg="5"
                    class="d-flex flex-row align-center"
                    :class="{
                      'justify-end': !$vuetify.breakpoint.xsOnly,
                      'justify-center': $vuetify.breakpoint.xsOnly,
                    }"
                  >
                    <v-btn
                      color="primary"
                      class="mx-2"
                      text
                      small
                      @click="legend = !legend"
                    >
                      <v-icon
                        size="22"
                        :left="$vuetify.breakpoint.mdAndUp"
                        v-text="
                          legend
                            ? 'mdi-chevron-double-up'
                            : 'mdi-chevron-double-down'
                        "
                      />
                      <span v-if="$vuetify.breakpoint.mdAndUp">Leyenda</span>
                    </v-btn>
                    <v-divider vertical class="mx-1 mb-1" />
                    <v-btn
                      color="primary"
                      class="mx-2"
                      text
                      small
                      :loading="capturing"
                      @click="CaptureReporte"
                    >
                      <v-icon
                        size="22"
                        :left="$vuetify.breakpoint.mdAndUp"
                        v-text="'mdi-camera-plus-outline'"
                      />
                      <span v-if="$vuetify.breakpoint.mdAndUp">Capturar</span>
                    </v-btn>
                    <v-divider vertical class="mx-1 mb-1" />
                    <v-menu
                      v-model="menu"
                      :close-on-content-click="false"
                      :nudge-width="320"
                      :nudge-bottom="10"
                      offset-y
                      z-index="100"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="primary"
                          class="ml-2"
                          text
                          small
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon size="22" left> mdi-tune </v-icon>
                          Consultar
                          <v-icon
                            size="22"
                            class="ml-1"
                            v-text="menu ? 'mdi-menu-up' : 'mdi-menu-down'"
                          />
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title class="px-4 primary--text display-1">
                          Seleccione Fecha a consultar
                        </v-card-title>
                        <v-card-text class="px-4 pt-0 mb-3">
                          <v-row>
                            <v-col cols="12" class="pt-0">
                              <v-radio-group
                                v-model="selected_date"
                                class="pt-0"
                                hide-details
                              >
                                <v-radio
                                  label="Semanal"
                                  value="semana"
                                  color="icono"
                                >
                                  <template #label>
                                    <span
                                      class="blue-grey--text font-weight-medium"
                                      >Semana</span
                                    >
                                  </template>
                                </v-radio>
                                <v-radio
                                  label="Quincenal"
                                  value="Quincena"
                                  color="icono"
                                >
                                  <template #label>
                                    <span
                                      class="blue-grey--text font-weight-medium"
                                      >Quincena</span
                                    >
                                  </template>
                                </v-radio>
                                <v-radio
                                  label="Mensual"
                                  value="Mes"
                                  color="icono"
                                >
                                  <template #label>
                                    <span
                                      class="blue-grey--text font-weight-medium"
                                      >Mes Actual</span
                                    >
                                  </template>
                                </v-radio>
                                <v-radio
                                  label="Rango de Fecha"
                                  value="personalizado"
                                  color="icono"
                                >
                                  <template #label>
                                    <span
                                      class="blue-grey--text font-weight-medium"
                                      >Rango de Fecha</span
                                    >
                                  </template>
                                </v-radio>
                              </v-radio-group>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col
                              cols="12"
                              class="d-flex flex-row ml-8"
                              :class="{
                                'flex-row align-center':
                                  $vuetify.breakpoint.smAndUp,
                                'flex-column ': $vuetify.breakpoint.xsOnly,
                              }"
                            >
                              <div>
                                <label-form text="Inicio" required />
                                <input-fecha
                                  v-model="range_inicio"
                                  color="blue-grey lighten-5"
                                  :disabled="selected_date != 'personalizado'"
                                />
                              </div>
                              <span
                                class="blue-grey--text font-weight-medium mx-4 mt-3 caption"
                                >-</span
                              >
                              <div>
                                <label-form text="Final" required />
                                <input-fecha
                                  v-model="range_fin"
                                  color="deep-orange lighten-5"
                                  :disabled="selected_date != 'personalizado'"
                                />
                              </div>
                            </v-col>
                            <v-col cols="12" class="py-0 ml-8">
                              <span
                                v-if="error.active"
                                class="d-block error--text caption font-italic"
                                v-text="error.msg"
                              />
                            </v-col>
                          </v-row>
                        </v-card-text>
                        <v-card-actions class="px-3 grey lighten-5">
                          <v-spacer />
                          <v-btn
                            outlined
                            color="blue-grey"
                            @click="menu = false"
                          >
                            Cancelar
                          </v-btn>
                          <v-btn
                            color="primary"
                            class="pr-3"
                            @click="consultarDatos"
                          >
                            <v-icon left size="22"> mdi-magnify </v-icon>
                            Consultar
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-menu>
                  </v-col>
                  <v-expand-transition>
                    <v-col v-show="legend" cols="12" class="pt-0">
                      <v-divider class="mb-4" />
                      <v-row>
                        <v-col cols="12" sm="6" md="4">
                          <v-icon color="icono" left size="22" class="mt-n1">
                            mdi-label
                          </v-icon>
                          <span class="blue-grey--text text-h5">
                            <span class="font-weight-bold">C.P:</span>
                            Clientes Planificados
                          </span>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-icon color="icono" left size="22" class="mt-n1">
                            mdi-label
                          </v-icon>
                          <span class="blue-grey--text text-h5">
                            <span class="font-weight-bold">C.V:</span>
                            Clientes Visitados
                          </span>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-icon color="icono" left size="22" class="mt-n1">
                            mdi-label
                          </v-icon>
                          <span class="blue-grey--text text-h5">
                            <span class="font-weight-bold">C.A:</span>
                            Clientes Activados
                          </span>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-icon color="icono" left size="22" class="mt-n1">
                            mdi-label
                          </v-icon>
                          <span class="blue-grey--text text-h5">
                            <span class="font-weight-bold">CAJAS:</span>
                            Total de Cajas
                          </span>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-icon color="icono" left size="22" class="mt-n1">
                            mdi-label
                          </v-icon>
                          <span class="blue-grey--text text-h5">
                            <span class="font-weight-bold">PFV:</span>
                            Pedidos Fuera de Visita
                          </span>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-icon color="icono" left size="22" class="mt-n1">
                            mdi-label
                          </v-icon>
                          <span class="blue-grey--text text-h5">
                            <span class="font-weight-bold">PT:</span>
                            Total de Pedidos
                          </span>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-expand-transition>
                </v-row>
              </v-card-title>
              <v-card-text class="pa-0">
                <data-resumen
                  :datos="datosRe"
                  :totales="datosTotales"
                  :loading="loadDatos"
                />
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from "moment";
import { screenshot } from "@/util/CaptureData";
import { wait } from "@/util/helpers";

export default {
  name: "ReportResumen",
  components: {
    DataResumen: () =>
      import(
        /* webpackChunkName: "data-Resumen" */
        "./Components/DataResumen.vue"
      ),
    InputFecha: () =>
      import(
        /* webpackChunkName: "input-fecha" */
        "./Components/InputFecha.vue"
      ),
  },
  data: () => ({
    datosRe: [],
    datosTotales: {},
    loadDatos: false,
    legend: false,
    fecha_inicio: "",
    fecha_fin: "",
    range_inicio: moment().format("YYYY-MM-DD"),
    range_fin: moment().format("YYYY-MM-DD"),
    selected_date: "semana",
    selected_text: "semana",
    error: {
      active: false,
      msg: "",
    },
    menu: false,
    capturing: false
  }),
  created() {
    this.fecha_inicio = moment().startOf("week").day(1).format("YYYY-MM-DD");
    this.fecha_fin = moment().format("YYYY-MM-DD");
    this.getDatos();
  },
  methods: {
    async getDatos() {
      this.loadDatos = true;
      this.datosRe = [];
      this.datosTotales = {};
      try {
        const { data } = await this.$api.get(
          `reportes/resumensemanal?fecha_inicio=${this.fecha_inicio}&fecha_fin=${this.fecha_fin}`,
        );
        this.datosRe = data.data;
        this.datosTotales = data.datostotales;
      } catch (error) {
        this.$root.$showAlert(
          "Lo siento, hubo un error al intentar obtener los datos del Resumen.",
          "error",
        );
      }
      this.loadDatos = false
    },
    consultarDatos() {
      this.error = {
        active: false,
        msg: "",
      };
      this.fecha_fin = "";
      this.fecha_inicio = "";
      switch (this.selected_date) {
        case "semana":
          this.fecha_inicio = moment()
            .startOf("week")
            .day(1)
            .format("YYYY-MM-DD");
          this.fecha_fin = moment().format("YYYY-MM-DD");
          break;
        case "Quincena":
          this.fecha_inicio = moment()
            .subtract(15, "days")
            .format("YYYY-MM-DD");
          this.fecha_fin = moment().format("YYYY-MM-DD");
          console.log("quincina");
          break;
        case "Mes":
          this.fecha_inicio = moment().startOf("month").format("YYYY-MM-DD");
          this.fecha_fin = moment().format("YYYY-MM-DD");
          break;
        case "personalizado":
          if (moment(this.range_inicio).isSameOrBefore(this.range_fin)) {
            this.fecha_inicio = this.range_inicio;
            this.fecha_fin = this.range_fin;
          } else {
            this.error = {
              active: true,
              msg: "La fecha de inicio debe ser menor que la fecha final",
            };
            return;
          }
          break
        default:
          break
      }
      this.selected_text = this.selected_date
      this.menu = false
      this.getDatos()
    },
    async CaptureReporte() {
      // const footer = document.getElementsByClassName('v-data-footer')
      const btns = document.getElementById("btnsActions");
      const ID_ELEMENT = "ResumenPorVendedor";
      const NAME_IMAGE = "Capture_resumen_vendedores";

      this.capturing = true;

      try {
        // footer[0].classList.add('d-none')
        btns.classList.remove("d-flex");
        btns.classList.add("d-none");
        await screenshot({
          id: ID_ELEMENT,
          title: NAME_IMAGE,
          width: this.$vuetify.breakpoint.xsOnly ? 700 : null,
        });
        // footer[0].classList.remove('d-none')
        btns.classList.remove("d-none");
        btns.classList.add("d-flex");
        this.$root.$showAlert(
          "Capture Exitoso, espere la descarga.",
          "success",
        );
      } catch (error) {
        this.$root.$showAlert(
          "Lo siento, hubo un error al intentar capturar el Reporte.",
          "error",
        );
      } finally {
        this.capturing = false;
      }
    }
  },
  compuetd: {},
};
</script>

<style></style>
