<template>
  <v-container
    id="clientes_geolocalizados"
    fluid
    tag="section"
    class="pa-1"
  >
    <v-row>
      <v-col cols="12">
        <main-header /> 
        <v-row class="mt-2">
          <v-col cols="12" md="6" class="pt-0">
            <v-card tile>
              <v-card-title class="primary--text grey lighten-5">
                <span class="text-h5 font-weight-bold">Clientes por vendedor</span>
              </v-card-title>
              <v-card-text class="pa-0">
                <clientes-geolocalizados :datos="datosCG" :loading="loadClientesG" />
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="6" class="pt-0">
            <v-card tile class="fill-height">
              <v-card-title class="primary--text grey lighten-5">
                <span class="text-h5 font-weight-bold">General</span>
              </v-card-title>
              <v-card-text class="pa-0 d-flex align-center">
                <grafica-geolocalizados :datos="datosCG" :loading="loadClientesG" />
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'ReportResumen',
  components: { 
    ClientesGeolocalizados: () => import(
      /* webpackChunkName: "clientes-geolocalizados" */
      './Components/ClientesGeolocalizados.vue'
    ),
    GraficaGeolocalizados: () => import(
      /* webpackChunkName: "grafica-geolocalizados" */
      './Components/ClientesGeolocalizadosGrafica.vue'
    ),
  },
  data: () => ({
    datosCG: [],
    loadClientesG: false,
  }),
  created(){
    this.getClientesGeo();
  },
  methods:{
    async getClientesGeo(){
      this.loadClientesG = true;
      this.datosCG = [];
      try {
        const { data } = await this.$api.get('reportes/clientesgeolocalizados');
        this.datosCG = data.vendedores;
      } catch (error) {
        this.$root.$showAlert(
          'Lo siento, hubo un error al intentar obtener los datos del Resumen.',
          'error'
        )
      }
      this.loadClientesG = false;
    },
  },
}
</script>

<style>

</style>