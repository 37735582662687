<template>
    <v-navigation-drawer
      v-model="toogle_filters"
      right
      fixed
      width="300"
      temporary
      :overlay-opacity="0.1"
      class="white--text content-scroll"
      color="grey lighten-5"
    >
      <v-list-item class="primary py-0">
        <v-list-item-content>
          <v-list-item-title class="text-h4 white--text">
            <v-icon dark small left>mdi-tune-variant</v-icon>
            FILTROS
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action class="my-0">
          <v-btn
            icon
            dark
            @click="toogle_filters = !toogle_filters"
          >
             <v-icon dark>mdi-close</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
      <v-expansion-panels
        v-model="item_filters"
        accordion 
        multiple 
        flat
      >
        <v-expansion-panel class="grey lighten-5">
          <v-expansion-panel-header class="primary--text font-weight-bold">
            <span><v-icon small left color="primary">mdi-group</v-icon>Linea</span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
              <v-select
                v-model="selected_categories"
                :items="itemsCategories"
                item-text="lin_des"
                return-object
                dense
                outlined
                clearable
                class="field-white"
                hide-details
                @change="selected_subcatg = []"
              />
          </v-expansion-panel-content>
          <v-divider />
        </v-expansion-panel>
       <v-expansion-panel class="grey lighten-5">
          <v-expansion-panel-header class="primary--text font-weight-bold">
            <span><v-icon small left color="primary">mdi-account</v-icon>Sub-Linea</span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
           <v-select
             v-model="selected_subcatg"
             item-text="subl_des"
             return-object
             dense
             outlined
             multiple
             chips
             class="field-white"
             hide-details
             :items="subLineas"
             :menu-props="{ bottom: true, offsetY: true }"
             :disabled="!subLineas.length > 0"
           >
              <template v-slot:selection="{ attrs, item, select, selected }">
                <v-chip
                  v-bind="attrs"
                  :input-value="selected"
                  close
                  @click="select"
                  @click:close="remove(item)"
                  small
                  class="my-1"
                >
                 <span class="text-truncate"> <strong>{{ item.subl_des }}</strong> </span>
                </v-chip>
              </template>
            </v-select>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels> 
      <template #append>
        <v-row>
          <v-col
            cols="12"
            class="d-flex justify-center align-center px-8 pt-4 pb-8 mx-auto"
          >
            <v-btn
              class="mr-3 px-8"
              small
              outlined
              tile
              @click="resetear"
            >
              Resetear
            </v-btn>
            <v-btn
              color="primary"
              class="px-8"
              small
              tile
              @click="aplicarFiltros"
            >
              <v-icon left>mdi-check</v-icon> 
              Aplicar
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </v-navigation-drawer>
</template>
<script>
export default {
  name: 'AvancedFilters',
  props: {
    value: Boolean,
    orders:{
      type: Array,
      default: () => ([])
    },
    itemsCategories: {
      type: Array,
      default: () => ([]),
    },
    itemsSubcatg: {
      type: Array,
      default: () => ([]),
    },
  },
  data () {
    return {
      selected_order: 'co_art',
      type_order: false,
      item_filters: [0,1,2,3],
      toogle_filters: this.value,
      selected_categories: '',
      selected_subcatg: [],
    }
  },
  methods: {
    // async getCategory() {
    //   try {
    //     const { data } = await this.$api.get('articulos/filters');

    //     this.items_categories = data.lineas;
    //     this.items_subcatg = data.sub_lineas;
    //   } catch (error) {
    //     console.error(error);
    //   }
    // },
    aplicarFiltros() {
      this.toogle_filters = false;
      this.$emit('filtrar', {
        lineas: this.selected_categories, 
        sub_lineas: this.selected_subcatg
      })
    },
    resetear () {
      this.selected_categories = ''
      this.selected_subcatg = []
      this.$emit('filtrar', {
        lineas: '', 
        sub_lineas: '',
      })
    },
    remove (item) {
      this.selected_subcatg.splice(this.selected_subcatg.indexOf(item), 1)
      this.selected_subcatg = [...this.selected_subcatg]
    },
  },
  watch: {
    toogle_filters (val, oldVal) {
      if (val === oldVal) return

      this.$emit('input', val)
    },
    value (val, oldVal) {
      if (val === oldVal) return

      this.toogle_filters = val
    },
  },
  computed: {
    subLineas () {
      return this.itemsSubcatg.length 
        ? this.itemsSubcatg.filter(item => this.selected_categories.co_lin === item.co_lin) 
        : []
    },
  },

}
</script>