<template>
  <v-container
    id="clientes"
    tag="section"
    class="pa-0 no-fluid"
  >
    <v-tabs-items v-model="tab" class="background-none">
      <v-tab-item class="background-none">                         
        <v-row class="pa-4 mb-0">
          <v-col cols="12" sm="12" md="12">
            <main-header>
            </main-header>
            <v-card tile>
              <v-card-text class="pa-0 ma-0">
                <v-row class="py-1 px-4">
                  <v-col
                    cols="12"
                    sm="12"
                    class="pt-0 d-flex"
                    :class="resposiveHeader"
                  >
                    <div class="mt-2">
                      <search-expand 
                        v-model="searchClient" 
                        :load="loadingClients"
                        placeholder="Buscar Cliente"
                      />
                    </div>  
                    <div :class="{'my-2 d-flex justify-center': $vuetify.breakpoint.xsOnly}">
                      <btn-geolocalizacion
                        v-if="$vuetify.breakpoint.smAndUp"
                        v-model="filtros.geo"
                      />
                      <v-btn
                        color="icono"
                        class="ml-2 mt-2"
                        :class="{'col-6': $vuetify.breakpoint.xsOnly}"
                        outlined
                        small
                        @click="getList"
                      >               
                       <v-icon
                          size="22"
                          :left="!$vuetify.breakpoint.smOnly"
                        >
                          mdi-update
                        </v-icon>  
                        <span v-if="!$vuetify.breakpoint.smOnly">Actualizar</span>              
                      </v-btn> 
                      <v-btn
                        color="icono"
                        class="ml-2 mt-2"
                        :class="{'col-6 mr-2': $vuetify.breakpoint.xsOnly}"
                        dark
                        small
                        @click="tab = 2"
                      >               
                        <v-icon size="22" left>mdi-map-marker-radius-outline</v-icon>  
                        Ver mapa             
                      </v-btn> 
                    </div>
                  </v-col>
                </v-row>
                <v-divider class="mt-3" />
                <v-row class="pa-0 ma-0">
                  <v-col cols="12" class="pa-0 ma-0">
                    <data-clients 
                      :data="itemsClients"
                      :search="searchClient"
                      :headers="headersClient"
                      :loadingData="loadingClients"
                      @edit="actionUpdate"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-tab-item>
      <template v-if="$hasPermission('clientes.potencial.editar')">
        <v-tab-item>
          <template v-if="tab === 1">
            <update-client
              :client="cliente"
              @back="resetView"
              @updated="actionSuccess"
            />
          </template>
        </v-tab-item>
      </template>
      <v-tab-item class="ma-0">
        <template v-if="tab === 2">
          <ubication
            :clients="clients"
            @back="resetView"
          />
        </template>
        </v-tab-item>
    </v-tabs-items>    
  </v-container>
</template>
<script>
import { getClientsPotenciales } from '@/services/clientes-potenciales.js'
import { sync } from 'vuex-pathify'

export default {
  name: 'clientes',
  components: { 
    DataClients: () => import(
      /* webpackChunkName: "data-list" */
      './DataList.vue'
    ),
    BtnGeolocalizacion: () => import(
      /* webpackChunkName: "btn-horario" */
      '../Components/Filtros/BtnGeolocalizacion.vue'
    ),
    UpdateClient: () => import(
      /* webpackChunkName: "updat-client" */
      './Update.vue'
    ),
    Ubication: () => import(
      /* webpackChunkName: "ubication" */
      './Ubication.vue'
    ),
    ViewMaps: () => import(
      /* webpackChunkName: "view-maps" */
      '@/widgets/ViewMaps'
    )
   },
  data: () => ({
    center: { lat: 8.955582, lng: -65.3220065 },
    searchClient: '',
    filtersClient: false,
    loadingClients: false,
    view_map: false,
    clients:[],
    filtros:{
      geo: '',
    },
    tab: 0,
    cliente: {},
  }),
  created () {
    this.getList()
    this.gutters = false
  },
  destroyed () {
    this.gutters = true
  },
  methods:{
    async getList () {
      try {
        this.loadingClients = true;
         this.clients = await getClientsPotenciales()        
      } catch (error) {
        this.$root.$showAlert(
          'Lo siento, hubo un error al intentar obtener los Clientes Potenciales.',
          'error',
        )
      }
      this.loadingClients = false
    },
    resetView () {
      this.tab = 0
      this.cliente = {}
    },
    actionUpdate (item) {      
      this.tab = 1
      this.cliente = { ...item }
    },
    actionSuccess () {
      this.changeView()
      this.getList()
    }
  },
  computed:{
    gutters: sync('app/gutters'),
    markers() {
      return this.clients.length > 0 ? this.clients.filter(cli => cli.lat != null).map(item => ({
        name: item.cli_des,
        position: {
          lat: parseFloat(item.lat.trim()),
          lng: parseFloat(item.lng.trim()),
        }
      })) : []
    },
    itemsClients () {
      let data = [];
      data = this.clients.map((item) => {
        return {
          ...item,
          geo: item.lat !== null && item.lng !== null ? '1' : '0',
        }
      })

      const CustomFiltersKeys = Object.keys(this.filtros)
      return data.filter((item) => {
        return CustomFiltersKeys.every(keyFiltro => {
          if(this.filtros[keyFiltro].length === 0) {
            return true;
          }
          return typeof this.filtros[keyFiltro] === 'object' 
            ? this.filtros[keyFiltro].includes(item[keyFiltro]) 
            : (this.filtros[keyFiltro].toString()).toLowerCase().includes((item[keyFiltro].toString()).toLowerCase())
        })
      })
    },
    headersClient () {
      return [
        { text: 'Cod.', value: 'co_cli_po', class: 'header-data', width: '90', active: true, align: 'center' },
        { text: 'Cliente', value: 'nombre_comercial', class: 'header-data', active: true,},
        { text: 'Contácto', value: 'persona_contacto', class: 'header-data', active: true, },       
        { text: 'Vendedor', value: 'ven_des', class: 'header-data', active: true },
        { 
          text: '', 
          value: 'info', 
          class: 'header-data', 
          width: '80', 
          active: this.$vuetify.breakpoint.smAndUp, 
          sortable: false,
        },
        { text: '', value: 'actions', class: 'header-data', width: '80', active: true, sortable: false },
      ]
    },
    resposiveHeader () {
      return {
        'flex-wrap align-center justify-space-between': this.$vuetify.breakpoint.smAndUp,
        'flex-column': this.$vuetify.breakpoint.xsOnly,
      }
    }
  }
}
</script>
<style scoped>
.background-none {
  background: none !important;
  overflow: visible !important;
}
</style>
