<template>
  <v-container
    id="Horarios"
    fluid
    tag="section"
    class="pa-1"
  >
    <main-header />
    <v-row>
      <v-col cols="12">
        <v-card tile>
          <v-card-text>
            <v-row justify="center">
              <v-col cols="12" sm="10" md="8" class="text-center">
                <span class="primary--text font-weight-bold text-h3 d-block">Subir Archivo</span>
                <span class="blue-grey--text text-h4">El archivo debe tener el formato <strong>Excel</strong> o <strong>CSV</strong> (.xlsx, .xls ó .csv).</span>
              </v-col>
              <v-col cols="12" sm="10" md="8">
                 <VueFileAgent
                  v-model="data_horario"
                  ref="FileHorario"
                  :accept="'.xlsx, .xls, .cvs'"
                  :multiple="false"
                  :deletable="true" 
                  :theme="'list'"
                  class="py-2 upload-horarios"
                  @select="validateFile($event)"
                >
                  <template v-slot:file-preview-new>
                   <v-row key="new-archivo" class="pa-2">
                     <v-col cols="12" class="d-flex justify-center align-center pb-0">
                       <v-icon size="60" color="icono">mdi-folder-open</v-icon>
                     </v-col>
                     <v-col cols="12" class="d-flex justify-center align-center">
                       <span class="blue-grey--text text--lighten-2 font-italic text-h5">Arrastre el archivo aquí ó Seleccione el documento en su PC local.</span>
                     </v-col>
                     <v-col cols="12" class="d-flex justify-center align-center">
                       <v-btn small color="icono" dark tile>
                         <v-icon left>mdi-monitor</v-icon>
                         Explorar Archivos
                       </v-btn>
                     </v-col>
                   </v-row>
                  </template>       
                  <template v-slot:file-preview="slotProps">
                    <v-row :key="slotProps.index" class="pa-6">
                      <v-col cols="12">
                        <v-card class="primary" dark>
                            <v-list-item>
                              <v-list-item-icon>
                                <v-icon
                                  v-if="data_horario.length > 0" 
                                  x-large 
                                  dark
                                  :color="(data_horario[0].ext === 'xlsx' || data_horario[0].ext === 'xls' || data_horario[0].ext === 'csv') ? 'white' : 'warning'"
                                  v-text="(data_horario[0].ext === 'xlsx' || data_horario[0].ext === 'xls' || data_horario[0].ext === 'csv') ? 'mdi-microsoft-excel' : 'mdi-file-alert'"
                                />
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title class="d-flex justify-start font-weight-bold" v-text="slotProps.fileRecord.name()"></v-list-item-title>
                                <v-list-item-subtitle class="d-flex justify-start" v-text="slotProps.fileRecord.size()"></v-list-item-subtitle>
                                <div v-if="load_horario">
                                  <v-progress-linear
                                    indeterminate
                                    rounded
                                    color="icono"
                                  />
                                </div>
                              </v-list-item-content>
                              <v-list-item-action class="d-flex flex-row align-center">
                                <v-btn text small @click="fileDeleted(slotProps.fileRecord)" :disabled="load_horario">
                                  <v-icon size="18">mdi-trash-can</v-icon>
                                </v-btn>
                                <v-divider class="inset-actions" vertical inset />
                                <v-btn text small :disabled="load_horario || errorfile" @click="loadHorario">
                                  cargar <v-icon right size="22">mdi-upload</v-icon>
                                </v-btn>
                              </v-list-item-action>
                            </v-list-item>
                        </v-card>
                      </v-col>
                    </v-row>
                  </template>
                   <template v-slot:after-outer>
                     <v-row class="py-4" v-if="errorfile">
                       <v-col cols="12">
                        <v-alert
                          text
                          prominent
                          type="error"
                          icon="mdi-cloud-alert"
                        >
                         El tipo de archivo no esta permitido. Debe seleccionar un archivo de formato Excel o CSV
                        </v-alert>
                       </v-col>
                     </v-row>
                   </template>
                </VueFileAgent>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: 'CargaHorarios',
  data: () => ({
    data_horario: [],
    load_horario: false,
    errorfile: false,

  }),
  methods:{
    fileDeleted(record){
      this.$refs.FileHorario.deleteFileRecord(record);
      this.errorfile = false;
    },
    validateFile (val) {
      if (val[0].ext === 'xlsx' || val[0].ext === 'xls' || val[0].ext === 'csv') {
        this.errorfile = false;
      }
      else{
        this.errorfile = true
      }
    },
    async loadHorario () {
      const confirmar = await this.$root.$confirm(
        '¿Estas Seguro?', 
        'Al subir el archivo seleccionado se modificara los horarios en nuestra Base de Datos.',
        {
          type: 'confirm',
          btnConfirmText: 'Confirmar',
          btnCancelText: 'Descartar',
        }
      );
      if(confirmar){
        const dataHorario = new FormData();
        dataHorario.append('file', this.data_horario[0].file)
        this.load_horario = true
        try {
          const {data} = await this.$api.post('settings/importhorario', dataHorario);
          this.$root.$showAlert(data.message, 'success');
          this.data_horario = [];
        } catch (error) {
            this.$root.$showAlert(
              'Lo sentimos, hubo un error al intentar Cargar el Horario.', 
              'error'
            );
        }
        this.load_horario = false;
      } 
    },
  }
}
</script>
<style lang="sass" scope>
.vue-file-agent .file-preview-wrapper
  z-index: 15 !important
.inset-actions
  margin-top: 15px !important
  margin-bottom: 14px !important
</style>
