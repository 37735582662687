import api from '../util/request.js'

export const getEstadisticaCXC = async ({ id }) => {
  try {
    const { data } = await api.get(`vendedores/${id}/estadisticas-cxc`)
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

export const getVendedores = async () => {
  try {
    const { data } = await api.get('vendedores')
    return data.data
  } catch (error) {
    return Promise.reject(error)
  }
}

export const getComments = async ({co_ven, fecha_inicio, fecha_final}) => {
  try {
    const { data } = await api.get('vendedores/observaciones/consulta', {
      params: {
        fecha_inicio,
        fecha_final,
        co_ven
      }
    })
    return data.data
  } catch (error) {
    return Promise.reject(error)
  }
}

export const saveComment = async (body) => {
  try {
    const { data } = await api.post('vendedores/observaciones', body)
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

export const getMapsUbucation = async ({ fecha, co_ven = null }) => {
  try {
    const { data } = await api.get('vendedores/maps/ubicacion', {
      params: {
        fecha,
        co_ven
      }
    })
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

export const getVisitasPlanificadas = async ({co_ven, fecha_inicio, fecha_final}) => {
  try {
    const { data } = await api.get(`vendedores/${co_ven}/programavisita2`, {
      params: {
        fecha_inicio,
        fecha_final,
      }
    })
    return data.data
  } catch (error) {
    return Promise.reject(error)
  }
}
