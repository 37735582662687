<template>
  <v-container
    id="administracion"
    fluid
    tag="section"
    class="pa-1"
  >
    <main-header />
    <v-row>
      <v-col cols="12">
        <v-card tile>
          <v-card-title>
            <v-row>
              <v-col cols="12" sm="12" md="6" class="d-flex flex-row align-center" style="gap:1em">
                <consultar-rango-fecha @consultar="consultarLogs" />
                <v-btn-toggle
                v-model="ambiente"
                  dense
                  mandatory
                  color="icono"
                  @change="getLogs"
                >
                  <v-btn small class="toggle-option" value="web">
                    <v-icon left>mdi-desktop-mac</v-icon>
                    Web
                  </v-btn>

                  <v-btn small class="toggle-option" value="app">
                    <v-icon left>mdi-cellphone-android</v-icon>
                    App
                  </v-btn>
                </v-btn-toggle>
              </v-col>
              <v-col cols="12" sm="12" md="6" class="d-flex justify-end align-center">
                <search-expand
                  v-model="searchLogs"
                  :load="loadLogs"
                  placeholder="Buscar"
                  :expand="$vuetify.breakpoint.mdAndUp"
                  class="mr-2"
                />
                <columns-views :columns="logsHeaders" v-if="$vuetify.breakpoint.mdAndUp"/>
                <v-btn
                  color="icono"
                  class="mx-2 px-0"
                  outlined
                  small
                  @click="getLogs"
                >
                  <v-icon size="22">mdi-update</v-icon>
                </v-btn>
                <v-btn
                  color="icono"
                  small
                  class="ml-2 py-0"
                  :class="{'px-2': $vuetify.breakpoint.smAndDown}"
                  dark
                  @click="filters = true"
                >
                  <v-icon
                    size="22"
                    :left="$vuetify.breakpoint.mdAndUp"
                    v-text="'mdi-filter-outline'"
                  />
                  <span v-if="$vuetify.breakpoint.mdAndUp">Filtros</span>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text class="pa-0 ma-0">
            <v-row
              v-if="filters_view.length > 0"
              class="py-1 px-4"
            >
              <v-col cols="12" class="d-flex flex-column white">
                <span class="text-h6 font-weight-medium primary--text">Filtros Aplicados</span>
                <div class="py-3">
                  <v-chip
                    v-for="(f,i) in filters_view"
                    :key="'filtrosLogs-'+i"
                    class="mr-2"
                    color="blue-grey lighten-5"
                    small
                    label
                    pill
                  >
                   <span class="font-weight-medium text-uppercase"> {{f}} </span>
                  </v-chip>
                </div>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" class="pa-0">
                <v-data-table
                  :headers="LogsHead"
                  :items="logsItems"
                  :loading="loadLogs"
                  :search="searchLogs"
                  :footer-props="{itemsPerPageText: 'Mostrar'}"
                  no-data-text="No exiten Logs Registrados"
                  @click:row="openDescripcion"
                  class="data-log"
                >
                  <template v-slot:item.fecha_recibido="{item}">
                     {{item.fecha_recibido | smartDate}}
                  </template>
                  <template v-slot:item.fecha_evento="{item}">
                     {{item.fecha_evento | smartDate}}
                  </template>
                  <template v-slot:item.hora_recibido="{item}">
                     {{item.fecha_recibido | hora}}
                  </template>
                  <template v-slot:item.hora_evento="{item}">
                     {{item.fecha_evento | hora}}
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <advanced-filters-logs
      v-model="filters"
      :filtros="dataFilters"
      @filtrarDatos="filtrarLogs"
    />
    <descripcion-log v-model="showLog" :log="itemLog" />
  </v-container>
</template>

<script>
import moment from 'moment';
import { logs } from '@/services/administracion'
export default {
  name: 'Logs',
  components: {
    columnsViews: () => import(
      /* webpackChunkName: "column-views" */
      '@/widgets/ColumnsViews'
    ),
    DescripcionLog: () => import(
      /* webpackChunkName: "descripcion-log" */
      './Components/DescripcionLog'
    ),
    ConsultarRangoFecha: () => import(
      /* webpackChunkName: "consultar-rango-fecha" */
      '@/widgets/ConsultarRangoFecha.vue'
    ),
    AdvancedFiltersLogs: () => import(
      /* webpackChunkName: "advanced-filters-logs" */
      './Components/AdvancedFiltersLogs.vue'
    ),
  },
  data: () => ({
    dataLogs: [],
    logsHeaders:[
      { text: 'Codigo', value: 'codigo', class: 'header-data', width: '80', active: false },
      { text: 'Mensaje', value: 'mensaje', class: 'header-data', active: true },
      { text: 'Usuario', value: 'usuario', class: 'header-data', active: true },
      { text: 'Dirección IP', value: 'ip', class: 'header-data', active: false },
      { text: 'Dispositivo', value: 'dispositivo', class: 'header-data', active: false },
      { text: 'Tipo', value: 'tipo', class: 'header-data', active: true },
      { text: 'Estatus', value: 'status', class: 'header-data', active: true },
      { text: 'Controlador', value: 'controlorador', class: 'header-data', active: false },
      { text: 'Linea', value: 'linea', class: 'header-data', active: false },
      { text: 'Tabla', value: 'tabla', class: 'header-data', active: true },
      { text: 'Fecha Recibido', value: 'fecha_recibido', class: 'header-data', active: true },
      { text: 'Hora Recibido', value: 'hora_recibido', class: 'header-data', active: true },
      { text: 'Fecha Evento', value: 'fecha_evento', class: 'header-data', active: true },
      { text: 'Hora Evento', value: 'hora_evento', class: 'header-data', active: true },
    ],
    loadLogs: false,
    filters: false,
    searchLogs: '',
    showLog: false,
    itemLog: {},
    date_inicio: '',
    date_fin: '',
    customFiltros:{
      usuario: [],
      status: '',
      tabla: [],
      codigo: '',
    },
    filters_view: [],
    ambiente: 'web'
  }),
  created() {
    this.date_inicio = moment().startOf('week').day(1).format("YYYY-MM-DD");
    this.date_fin = moment().format('YYYY-MM-DD');
    this.getLogs();
  },
  methods:{
    async getLogs() {
      this.loadLogs = true;
      this.dataLogs = [];
      try {
        this.dataLogs = await logs({
          dateIni: this.date_inicio,
          dateEnd: this.date_fin,
          ambiente: this.ambiente
        });
      } catch (error) {
        this.$root.$showAlert(
          'Lo siento, hubo un error al intentar obtener los Logs.',
          'error'
        )
      }
      this.loadLogs = false;
    },
    openDescripcion(row) {
      this.showLog = true;
      this.itemLog = row;
    },
    consultarLogs(fecha_selected) {
      this.date_inicio = fecha_selected.ini;
      this.date_fin = fecha_selected.fin;
      this.getLogs();
    },
    filtrarLogs (filters) {
      this.customFiltros = {...filters}
      this.mostrarFiltros();
    },
    mostrarFiltros() {
      this.filters_view = []
      const CustomFiltersKeys = Object.keys(this.customFiltros)

      CustomFiltersKeys.forEach(item => {
        if (this.customFiltros[item].length > 0) {
          this.filters_view.push(`${item}: ${this.customFiltros[item]}`)
        }
      })
    },
  },
  computed: {
    LogsHead () {
      return this.logsHeaders.filter(item => item.active);
    },
    dataFilters () {
      if (this.dataLogs.length > 0) {
        const { usuarios, tablas, codigo } = this.dataLogs.reduce((acc, item) => {
          acc.usuarios.push(item.usuario)
          acc.tablas.push(item.tabla)
          acc.codigo.push(item.codigo)
          return acc
        }, { usuarios: [], tablas: [], codigo: [] })

        return {
          usuarios: [...new Set(usuarios)],
          tablas: [...new Set(tablas)],
          codigo: [...new Set(codigo)],
        }
      }
      return {}
    },
    logsItems() {
      const filtros = this.customFiltros;
      const CustomFiltersKeys = Object.keys(filtros)
      return this.dataLogs.filter((item) => {
        return CustomFiltersKeys.every(keyFiltro => {
          if(filtros[keyFiltro].length === 0 || filtros[keyFiltro] === null) {
            return true;
          }
          return typeof filtros[keyFiltro] === 'object'
            ? filtros[keyFiltro].includes(item[keyFiltro])
            : (filtros[keyFiltro].toString()).toLowerCase().includes((item[keyFiltro].toString()).toLowerCase())
        })
      })
    },
  },
  filters: {
    hora(fecha) {
      return moment(fecha).format('hh:mm:ss a');
    }
  }
}
</script>
<style lang="sass">
.data-log > .v-data-table__wrapper > table > tbody > tr
  cursor: pointer !important

.toggle-option
  padding-top: 15px !important
  padding-bottom: 15px !important
  padding-left: 10px !important
  padding-right: 10px !important

</style>
