import api from '../util/request.js'

export const sendMessage = async ({ datos }) => {
  try {
    const { data } = await api.post('mensajes/enviar', datos)
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

export const getClients = async () => {
  try {
    const { data } = await api.get('mensajes/clientes')
    return data.data
  } catch (error) {
    return Promise.reject(error)
  }
}

export const getGroups = async () => {
  try {
    const { data } = await api.get('mensajes/grupos')
    return data.data
  } catch (error) {
    return Promise.reject(error)
  }
}

export const saveGroup = async ({ info, method, idGroup = null }) => {
  try {

    const { data } = await api[method](`mensajes/grupos/${idGroup ?? ''}`, info)
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

export const deleteGroup = async ({ idGroup }) => {
  try {
    const { data } = await api.delete(`mensajes/grupos/${idGroup}`)
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

export const getTemplates = async () => {
  try {
    const { data } = await api.get('mensajes/plantillas')
    return data.data
  } catch (error) {
    return Promise.reject(error)
  }
}

export const saveTemplate = async ({ info, method, idTemplate = null }) => {
  try {

    const { data } = await api[method](`mensajes/plantillas/${idTemplate ?? ''}`, info)
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

export const getTemplate = async ({idTemplate}) => {
  try {

    const { data } = await api.get(`mensajes/plantillas/${idTemplate ?? ''}`)
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

export const deleteTemplate = async ({ idTemplate }) => {
  try {
    const { data } = await api.delete(`mensajes/plantillas/${idTemplate}`)
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

