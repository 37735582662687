<template>
  <v-container
    id="pagos"
    fluid
    tag="section"
    class="pa-1"
  >
    <main-header />
    <v-row class="mb-8" id="data-cobros-isola">
      <v-col cols="12" class="pt-5">
        <v-card tile>
          <v-card-text class="pa-0 ma-0 white">
            <header-screenshot class="ma-0" v-show="capturing" />
            <v-row class="py-1 px-4">
              <v-col v-if="$vuetify.breakpoint.xsOnly" cols="12">
                <search-expand
                  v-model="searchPay"
                  :load="loadingPay"
                  placeholder="Buscar Pago"
                  :expand="$vuetify.breakpoint.smAndUp"
                />
              </v-col>
              <v-col
                cols="12" sm="5" md="4"
                class="d-flex blue-grey--text"
                :class="{'flex-column': $vuetify.breakpoint.smAndDown, 'align-center': $vuetify.breakpoint.mdAndUp}">
                <consultar-rango-fecha @consultar="consultarCobros" />
              </v-col>
              <v-col
                cols="12"
                sm="7"
                md="8"
                id="btnsActionsCobros"
                class="d-flex align-center white"
                :class="{'justify-end': $vuetify.breakpoint.smAndUp, 'justify-center': $vuetify.breakpoint.xsOnly, 'pl-6': $vuetify.breakpoint.mdAndUp}">
                  <search-expand
                    v-if="$vuetify.breakpoint.smAndUp"
                    v-model="searchPay"
                    :load="loadingPay"
                    placeholder="Buscar Pago"
                    :expand="$vuetify.breakpoint.smAndUp"
                  />
              <!---------- Icono de prueba  ---------->
                  <v-btn
                    color="icono"
                    dark
                    small
                    outlined
                    class="ml-2 pa-0"
                    :class="{'px-2': $vuetify.breakpoint.smAndDown}"
                    @click="generatePdf2"
                  >
                    <v-icon
                      size="18"
                      v-text="'mdi-download'"
                    />
                  </v-btn>
                <!---------- Icono de descarga PDF (flecha hacia abajo)  ---------->
                  <!-- <v-btn
                    color="icono"
                    dark
                    small
                    outlined
                    class="ml-2 pa-0"
                    :class="{'px-2': $vuetify.breakpoint.smAndDown}"
                    @click="generatePdf"
                  >
                    <v-icon
                      size="18"
                      v-text="'mdi-download'"
                    />
                  </v-btn> -->
                  <!---------- Icono de cámara descargar ewn Imagen ---------->
                  <v-btn
                    color="icono"
                    small
                    outlined
                    class="ml-2 py-0"
                    :class="{'px-2': $vuetify.breakpoint.smAndDown}"
                    :loading="capturing"
                    @click="converTableToImage"
                  >
                    <v-icon
                      size="18"
                      v-text="'mdi-camera-plus-outline'"
                    />
                    <!-- <span>Capturar</span>           -->
                  </v-btn>
                  <v-btn
                    color="icono"
                    small
                    outlined
                    class="ml-2 py-0"
                    :class="{'px-2': $vuetify.breakpoint.smAndDown}"
                    dark
                    @click="filtersPay = true"
                  >
                    <v-icon
                      size="22"
                      :left="$vuetify.breakpoint.mdAndUp"
                      v-text="'mdi-filter-outline'"
                    />
                    <span v-if="$vuetify.breakpoint.mdAndUp">Filtros</span>
                  </v-btn>
                  <v-btn
                    v-if="$hasPermission('cobros.crear')"
                    color="icono"
                    small
                    class="ml-2 py-0"
                    :class="{'px-2': $vuetify.breakpoint.smAndDown}"
                    dark
                    @click="add = true"
                  >
                    <v-icon
                      size="22"
                      :left="$vuetify.breakpoint.mdAndUp"
                      v-text="'mdi-plus'"
                    />
                    <span v-if="$vuetify.breakpoint.mdAndUp">nuevo</span>
                  </v-btn>
              </v-col>
            </v-row>
            <v-row
              v-if="filters_view.length > 0"
              class="py-1 px-4"
            >
              <v-col cols="12" class="d-flex flex-column white">
                <span class="text-h6 font-weight-medium primary--text">Filtros Aplicados</span>
                <div class="py-3">
                  <v-chip
                    v-for="(f,i) in filters_view"
                    :key="'filtrosa-'+i"
                    class="mr-2"
                    color="blue-grey lighten-5"
                    small
                    label
                    pill
                  >
                   <span class="font-weight-medium"> {{f}} </span>
                  </v-chip>
                </div>
              </v-col>
            </v-row>
            <!-- <v-divider class="mt-3" /> -->
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" class="pt-0" :style="tableShow">
        <v-card tile class="transparent" flat>
          <v-card-text class="pa-0 ma-0">
            <v-row>
              <v-col cols="12">
                <pagos-data
                  :datos="pagosItems"
                  :search="searchPay"
                  :loading-data="loadingPay"
                  @change="getPagos"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <register
      v-if="$hasPermission('cobros.crear')"
      v-model="add"
      @created="successCreate"
    />
    <advanced-filters-pagos
      v-model="filtersPay"
      @filtrarDatos="filtrarPagos"
      :ven="items_vendedores"
      :bancos="items_bancos"
    />
    <success-pago
      v-model="exito"
      :cobro="nroCobroExito"
    />
  </v-container>
</template>
<script>
import moment from 'moment'
import exportPDF from '@/util/ExportPDFPagos'
import { screenshot } from '@/util/CaptureData'

export default {
  name: 'Pagos',
  components: {
    AdvancedFiltersPagos: () => import(
      /* webpackChunkName: "advanced-filters-pagos" */
      './Components/AdvancedFiltersPagos.vue'
    ),
    PagosData: () => import(
      /* webpackChunkName: "pagos-data" */
      './Components/PagosData.vue'
    ),
    Register: () => import(
      /* webpackChunkName: "register" */
      './Components/Register.vue'
    ),
    ConsultarRangoFecha: () => import(
      /* webpackChunkName: "consultar-rango-fecha" */
      './Components/ConsultarCobrosApi.vue'
    ),
    SuccessPago: () => import(
      /* webpackChunkName: "success-pago" */
      './Components/SuccessPago.vue'
    ),
   },
  data: () => ({
    searchPay: '',
    filtersPay: false,
    add: false,
    loadingPay: false,
    capturing: false,
    payments:[],
    rango_inicio: '',
    rango_final: '',
    showFilters: false,
    pageData: 1,
    sortByPay: '',
    orderSortPay: true,
    fecha_inicio: '',
    fecha_fin: '',
    status_api: null,
    filtro_fecha: 'semana',
    filters_view: [],
    items_vendedores: [],
    items_bancos: [],
    customFiltros:{
      cod_banco: [],
      moneda: '',
      metodo_pago: [],
      status: '',
      co_ven: [],
      fecha: '',
    },
    exito: false,
    nroCobroExito: '',
  }),
  created() {
    this.fecha_inicio = moment().startOf('week').day(1).format("YYYY-MM-DD");
    this.fecha_fin = moment().format('YYYY-MM-DD');
    this.getPagos();
    this.getFiltros();
  },
  methods:{
    async getPagos () {
      try {
        this.loadingPay = true;
        this.payments = [];
        const { data } = await this.$api.get(this.status_api ? `pagos?fecha_inicio=${this.fecha_inicio}&fecha_final=${this.fecha_fin}&status=${this.status_api}` : `pagos?fecha_inicio=${this.fecha_inicio}&fecha_final=${this.fecha_fin}`);

        this.payments = data.data;
      } catch (error) {
        this.$root.$showAlert(
          'Lo siento, hubo un error al intentar obtener los Pagos.',
          'error',
        )
      }
      this.loadingPay = false;
    },
    async getFiltros () {
      try {
        const { data } = await this.$api.get('pagos/filtros');

        this.items_vendedores = data.vendedores;
        this.items_bancos = data.bancos;
      } catch (error) {
        console.error(error);
      }
    },
     resetDate () {
      this.rango_inicio = ''
      this.rango_fin = ''
    },
    activeSort(column) {
      this.sortByPay = column;
      this.orderSortPay = !this.orderSortPay;
    },
    columnActived(column) {
      return column === this.sortByPay
    },
    consultarCobros(fecha) {
      this.fecha_inicio = fecha.ini;
      this.fecha_fin = fecha.fin;
      this.status_api = fecha.estatus;
      this.getPagos();
    },
    filtrarPagos (filters) {
      this.customFiltros = {
        cod_banco: filters.banco.length > 0 ? parseInt(filters.banco) : '',
        moneda: filters.moneda,
        metodo_pago: filters.metodo_pago,
        status:  filters.estatus.length > 0
                  ? filters.estatus.map(item => parseInt(item))
                  : [],
        co_ven: filters.vendedor,
        fecha: filters.fecha_pago.ini !== '' && filters.fecha_pago.fin !== '' ? filters.fecha_pago : '',
      }
      this.mostrarFiltros(filters);
    },
    mostrarFiltros(filters) {
      this.filters_view = [];

      if(filters.fecha_pago.ini !== '' && filters.fecha_pago.fin !== '') {
        this.filters_view.push(`Fecha Cobro: ${moment(filters.fecha_pago.ini).format("D MMM YYYY")} - ${moment(filters.fecha_pago.fin).format("D MMM YYYY")}`)
      }

      if(filters.moneda !== '') {
        this.filters_view.push(`Moneda: ${filters.moneda}`)
      }

      if(filters.metodo_pago.length > 0) {
        this.filters_view.push(`Metodo: ${filters.metodo_pago}`)
      }

      if(filters.banco !== undefined) {
        const ban_select = this.items_bancos.filter(item => item.cod_banco === filters.banco);
        if(ban_select.length > 0) {
          this.filters_view.push(`Banco: ${ban_select[0].nombre}`)
        }
      }

      if(filters.vendedor.length > 0) {
        filters.vendedor.forEach(ven =>{
          const ven_select = this.items_vendedores.filter(item => item.co_ven === ven);

          if(ven_select.length > 0) {
            this.filters_view.push(`Vendedor: ${ven_select[0].ven_des}`)
          }
        })
      }

      if(filters.estatus.length > 0) {
        filters.estatus.forEach((item) =>{
          this.filters_view.push(`Estatus: ${item === '0' ? 'Sin Procesar' : item === '1' ? 'Procesado' : 'Anulado'}`)
        })
      }
    },
    async converTableToImage () {
      if(this.pagosItems.length === 0) {
        this.$root.$showAlert(
          'No se encontraron registros para Capturar.',
          'error',
        )
        return
      }
      this.capturing = true
      const ID_ELEMENT = 'data-cobros-isola'
      const NAME_IMAGE = 'Capture_listado_de_Cobros'
      const footer = document.getElementsByClassName('v-data-footer');
      const btns = document.getElementById('btnsActionsCobros')
      try {
        footer[0].classList.add("d-none");
        btns.classList.remove('d-flex')
        btns.classList.add('d-none')
        await screenshot({id: ID_ELEMENT, title: NAME_IMAGE, width: this.$vuetify.breakpoint.xsOnly ? 500 : null })
        footer[0].classList.remove("d-none")
        btns.classList.remove('d-none')
        btns.classList.add('d-flex')
        this.$root.$showAlert(
          'Capture Exitoso, espere la descarga.',
          'success',
        )
      } catch (error) {
         this.$root.$showAlert(
          'Lo siento, hubo un error al intentar capturar.',
          'error'
        )
      } finally {
        this.capturing = false
      }
    },
    generatePdf2(){
      // console.log(this.pagosItems);
      // console.log(this.items_bancos);
      if(this.pagosItems.length === 0) {
        this.$root.$showAlert(
          'No se encontraron registros para Exportar.',
          'error',
        )
        return;
      }
      var columns = [
        { title: `${'Cobro/'}\n${'Recibido'}`, dataKey: 'cod_pago' },
        //{ title: 'Recibido', dataKey: 'fecha_recibido'},
        { title: `${'Cliente'}`, dataKey: 'cli_des'},
        { title: 'Vendedor', dataKey: 'ven_des'},
        { title: 'Estatus', dataKey: 'estatus' },
        { title: 'Cobro Profit', dataKey: 'profit' },
        { title: 'Factura(s)', dataKey: 'fac' },
        { title: 'Metodo Pago', dataKey: 'metodo' },
        { title: `${'Banco/'}\n${'Referencia'}`, dataKey: 'banco' },
        // { title: 'Referencia', dataKey: 'ref' },
        { title: 'Tasa', dataKey: 'tasa' },
        { title: 'Monto Bs', dataKey: 'monto_bs' },
        { title: 'Monto $', dataKey: 'monto' },
        { title: 'Observación', dataKey: 'observacion' },
      ];
      var numberFormat = new Intl.NumberFormat('es-ES');
      var suma = this.pagosItems.length > 0 ? this.pagosItems.reduce((acc,item) => acc + parseFloat(item.monto.trim()), 0.0) : '0.0';
      let nameBank = {};
      exportPDF(
        columns,
        this.pagosItems.map((item) =>{
          return {
            cod_pago: `${item.cod_pago}\n${moment(item.fecha_recibido).format('DD/MM/YYYY')}`,
            cli_des: `${item.cli_des.trim()} (${item.co_cli})`,
            ven_des: `${item.ven_des.trim()}(${item.co_ven})`,
            estatus: item.status === 0 ? 'SIN PROC.' : item.status === 1 ? 'PROC.' : item.status === 2 ? 'ANUL.' : '',
            profit: `${item.nro_profit}`,
            fac: `${item.fact}`,
            metodo: `${item.metodo_pago}`,
            banco: `${this.items_bancos.find(ban => Number(ban.cod_banco) === item.cod_banco)?.nombre}\n${item.ref}`,
            //ref: `${item.ref}`,
            tasa: `${item.tasa}`,
            monto_bs: `${item.monto_bs} Bs`,
            monto: `$${item.monto}`,
            observacion: `${item.observacion}`,
          }
        }),
        {
          title: 'Listado de Cobros',
          range: `${moment(this.fecha_inicio).format('D MMM YYYY')} - ${moment(this.fecha_fin).format('D MMM YYYY')}`,
          amount: `${suma}$`,
        },
      );
    },
    generatePdf() {
      if(this.pagosItems.length === 0) {
        this.$root.$showAlert(
          'No se encontraron registros para Exportar.',
          'error',
        )
        return;
      }
      var columns = [
        { title: 'Código', dataKey: 'cod_pago' },
        { title: 'Recibido', dataKey: 'fecha_recibido'},
        {
          title: 'Cliente',
          dataKey: 'cli_des',
        },
        {
          title: 'Vendedor',
          dataKey: 'ven_des',
        },
        { title: 'Monto', dataKey: 'monto' },
        { title: 'Estatus', dataKey: 'estatus' },
      ];
      var numberFormat = new Intl.NumberFormat('es-ES');
      var suma = this.pagosItems.length > 0 ? this.pagosItems.reduce((acc,item) => acc + parseFloat(item.monto.trim()), 0.0) : '0.0';
      exportPDF(
        columns,
        this.pagosItems.map((item) =>{
          return {
            cod_pago: item.cod_pago,
            fecha_recibido: moment(item.fecha_recibido).format('D MMM YYYY'),
            cli_des: `${item.co_cli} - ${item.cli_des.trim()}`,
            ven_des: `${item.ven_des.trim()} (${item.co_ven})`,
            monto: `${item.monto}$`,
            estatus: item.status === 0 ? 'SIN PROC.' : item.status === 1 ? 'PROC.' : item.status === 2 ? 'ANUL.' : '',
          }
        }),
        {
          title: 'Listado de Cobros',
          range: `${moment(this.fecha_inicio).format('D MMM YYYY')} - ${moment(this.fecha_fin).format('D MMM YYYY')}`,
          amount: `${suma}$`,
        },
      );
    },
    successCreate (nro) {
      this.exito = true
      this.nroCobroExito = nro
      this.getPagos()
    },
  },
  computed: {
    pagosItems() {
      const filtros = this.customFiltros;
      const CustomFiltersKeys = Object.keys(filtros)
      return this.payments.filter((item) => {
        return CustomFiltersKeys.every(keyFiltro => {
          if(filtros[keyFiltro].length === 0) {
            return true;
          }

          if(keyFiltro === 'fecha' && filtros[keyFiltro].ini !== '' && filtros[keyFiltro].fin !== '') {
            return  moment(item[keyFiltro]).isBetween(moment(filtros[keyFiltro].ini).format("YYYY-MM-DD"), moment(filtros[keyFiltro].fin).format("YYYY-MM-DD"), undefined, '[]')
          }
          return typeof filtros[keyFiltro] === 'object'
            ? filtros[keyFiltro].includes(item[keyFiltro])
            : (filtros[keyFiltro].toString()).toLowerCase() === (item[keyFiltro].toString()).toLowerCase()
        })
      }).map((pago) => {
        return {
          ...pago,
          mode_lectura: true,
          client_editar: false,
        }
      })
    },
    tableShow () {
      return {
        display: this.add ? 'none' : '',
      }
    },
  },
}
</script>
