var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"tile":"","color":"transparent","flat":_vm.$vuetify.breakpoint.mobile || _vm.view === 'grid'}},[_c('v-card-text',{staticClass:"pa-0"},[(_vm.$vuetify.breakpoint.mobile || _vm.view === 'grid')?_c('v-data-iterator',{staticClass:"mt-2",attrs:{"items":_vm.data,"item-key":"co_art","items-per-page":10,"search":_vm.search,"loading":_vm.loading,"disable-pagination":_vm.loading,"footer-props":{itemsPerPageText: 'Mostrar'},"custom-filter":_vm.gridSearch},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var items = ref.items;
return [_c('v-row',[_vm._l((items),function(art,i){return [_c('mobile-inventario',{key:'mobileart-'+i,attrs:{"type":_vm.view,"articulo":art,"moneda":_vm.moneda,"pVenta":art[_vm.pventa],"colorPrecio":_vm.colorPventa},scopedSlots:_vm._u([{key:"action",fn:function(){return [_c('v-btn',{attrs:{"color":"white","text":"","to":{name: 'Articulo', params: {cod: art.co_art.trim(), moneda: _vm.moneda === '$' ? 'usd' : 'bs'}}}},[_vm._v(" Ver articulo "),_c('v-icon',{attrs:{"size":"20","right":""}},[_vm._v("mdi-arrow-right")])],1)]},proxy:true}],null,true)})]})],2)]}},{key:"loading",fn:function(){return [_c('v-row',_vm._l((3),function(load){return _c('v-col',{key:load,attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-skeleton-loader',{attrs:{"type":"card"}})],1)}),1)]},proxy:true}],null,false,3235737656)}):_c('v-data-table',{attrs:{"headers":_vm.dataheader,"items":_vm.data,"search":_vm.search,"loading":_vm.loading,"items-per-page":10,"disable-pagination":_vm.loading,"custom-filter":_vm.customSearch,"loading-text":"Obteniendo Data... Por favor espere."},scopedSlots:_vm._u([{key:"item.art_des",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex my-3"},[_c('div',{staticClass:"d-flex-inline"},[_c('v-avatar',{staticClass:"mr-2",attrs:{"size":"44","rounded":""}},[_c('v-img',{attrs:{"contain":"","src":((_vm.dir_image + item.co_art) + ".jpg")}})],1)],1),_c('div',{staticClass:"d-flex flex-column justify-start"},[_c('span',{staticClass:"primary--text font-weight-medium",domProps:{"textContent":_vm._s(item.art_des)}}),_c('span',{staticClass:"text-subtitle-2",domProps:{"textContent":_vm._s(item.lin_des)}})])])]}},{key:"item.prec_vta3",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center"},[_c('span',{staticClass:"font-weight-bold",style:(_vm.colorPventa)},[_vm._v(_vm._s(_vm.moneda)+" "+_vm._s(_vm._f("FormatCurrency")(item[_vm.pventa])))])])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":"","min-width":"200","offset-y":"","origin":"top right","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"px-0",attrs:{"plain":"","small":"","color":"icono"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"20","color":"icono"}},[_vm._v("mdi-dots-horizontal")])],1)]}}],null,true)},[_c('v-list',{staticClass:"py-0"},[_c('v-list-item-group',{attrs:{"color":"primary"}},[(_vm.$hasPermission('inventario.editar'))?[_c('v-list-item',{staticClass:"py-2",on:{"click":function($event){return _vm.actionImagen(item)}}},[_c('v-list-item-icon',{staticClass:"my-2 ml-1 mr-3 align-self-center"},[_c('v-icon',{attrs:{"left":"","color":"blue-grey"}},[_vm._v("mdi-image-multiple")])],1),_c('v-list-item-title',[_vm._v("Editar Imagen")])],1),_c('v-divider')]:_vm._e(),_c('v-list-item',{staticClass:"py-2",attrs:{"to":{name: 'Articulo', params: {cod: item.co_art.trim(), moneda: _vm.moneda === '$' ? 'usd' : 'bs'}}}},[_c('v-list-item-icon',{staticClass:"my-2 ml-1 mr-3 align-self-center"},[_c('v-icon',{attrs:{"left":"","color":"blue-grey"}},[_vm._v("mdi-arrow-right")])],1),_c('v-list-item-title',[_vm._v("Ver detalles")])],1)],2)],1)],1)]}},{key:"loading",fn:function(){return [_c('v-skeleton-loader',{attrs:{"type":"table-tbody"}})]},proxy:true}])})],1)],1),_c('modal-imagenes',{attrs:{"product":_vm.infoProduct},model:{value:(_vm.toggleImagen),callback:function ($$v) {_vm.toggleImagen=$$v},expression:"toggleImagen"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }