<template>
  <v-container id="administracion" fluid tag="section" class="pa-1">
    <main-header />
    <v-row>
      <v-col cols="12" class="pb-1">
        <v-card tile>
          <v-card-title class="pa-0">
            <v-tabs
              v-model="selectViewUser"
              background-color="grey lighten-5"
              color="secondary"
              show-arrows
              active-class="blue-grey lighten-5"
            >
              <v-tab>
                <v-icon
                  size="16"
                  class="mr-1"
                  v-text="'mdi-account-circle-outline'"
                ></v-icon>
                <span>Vendedores</span>
              </v-tab>
              <v-tab>
                <v-icon
                  size="16"
                  class="mr-1"
                  v-text="'mdi-account-multiple'"
                ></v-icon>
                <span>Administrativos</span>
              </v-tab>
            </v-tabs>
          </v-card-title>
          <!-- <v-card-subtitle class="col col-md-9 col-12">
            Encontrar todas las cuentas de vendedor de su empresa y los roles asociados
          </v-card-subtitle> -->
          <v-divider />
          <v-card-text class="pa-0">
            <v-tabs-items v-model="selectViewUser" touchless>
              <v-tab-item>
                <data-accounts
                  :data="users"
                  :search="search"
                  :headers="headers"
                  :roles="roles"
                  :loadingData="loadingUsers"
                  @procesado="getUsers"
                  @closeUser="SesionClose"
                />
              </v-tab-item>
              <v-tab-item>
                <data-admin-accounts
                  :data="usersAdmin"
                  :search="searchAdmin"
                  :headers="headers"
                  :roles="roles"
                  :loadingData="loadingUsersAdmin"
                  @procesado="getUsersAdmin"
                  @closeUser="SesionClose"
                />
              </v-tab-item>
            </v-tabs-items>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar
      :timeout="-1"
      :value="loadSesion"
      right
      color="primary"
      elevation="24"
    >
      <v-progress-circular indeterminate color="icono"></v-progress-circular>
      <span class="ml-4">Cerrando Sesión</span>
    </v-snackbar>
  </v-container>
</template>
<script>
export default {
  name: 'Administracion',
  components: {
    DataAccounts: () =>
      import(
        /* webpackChunkName: "data-accounts" */
        './Components/DataAccounts.vue'
      ),
    DataAdminAccounts: () =>
      import(
        /* webpackChunkName: "data-admin-accounts" */
        './Components/DataAdminAccounts.vue'
      )
  },
  data() {
    return {
      selectViewUser: '',
      users: [],
      usersAdmin: [],
      loadingUsers: false,
      loadingUsersAdmin: false,
      loadSesion: false,
      roles: [],
      search: '',
      searchAdmin: '',
      headers: [
        // { text: '#', value: 'co_ven', class: 'header-data', width: '80' },
        // { text: 'C.I.', value: 'cedula', class: 'header-data'},
        {
          text: 'Nombre',
          align: 'start',
          sortable: false,
          value: 'ven_des',
          class: 'header-data'
        },
        { text: 'Usuario/Rol', value: 'email', class: 'header-data' },
        { text: 'Portafolios', value: 'portafolios', class: 'header-data' },
        // { text: 'Rol', value: 'roles', class: 'header-data' },
        {
          text: 'Última Sesión',
          value: 'ultimo_uso',
          class: 'header-data',
          width: '220'
        },
        {
          text: 'Estatus',
          value: 'estatus',
          class: 'header-data d-flex justify-center align-center'
        },
        { text: '', value: 'actions', class: 'header-data', width: '70' }
      ]
    }
  },
  created() {
    this.getUsers()
    this.getUsersAdmin()
    this.getRoles()
  },
  methods: {
    async getUsers() {
      try {
        this.loadingUsers = true;
        const { data } = await this.$api.get('users');

        this.users = data.data;
        this.loadingUsers = false;
      } catch (error) {
        this.loadingUsers = false
        this.$root.$showAlert(
          'Lo siento, hubo un error al intentar obtener los Usuarios.',
          'error'
        )
      }
    },
    async getUsersAdmin() {
      try {
        this.loadingUsersAdmin = true;
        const { data } = await this.$api.get('users/listar');

        this.usersAdmin = data.data;
      } catch (error) {
        this.$root.$showAlert(
          'Lo siento, hubo un error al intentar obtener los Usuarios.',
          'error'
        )
      }
      this.loadingUsersAdmin = false
    },
    async getRoles() {
      try {
        const { data } = await this.$api.get('users/roles')
        this.roles = data.data
      } catch (error) {}
    },
    async SesionClose(id) {
      this.loadSesion = true
      try {
        const { data } = await this.$api.get(`users/${id}/cerrar`)
        this.$root.$showAlert(data.message, 'success')
        this.getUsers()
      } catch (error) {
        this.$root.$showAlert(
          'Lo siento, hubo un error al intentar cerrar la sesion del usuario',
          'error'
        )
      }
      this.loadSesion = false
    }
  }
}
</script>
