<template>
   <v-menu
      bottom
      left
      min-width="300"
      offset-y
      origin="top right"
      transition="scale-transition"
      close-on-content-click
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn
          min-width="0"
          outlined
          plain
          v-on="on"
          v-bind="attrs"
          small
          class="px-2"
          color="blue-grey"
        >
          <span class="font-weight-bold">P.V. {{precioSeleccionado}}</span>
          <v-icon small>mdi-menu-down</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item-group
          v-model="selected_pv"
          active-class="icono--text"
        >
          <template v-for="(item,i) in lista">
            <v-list-item
              :key="i"
              :value="item.value"
              v-if="item.value !== 'actions'"
            >
              <template v-slot:default="{ active }">
                <v-list-item-icon class="mr-2">
                   <v-icon small left :color="colors_pventa[item.value]">mdi-checkbox-blank-circle</v-icon> 
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="primary--text text--lighten-4" v-text="item.name"></v-list-item-title>
                </v-list-item-content>

                <v-list-item-action>
                  <v-icon v-if="active">mdi-check</v-icon>
                </v-list-item-action>
              </template>
            </v-list-item>  
          </template>
        </v-list-item-group>
      </v-list>
    </v-menu>  
</template>
<script>
export default {
  name:'BottonPV',
  props:{
    lista:{
      type: Array,
      default: () => ([])
    },
    value: String
  },
  data() {
    return {
      selected_pv: this.value,
      colors_pventa:{
        prec_vta2: '#24404E',
        prec_vta3: '#00CAE3',
        prec_vta4: '#009688',
        prec_vta5: '#795548',
      },
    }
  },
  watch:{
    value (val, oldVal) {
      if (val === oldVal) return

      this.selected_pv = val
    },
    selected_pv(val, oldVal){
      if (val === oldVal) return

      this.$emit('input', val);
    }
  },
  computed:{
    precioSeleccionado(){
      return this.selected_pv.slice(-1);
    }
  }

  
}
</script>