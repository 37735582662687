<template>
  <v-container
    id="potencial-detalles"
    tag="section"
    class="pa-0"
    fluid
  >    
    <v-row>
      <v-col cols="12" class="pa-0 light d-flex flex-row justify-space-between align-center pa-3">
        <div>
          <span class="text-h4 font-weight-bold mr-1">
            <v-icon size="20" class="mt-n1">mdi-account-tie</v-icon>
            Cliente
          </span>
          <span class="display-1 primary--text space-normal">
            {{cliente.nombre_comercial}} <strong>({{cliente.co_cli_po}})</strong>
          </span>     
        </div>
       <v-btn
          depressed
          plain
          class="py-1"
          :class="{'px-0': $vuetify.breakpoint.xsOnly }"
          :to="{name: 'Clientes Potenciales'}"
        >
          <v-icon size="20" :left="$vuetify.breakpoint.smAndUp">mdi-arrow-left-bold-box</v-icon>
          <span v-if="$vuetify.breakpoint.smAndUp">Regresar</span>
        </v-btn>
      </v-col>
      <v-col cols="12" class="pa-0">
        <v-tabs 
          v-model="tabsClient"
          background-color="blue-grey" 
          show-arrows
          dark
          slider-color="blue-grey lighten-4"
          active-class="active-tab-details"
          slider-size="3"
          class="fill-height"
        >
          <v-tab v-for="(tab,i) in tabs" :key="'details-'+i">
            <v-icon size="20" left v-text="tab.icon"></v-icon>
            <span v-text="tab.name"></span>
          </v-tab>
          <v-tab :class="{'d-none': tabsClient != tabs.length}">
            Visita:
            <span  
              class="d-inline-block text-truncate mx-1"
              style="max-width: 150px;"
            >{{detailsVisita.fecha | smartDate}}</span>
          </v-tab>  
        </v-tabs>
      </v-col>    
    </v-row>
    <v-row>
      <v-col cols="12" class="px-0">
        <v-card tile flat>
          <v-card-text class="pa-0">
            <v-tabs-items v-model="tabsClient" touchless>
              <v-tab-item>                         
                <tab-info v-if="!loadingClient" :info="cliente" />
              </v-tab-item>
              <v-tab-item>
                <visitas :items="itemsVisita" @show="getVisita"/>               
              </v-tab-item>
              <v-tab-item>
                <detalles-visita :visita="detailsVisita" />
              </v-tab-item>
            </v-tabs-items>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { get, sync } from 'vuex-pathify'
import { getInfoClient } from '@/services/clientes-potenciales.js'

export default {
  name: 'PotencialDetalles',
  components: { 
    TabInfo: () => import(
      /* webpackChunkName: "tab-info" */
      './TabInfo.vue'
    ),
    UpdateClient: () => import(
      /* webpackChunkName: "updat-client" */
      './Update.vue'
    ),
    Visitas: () => import(
      /* webpackChunkName: "visitas" */
      './Visitas.vue'
    ),
     DetallesVisita: () => import(
      /* webpackChunkName: "item-historia" */
      '@/views/Vendedores/Components/HistoricoVisitas/ItemHistoria.vue'
    ),
   },
  data: () => ({
    cliente: {},
    loadingClient: false,
    tabsClient: null,
    tabs:[
      {
        name: 'información',
        icon: 'mdi-text-box-check-outline',
        index: 0
      },
      {
        name: 'visitas',
        icon: 'mdi-alarm-check',
        index: 1
      },
    ],
    detailsVisita: {},
  }),
  created () {
    this.getCliente();    
  },
  methods:{
    async getCliente() {
      this.overlay = this.loadingClient = true
      try {
        this.cliente = await getInfoClient({id: this.co_cliente });  
      } catch (error) {
        this.$root.$showAlert(
          'Lo siento, hubo un error al intentar obtener los datos del Cliente.',
          'error'
        )
      } finally {
        setTimeout(() => {
          this.overlay = this.loadingClient = false;    
        }, 2000);
      }
    },
    getVisita(item) {
      this.detailsVisita = { ...item }
      this.tabsClient = this.tabs.length
    }
  },
  computed: {
    co_cliente: get('route/params@cod'),
    overlay: sync('app/overlay'),
    itemsVisita () {
      return this.cliente && this.cliente.visitas.length > 0
        ? this.cliente.visitas.map(item => {
          return {
            ...item,
            lat_cliente: this.cliente.lat,
            lng_cliente: this.cliente.lng,
          }
        })
        : []
    },
  },
}
</script>
<style lang="sass">
.active-tab-details 
  background-color: rgba(255, 255, 255, .12) !important
</style>
