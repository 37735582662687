<template>
   <v-menu
      bottom
      left
      min-width="300"
      offset-y
      origin="top right"
      transition="scale-transition"
      :close-on-content-click="false"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn
          min-width="0"
          outlined
          plain
          v-on="on"
          v-bind="attrs"
          small
          class="px-2"
          color="blue-grey"
        >
          <v-icon :left="$vuetify.breakpoint.xsOnly">mdi-view-column</v-icon>
          <span v-if="$vuetify.breakpoint.xsOnly">Columnas</span>
        </v-btn>
      </template>
      <v-list>
        <template v-for="(column,i) in columns">
          <v-list-item
            :key="i"
            link
            v-if="column.value !== 'actions'"
            :disabled="false"
          >
            <v-list-item-content>
              <v-list-item-title class="primary--text text--lighten-4" v-text="column.text"></v-list-item-title>
            </v-list-item-content>

            <v-list-item-action>
              <v-checkbox
                v-model="column.active"
                color="secondary"
                hide-details
              ></v-checkbox>
            </v-list-item-action>
          </v-list-item>  
        </template>
      </v-list>
    </v-menu>  
</template>
<script>
export default {
  name:'ColumnsViews',
  props:{
    columns:{
      type: Array,
      default: () => ([])
    }
  }
  
}
</script>