<template>
  <v-container
    id="bancos"
    fluid
    tag="section"
    class="pa-1"
  >
    <v-row>
      <v-col cols="12" class="py-1">
        <v-btn 
          plain
          color="primary"
          :to="{path: '/configuracion/roles'}"
        >
          <v-icon left>mdi-arrow-left</v-icon>
          <span class="font-weight-medium">Volver a Roles</span>
        </v-btn>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12" sm="10" md="8">
        <v-card tile class="rounded">
          <v-card-title class="d-flex align-center pb-2 grey lighten-5">
            <v-icon color="blue-grey" size="20" left>mdi-account-cog-outline</v-icon>
            <span class="primary--text text-h4 font-weight-medium text-capitalize">{{action}} Rol</span>
          </v-card-title>
            <v-divider/>
          <v-card-text class="px-8" style="position: relative !important">
            <v-overlay
              :value="loading || show_get"
              color="grey lighten-5" 
              class="text-center"
              opacity=".8"
              absolute
            >
              <v-progress-linear
                color="blue-grey lighten-2"
                indeterminate
                rounded
                height="4"
              />
              <h5 v-if="loading" class="blue-grey--text mt-2 font-weight-regular">
                {{action === 'crear' ? 'Guardando' : 'Actualizando'}}
              </h5>
            </v-overlay>
            <validation-observer ref="ADMIN_ROL_FORM" tag="div">
              <v-row>
                <v-col cols="12" sm="10" md="8" class="pt-2 pb-0">
                  <label-form text="Nombre" required />
                  <validation-provider name="Nombre" vid="rolInfo.name" rules="required" v-slot="{ errors }">
                    <v-text-field
                      v-model="rolInfo.name"
                      outlined
                      dense
                      :error-messages="errors[0]"
                    />
                  </validation-provider>
                </v-col>           
              </v-row> 
              <v-row>
                <v-col cols="12">
                  <v-card class="blue-grey lighten-5">
                    <v-card-title class="d-flex align-end justify-space-between pt-0 pb-1">
                      <span class="text-h5 font-weight-medium grey--text text--darken-4 text-uppercase">Permisos</span>
                      <v-checkbox
                        v-model="allPermisos"
                        label="Todos"
                        color="icono"
                        hide-details
                        dense
                        @change="allAction"
                      >
                        <template #label>
                          <span 
                            class="body-2 font-weight-medium grey--text text--darken-4" 
                            v-text="'Todos'"
                          />
                        </template>
                      </v-checkbox>
                    </v-card-title>
                    <v-divider class="mx-4" />
                    <v-card-text class="pt-2">
                      <v-skeleton-loader
                        v-if="loadingPermisos"
                        type="table-heading, table-heading, table-heading, table-heading"
                        class="transparent loader-permisos"
                      />
                      <div v-else>
                        <template v-for="(permiso,i) in permisosList">
                          <v-checkbox
                            :key="'listperm-N-'+i"
                            v-model="rolInfo.permisos"
                            :value="permiso.name"
                            color="icono"
                            hide-details
                            class="mt-1"
                            dense
                            light
                          >
                            <template #label>
                              <span 
                                class="subtitle-2 font-weight-medium grey--text text--darken-4" 
                                v-text="permiso.name"
                              />
                            </template>
                          </v-checkbox>
                        </template>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>      
            </validation-observer>
          </v-card-text>
          <v-card-actions class="d-flex justify-end mb-3 px-8 pb-5">
            <v-btn
              tile
              depressed
              color="grey lighten-4"
              class="px-4 mx-2"
              style="height: 35px !important"
              :small="$vuetify.breakpoint.mobile"
              :to="{path: '/configuracion/roles'}"
            >
              Cancelar
            </v-btn>
            <v-btn
              tile
              depressed
              color="icono"
              class="px-4 text-capitalize text-h6"
              dark
              style="height: 35px !important"
              :small="$vuetify.breakpoint.mobile"
              :loading="loading"
              @click="actionRol"
            >
              <v-icon dark left>mdi-check</v-icon>
              {{action}} Rol
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { get } from 'vuex-pathify'

const dataDefault = () => ({
  name: '',  
  permisos: [],
});

export default {
  name: 'GestionRol',
  data() {
    return {
      loadingPermisos: false,
      rolInfo: dataDefault(),    
      permisosList: [], 
      allPermisos: false,
      loading: false,
      show_get: false,
    }
  },
  created() {
    this.getPermisos()
    if(this.action === 'actualizar' && this.rol_id) {
      this.getRol();
    }
  },
  computed: {
    action: get('route/params@action'),
    rol_id: get('route/params@id'),
  },
  methods: {
    async getPermisos() {
      try {
        this.loadingPermisos = true;
        const { data } = await this.$api.get('users/permisos');

        this.permisosList = data.data;
      } catch (error) {
        this.$root.$showAlert(
          'Lo siento, hubo un error al intentar obtener los permisos.',
          'error'
        )
      }
      this.loadingPermisos = false;
    },
    async getRol() {
      try {
        this.show_get = true;
        const { data } = await this.$api.get(`users/roles/${this.rol_id}`);

        this.rolInfo.name = data.data.name;
        this.rolInfo.permisos = data.data.permissions.map(item => item.name);

      } catch (error) {
        this.$root.$showAlert(
          'Lo siento, hubo un error al intentar obtener el rol.',
          'error'
        )
      }
      this.show_get = false;
    },
    allAction() {
      if(this.allPermisos === true)
        this.rolInfo.permisos = this.permisosList.map(item => item.name)
      else
        this.rolInfo.permisos = []
    },
    async actionRol() {
      const valid = await this.$refs.ADMIN_ROL_FORM.validate();
      if(valid) {
        try {
          this.loading = true;         
          const { data } = await this.$api({
            method: this.action === 'crear' ? 'post' : 'put',
            url: this.action === 'crear' ? 'users/roles' : 'users/roles/' + this.rol_id,
            data: this.rolInfo,
          });
          this.$root.$showAlert(data.message, 'success');
          this.$router.push({path: '/configuracion/roles'});
        } catch (error) {
            this.$root.$showAlert(
              'Lo sentimos, hubo un error al intentar realizar esta acción en el Servidor.',
              'error'
            );
        } finally {
          this.loading = false;
        }
      }
    },
  },
  
}
</script>
<style lang="sass">
.loader-permisos .v-skeleton-loader__table-heading 
  & .v-skeleton-loader__heading
    max-width: 5% !important
  & .v-skeleton-loader__text
    max-width: 90% !important

.loader-permisos.theme--light.v-skeleton-loader .v-skeleton-loader__table-heading
  background: none !important

</style>