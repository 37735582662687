<template>
  <v-container
    id="Resumen_efectividad"
    fluid
    tag="section"
    class="pa-1"
  >
    <v-row>
      <v-col cols="12">
        <main-header /> 
        <v-row>
          <v-col cols="12" class="pt-0">
            <v-card tile id="data-efectividad-ven">
              <header-screenshot class="ma-0" v-show="capturing" />
              <v-card-title class="white">
                <v-row class="white">
                  <v-col cols="12" sm="4" md="4" lg="4" class="pb-1 d-flex align-center">
                    <v-menu
                      v-model="menu"
                      :close-on-content-click="false"
                      max-width="290px"
                      min-width="200px"
                      offset-y
                      z-index="100"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-chip
                          label
                          outlined
                          class="d-flex align-center pr-1"
                          v-on="on"
                          v-bind="attrs"
                        >
                          <v-icon size="20" :left="$vuetify.breakpoint.smAndUp">mdi-calendar</v-icon>
                          <span class="font-weight-bold text-h6 mr-1">Día: </span>
                          <span class="font-weight-bold text-h6 blue-grey--text">{{fecha_inicio | smartDate }}</span>
                          <v-icon 
                            size="22" 
                            class="ml-1"
                            v-text="menu ? 'mdi-menu-up' : 'mdi-menu-down' "
                          />
                        </v-chip>                       
                      </template>
                      <v-card>
                        <v-card-title class="px-4 primary--text text-h6 grey lighten-5">
                          <v-icon left>mdi-calendar-month</v-icon>
                          Seleccione Fecha a consultar
                        </v-card-title>
                        <v-card-text class="px-0 py-0 d-flex justify-center">
                         <v-date-picker
                            v-model="selected_date_report_evi"
                            color="icono"
                            no-title
                          >
                            <!-- <v-spacer></v-spacer>
                            <v-btn
                              color="primary"
                              @click="toggle_menu_date = false"
                            >
                              Aceptar
                            </v-btn> -->
                          </v-date-picker>
                        </v-card-text>
                        <v-card-actions class="px-3 grey lighten-5">
                          <v-spacer></v-spacer>
                          <v-btn
                            outlined
                            color="blue-grey"
                            @click="menu = false"
                          >
                            Cancelar
                          </v-btn>
                          <v-btn
                            color="primary"
                            @click="consultarDatos"
                            class="pr-3"
                          >
                            <v-icon left size="22">mdi-magnify</v-icon>
                            Consultar
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-menu>
                  </v-col>
                  <v-col id="btnsAction" cols="12" sm="8" md="8" lg="8" 
                    class="pb-1 d-flex flex-row align-center"
                    :class="{'justify-end': !$vuetify.breakpoint.xsOnly, 'justify-center': $vuetify.breakpoint.xsOnly}"
                  >  
                    <search-expand 
                      placeholder="Buscar Vendedor"
                      :expand="$vuetify.breakpoint.smAndUp"
                    />
                      <!-- v-model="searchPay" 
                      :load="loadingPay" -->
                    <v-btn
                      color="icono"
                      dark
                      small
                      outlined
                      class="ml-2 py-0"
                      :class="{'px-2': $vuetify.breakpoint.smAndDown}"
                      @click="generateReporteEvi"
                    >               
                      <v-icon 
                        size="22" 
                        :left="$vuetify.breakpoint.mdAndUp" 
                        v-text="'mdi-file-pdf-outline'"
                      />             
                      <span v-if="$vuetify.breakpoint.mdAndUp">exportar</span>          
                    </v-btn>               
                    <v-btn
                      color="icono"
                      small
                      outlined
                      class="ml-2 py-0"
                      :class="{'px-2': $vuetify.breakpoint.smAndDown}"
                      @click="captureTableToImage"
                    >               
                      <v-icon 
                        size="22" 
                        :left="$vuetify.breakpoint.mdAndUp" 
                        v-text="'mdi-camera-plus-outline'"
                      />             
                      <span v-if="$vuetify.breakpoint.mdAndUp">Capturar</span>          
                    </v-btn>              
                   </v-col>                 
                </v-row>
              </v-card-title>
              <v-card-text class="pa-0">
                <data-efectividad :datos="datosEfectividad" :loading="loadDatos" />
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from 'moment'
import { screenshot } from '@/util/CaptureData'
import exportPDF from '@/util/ExportPDF'
export default {
  name: 'ReportResumen',
  components: { 
    DataEfectividad: () => import(
      /* webpackChunkName: "data-efectividad" */
      './Components/DataEfectividad.vue'
    ),
  },
  data: () => ({
    datosEfectividad: [],
    datosTotales: [],
    loadDatos: false,
    legend: false,
    fecha_inicio: '',
    selected_date_report_evi: '',
    error:{
      active: false,
      msg: '',
    },
    menu: false,
    capturing: false,
  }),
  created() {
    this.fecha_inicio = moment().format("YYYY-MM-DD");
    this.getDatos();
  },
  methods:{
    async getDatos() {
      this.loadDatos = true;
      this.datosEfectividad = [];
      try {
        const { data } = await this.$api.get(`reportes/efectividad?fecha=${this.fecha_inicio}`);
        this.datosEfectividad = data.vendedores;
      } catch (error) {
        this.$root.$showAlert(
          'Lo siento, hubo un error al intentar obtener los datos del Resumen.',
          'error'
        )
      }
      this.loadDatos = false;
    },
    consultarDatos() {
      this.fecha_inicio = this.selected_date_report_evi;
      this.menu = false;
      this.getDatos();
    },
    async captureTableToImage() {
      if(this.itemsEvi.length === 0) {
        this.$root.$showAlert(
          'No se encontraron registros para Capturar.',
          'error',
        )
        return;
      }
      this.capturing = true
      const ID_ELEMENT = 'data-efectividad-ven'
      const NAME_IMAGE = 'Capture_reporte_efectividad'
      const footer = document.getElementsByClassName('v-data-footer')
      const btns = document.getElementById('btnsAction')
      try {
        footer[0].classList.add("d-none")
        btns.classList.remove('d-flex')
        btns.classList.add('d-none')
        await screenshot({id: ID_ELEMENT, title: NAME_IMAGE, width: this.$vuetify.breakpoint.xsOnly ? 900 : null })
        footer[0].classList.remove("d-none");
        btns.classList.remove('d-none')
        btns.classList.add('d-flex')       
        this.$root.$showAlert(
          'Capture Exitoso, espere la descarga.',
          'success',
        )     
      } catch (error) {
        this.$root.$showAlert(
          'Lo siento, hubo un error al intentar capturar.',
          'error'
        )
      } finally {        
        this.capturing = false
      }
    },
    generateReporteEvi() {
      if(this.datosEfectividad.length === 0) {
        this.$root.$showAlert(
          'No se encontraron registros para Exportar.',
          'error',
        )
        return;
      }
      var columns = [
        { title: 'Vendedor', dataKey: 'ven' },
        { title: 'Inicio', dataKey: 'hora_inicio'},
        { title: 'Fin', dataKey: 'hora_fin'},
        {
          title: 'C.V.',
          dataKey: 'cv',
        },
        {
          title: 'C.P.',
          dataKey: 'cp',
        },
        { title: 'E.V.I.', dataKey: 'evi' },
        { title: 'C.F.P.', dataKey: 'cfp' },
      ];
      exportPDF( 
        columns, 
        this.datosEfectividad.map((item) =>{
          return {
            ven: `${item.co_ven} - ${item.ven_des.trim()}`,
            hora_inicio: item.hora_inicio !== '' ? moment(item.hora_inicio).format('hh:mm A') : '',
            hora_fin: item.hora_fin !== '' ? moment(item.hora_fin).format('hh:mm A') : '',            
            cv: item.cv,
            cp: item.cp,
            evi: item.evi,
            cfp: item.cfp,
          }
        }), 
        {
          title: 'Reporte de Efectividad',
          range: `${moment(this.fecha_inicio).format('D MMM YYYY')}`,
        },
      );
    },
  },
  computed: {
    itemsEvi() {
      const per = 'ruta-vendedor.consulta'
      return per.includes('ruta-vendedor.consulta')
    },
  }
}
</script>

<style>

</style>