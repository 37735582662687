<template>
  <v-container
    id="visitas_totales"
    fluid
    tag="section"
    class="pa-0"
  >
    <v-row>
      <v-col cols="12">
        <main-header />
        <v-row>
          <v-col cols="12" class="pt-0">
            <v-card tile id="visitasTotales" >
              <!-- <header-screenshot class="ma-0" v-show="capturing" /> -->
              <v-card-title class="white px-0">
                <v-row class="pt-2">
                  <v-col cols="12">
                    <v-row class="mx-0">
                      <v-col
                        cols="12"
                        class="d-flex py-2 px-4"
                        :class="{'justify-space-between': $vuetify.breakpoint.smAndUp, 'justify-center flex-column': $vuetify.breakpoint.xsOnly, }">
                        <div
                          class="d-flex"
                          :class="{'justify-center flex-column': $vuetify.breakpoint.xsOnly, }"
                        >
                          <consultar-rango-fecha @consultar="consultarPrograma"  />
                          <btn-filter v-model="daysFiltered" @update="resetSlider" />
                          <v-chip
                            class="ml-2"
                            :class="{'fill-width d-flex justify-center': $vuetify.breakpoint.xsOnly, }"
                            style="overflow: visible !important"
                            label
                            outlined
                            @click="showLegend = !showLegend"
                          >
                            <v-fade-transition>
                              <v-icon
                                size="22"
                                left
                                v-text="showLegend ? 'mdi-chevron-double-up' : 'mdi-chevron-double-down'"
                              />
                            </v-fade-transition>
                            Leyenda
                          </v-chip>
                        </div>

                        <div :class="{'mt-4 d-flex justify-center': $vuetify.breakpoint.xsOnly}">
                          <v-btn
                            color="icono"
                            class="mx-2 white--text"
                            fab
                            x-small
                            depressed
                            @click="prevDay"
                          >

                            <v-icon>mdi-chevron-left</v-icon>
                          </v-btn>
                          <v-btn
                            color="icono"
                            fab
                            class="mx-2 white--text"
                            x-small
                            depressed
                            @click="nextDay"
                          >
                          <v-icon>mdi-chevron-right</v-icon>
                          </v-btn>
                        </div>
                      </v-col>
                      <leyenda v-model="showLegend" />
                      <v-col cols="12" class="pt-1"><v-divider class="elevation-1"/></v-col>
                    </v-row>
                  </v-col>
                </v-row>
               </v-card-title>
               <v-card-text class="pa-0">
                <loader-report v-if="loading"></loader-report>
                 <v-row v-else no-gutters class="">
                  <v-col v-if="dataReport.length === 0" cols="12" class="d-flex align-center justify-center flex-column height-fill py-12">
                    <span>
                      <v-icon size="62" color="blue-grey">mdi-database-marker</v-icon>
                    </span>
                    <span class="text-h4 blue-grey--text font-italic py-3">No se encontraron registros en el rango de fecha seleccionado.</span>
                  </v-col>
                  <template v-else>
                    <v-col cols="4" sm="2" class="box-ven">
                       <v-card
                        class="elevation-0"
                        tile
                       >
                         <v-row justify="center" class="mb-1">
                           <v-col cols="12" style="padding: 16px 12px;">
                             <v-card flat tile height="60px" class="d-flex flex-column align-center justify-center px-4">
                               <v-icon color="primary" size="32">mdi-account-supervisor-circle</v-icon>
                               <span class="primary--text text-h4 font-weight-bold">Vendedores</span>
                             </v-card>
                           </v-col>
                         </v-row>
                          <v-list two-line class="list-custom">

                             <v-list-item v-for="(ven, i) in vendedoresList" :key="`ved-${ven.co_ven}-${i}`">
                                <v-list-item-content>
                                   <v-list-item-title
                                     class="primary--text font-weight-bold"
                                     v-text="ven.RDV"
                                   />
                                   <v-list-item-subtitle class="primary--text font-weight-medium">COD.: {{ven.co_ven }}</v-list-item-subtitle>
                                 </v-list-item-content>
                             </v-list-item>
                             <!-- <v-divider v-if="i !== vendedoresList.length -1"></v-divider> -->
                          </v-list>
                       </v-card>
                    </v-col>
                    <v-col cols="8" sm="10" id="contentVisit">
                     <v-responsive min-width="100%">
                       <div class="mb-2">
                         <v-row justify="center" class="mx-0">
                           <v-sheet
                             class="container-slide transparent"
                           >
                             <v-slide-group
                               v-model="daysView"
                               show-arrows="always"
                               class="slide-visitas"
                             >
                               <v-slide-item
                                 v-for="(dia, i) in itemsProg"
                                 :key="'diaVisitaItem'+i"
                               >
                                 <v-col
                                 :key="'diaVisita'+i"
                                 class="card-calendar"
                                 >
                                 <!-- :class="{
                                   'card-calendar': $vuetify.breakpoint.mdAndUp,
                                   'card-calendar-sm': $vuetify.breakpoint.smOnly,
                                   'card-calendar-xs': $vuetify.breakpoint.xsOnly,
                                 }" -->
                                   <header-calendar :dia="dia" />
                                   <dia-visitas
                                     v-if="momento(dia.fecha).isSameOrAfter(rango_prog_ini) && momento(dia.fecha).isSameOrBefore(rango_prog_fin)"
                                     :dia="dia"
                                   />
                                 </v-col>
                               </v-slide-item>
                             </v-slide-group>
                           </v-sheet>
                         </v-row>
                       </div>
                     </v-responsive>
                    </v-col>
                  </template>
                 </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { get, sync } from 'vuex-pathify'
import moment from 'moment'
import es from 'moment/locale/es'
moment.locale('es', es);

export default {
  name: 'VisitasTotales',
  components:{
    ConsultarRangoFecha: () => import(
      /* webpackChunkName: "consultar-rango-fecha" */
      '@/widgets/ConsultarRangoFecha.vue'
    ),
    HeaderCalendar: () => import(
      /* webpackChunkName: "header-calendar" */
      './components/HeaderCalendar.vue'
    ),
    DiaVisitas: () => import(
      /* webpackChunkName: "dia-visitas" */
      './components/DiaVisitas.vue'
    ),
    BtnFilter: () => import(
      /* webpackChunkName: "btn-filter" */
      './components/BtnFilter.vue'
    ),
    LoaderReport: () => import(
      /* webpackChunkName: "btn-filter" */
      './components/LoaderReport.vue'
    ),
    Leyenda: () => import(
      /* webpackChunkName: "leyenda" */
      './components/Leyenda.vue'
    ),
  },
  data: () => ({
    daysView: 0,
    rango_prog_ini: moment().startOf('week').day(1).format("YYYY-MM-DD"),
    rango_prog_fin: moment().format("YYYY-MM-DD"),
    dia_actual: moment().format("YYYY-MM-DD"),
    dataReport: [],
    vendedoresList: [],
    loading: false,
    filtro_semana: 0,
    weeks: [],
    sortBy: 'hora_inicio',
    sortDesc: false,
    windowSize: {
      x: 0
    },
    sum: null,
    rest: null,
    daysFiltered: [],
    showLegend: true
  }),
  computed:{
    mini: sync('app/mini'),
    itemsProg() {
      let datos = [];
      const fechas = Object.keys(this.dataReport);
      if(fechas.length > 0) {
        for (const key in fechas) {
          // const format = fechas[key].split('-')
          datos.push({
            fecha: fechas[key],
            dia_semana: moment(fechas[key]).format("d"),
            clientes: this.dataReport[fechas[key]].length > 0 ? this.dataReport[fechas[key]] : [],
          })
        }
        return datos
        .filter(data => {
          if(this.daysFiltered.length === 0)
            return true

          return this.daysFiltered.includes(data.dia_semana)
        });
      }
      return [];
    },
  },
  created(){
    this.mini = true;
    this.getReportVisita();
  },
  mounted(){
    this.onResize()
  },
  methods: {
    momento: moment,
    onResize () {
      const contentCards = document.getElementById('contentVisit')
      this.windowSize = { x: contentCards ? contentCards.clientWidth : 0}
    },
    resetSlider(){
      this.daysView = 0
    },
    nextDay(){
      this.onResize()
      this.rest = false
      const WIDTH_CARD = 205
      const MAX_ITEMS = this.itemsProg.length ?? 0
      const ITEMS_PER_PAGE = Math.floor(this.windowSize.x / WIDTH_CARD)
      const hasFist = this.daysView >= 0 && this.daysView <= ITEMS_PER_PAGE

      if(this.daysView === MAX_ITEMS || ITEMS_PER_PAGE === 0){
        return
      }

      if(hasFist){
        this.daysView = ITEMS_PER_PAGE - 1
        this.daysView++
      }

      if(this.sum === false && !hasFist){
        this.daysView = this.daysView + ITEMS_PER_PAGE
      }
      this.daysView++
      this.sum = true
    },
    prevDay(){
      this.sum = false
      const WIDTH_CARD = 205
      const MAX_ITEMS = this.itemsProg.length ?? 0
      const ITEMS_PER_PAGE = Math.floor(this.windowSize.x / WIDTH_CARD)
      const hasFist = this.daysView >= 0 && this.daysView < ITEMS_PER_PAGE

      if(this.daysView === 0){
        console.log('salida')
        return
      }

      if(hasFist){
        this.daysView = 0
        // this.daysView--
        return
      }

      if(this.rest === false && !hasFist){
        this.daysView = this.daysView - (ITEMS_PER_PAGE - 1)
      }

      this.daysView--
      this.rest = true
    },
    calcularSemanas() {
      this.filtro_semana = 0;
      this.weeks = [];
      this.weeks = [{
        name:  moment(this.rango_prog_ini).format("DD") + ' - ' + moment(this.rango_prog_ini).startOf('week').day(6).format("DD") + moment(this.rango_prog_ini).format(" MMM YYYY"),
        start:  moment(this.rango_prog_ini).format("YYYY-MM-DD"),
        end:  moment(this.rango_prog_ini).startOf('week').day(6).format("YYYY-MM-DD"),
      }];
      const totalSemanas = Math.trunc(moment(this.rango_prog_fin).diff(moment(this.rango_prog_ini), 'weeks', true));

      if(totalSemanas > 0) {
        for (let index = 1; index <= totalSemanas; index++) {
          this.weeks.push({
            name:  moment(this.rango_prog_ini).add(index, 'w').startOf('week').format("DD") + ' - ' + moment(this.rango_prog_ini).add(index, 'w').startOf('week').day(6).format("DD") +  moment(this.rango_prog_ini).add(index, 'w').startOf('week').format(" MMMM, YYYY"),
            start: moment(this.rango_prog_ini).add(index, 'w').startOf('week').format("YYYY-MM-DD"),
            end:   moment(this.rango_prog_ini).add(index, 'w').startOf('week').day(6).format("YYYY-MM-DD"),
          });
        }
      }
    },
    async getReportVisita() {
      this.loading = true;
      try {
        const report_visita = await this.$api.get(`reportes/reporte-comparativo-visitas?fecha_inicio=${moment(this.rango_prog_ini).format("YYYY-MM-DD")}&fecha_final=${moment(this.rango_prog_fin).format("YYYY-MM-DD")}`);
        this.dataReport = report_visita.data?.vendedores;
        this.vendedoresList = Object.values(this.dataReport).length > 0
          ? Object.values(this.dataReport).find(item => item.length > 0)
          : []
        // this.calcularSemanas()
      } catch (error) {
         this.$root.$showAlert(
          'Lo siento, hubo un error al intentar obtener los datos del Programa de Visita.',
          'error',
        )
      } finally {
        this.loading = false;
      }
    },
    consultarPrograma(fecha) {
      this.rango_prog_ini = fecha.ini;
      this.rango_prog_fin = fecha.fin;
      this.getReportVisita();
    },
  }
}
</script>
<style lang="sass">
.container-slide
  width: 100%

.body-program
  background: #fdfdfd

.slide-visitas
  background: none
  & .v-slide-group__next, .v-slide-group__prev
    display: none
    -webkit-box-align: start
    -ms-flex-align: start
    align-items: flex-start !important
    min-width: 35px !important
    & .v-icon.v-icon
      position: fixed
      transform: translateY(15px)
      color: #003b49 !important
      font-size: 1.7rem
      background-color: #fff
      border-radius: 50%
      box-shadow: 0 3px 1px -2px rgba(0,0,0,.2),0 2px 2px 0 rgba(0,0,0,.14),0 1px 5px 0 rgba(0,0,0,.12)!important

.list-custom
  .v-list-item
    height: 136px !important
  :nth-child(2n+1).v-list-item
    background: #F4F5FC !important
    border-color: #F4F5FC !important

.box-ven
  box-shadow: 1px 0px 4px 0 rgba(0, 0, 0, 0.14), 0 0 0 0 rgba(0, 0, 0, 0), 0 0 0 0 rgba(0, 0, 0, 0)
  z-index: 10

</style>

