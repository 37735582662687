<template>
  <v-container
    id="bancos"
    fluid
    tag="section"
    class="pa-1"
  >
    <main-header />
    <v-row>
      <v-col cols="12" class="pb-1">
        <v-card tile>
          <v-card-title>
            <v-row>
              <v-col 
                cols="12" 
                class="d-flex align-center"
                :class="{'flex-row': $vuetify.breakpoint.smAndUp}">
                <search-expand 
                  v-model="search" 
                  :load="loadingRoles"
                  placeholder="Buscar rol"
                />
                <template v-if="$vuetify.breakpoint.smAndUp">
                  <v-btn
                    color="icono"
                    small
                    outlined
                    class="ml-md-2 ml-lg-2 ml-sm-2"
                    @click="getRoles"
                  >               
                    <v-icon 
                      size="22" 
                      :left="$vuetify.breakpoint.mdAndUp" 
                      v-text="'mdi-update'"
                    />             
                    <span v-if="$vuetify.breakpoint.mdAndUp">Actualizar</span>          
                  </v-btn> 
                  <v-btn
                    v-if="$hasPermission('configuracion.roles.crear')"
                    small
                    color="icono"
                    class="ml-2"
                    dark
                    :to="{name: 'GestionRol', params: {action: 'crear'}}"
                  >
                    <v-icon
                      size="22" 
                      :left="$vuetify.breakpoint.mdAndUp" 
                    >
                      mdi-plus
                    </v-icon>
                    Crear
                  </v-btn>
                </template>
              </v-col>
            </v-row>
            <v-row v-if="$vuetify.breakpoint.xsOnly" class="mt-2">
              <v-col cols="6" class="d-flex align-center">
                <v-btn
                  color="icono"
                  small
                  block
                  outlined
                  @click="getRoles"
                >               
                  <v-icon 
                    size="22" 
                    left
                    v-text="'mdi-update'"
                  />             
                  <span>Actualizar</span>          
                </v-btn> 
              </v-col>
              <v-col cols="6" class="d-flex align-center">
                <v-btn
                  small
                  color="icono"
                  dark
                  block
                 :to="{name: 'GestionRol', params: {action: 'crear'}}"
                >
                  <v-icon
                    size="22" 
                    left
                  >
                    mdi-plus
                  </v-icon>
                  Crear
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <v-divider />
          <v-card-text class="pa-0">
            <roles-data
              :datos="roles"
              :search="search"
              :loading-data="loadingRoles"            
            />                
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: 'Permisos',
  components: {
    RolesData: () => import(
      /* webpackChunkName: "roles-data" */
      './RolesData.vue'
    ),
  },
  data() {
    return {
      roles: [],
      loadingRoles: false,
      search: '',
      actionrol: 'crear',
      showGestion: false,
      rolData: {},
    }
  },
  created() {
    this.getRoles();
  },
  methods:{
    async getRoles() {
      try {
        this.loadingRoles = true;
        const { data } = await this.$api.get('users/roles');

        this.roles = data.data;
      } catch (error) {
        this.$root.$showAlert(
          'Lo siento, hubo un error al intentar obtener los Roles.',
          'error'
        )
      }
      this.loadingRoles = false;
    },
  },
}
</script>
