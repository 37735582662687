<template>
  <v-container
    id="vendedor-detalles"
    tag="section"
    class="pa-1"
  >  
    <main-header>
      <template #actionsMain>
        <v-btn-toggle
          v-model="currency"
          dense
          borderless
          color="icono"
          class="mx-2"
          @change="getArticulo"          
        >
          <v-btn color="blue-grey lighten-5" small value="bs" :disabled="loadArt">
            <span class="font-weight-bold">
              Bs.
            </span>
          </v-btn>

          <v-btn color="blue-grey lighten-5" small value="usd" :disabled="loadArt">
            <span class="font-weight-bold">
              $
            </span>
          </v-btn>
        </v-btn-toggle>
         <v-btn
          v-if="$hasPermission('inventario.editar')"
          color="blue-grey lighten-5"
          depressed
          small
          @click="modalImages = true"
        >
          <span v-if="$vuetify.breakpoint.smAndUp">Cambiar Imagén</span>
          <v-icon size="28" :right="$vuetify.breakpoint.smAndUp">mdi-image-multiple</v-icon>
        </v-btn>
      </template>
    </main-header>
    <v-row justify="center">
      <v-col cols="12" class="pt-1 mx-auto" v-if="loadArt">
        <v-skeleton-loader
          type="article, list-item-avatar-three-line"
        ></v-skeleton-loader>
      </v-col>
      <v-col cols="12" class="pt-1 mx-auto" v-else>
        <v-card outlined>
          <v-card-text class="px-md-8">     
            <v-row>
              <v-col cols="12" sm="12" md="11" class="mx-auto">
                <v-row>
                  <v-col cols="12" sm="5" md="3" lg="2" class="pb-0"> 
                    <v-sheet
                      outlined
                      class="px-1 grey lighten-5"
                      elevation="2"
                      style="cursor:pointer !important"
                      @click="modalImages = true"
                    >
                      <v-img
                        :height="$vuetify.breakpoint.smAndUp ? '170px' : '250px'"
                        contain
                        class="mx-auto"
                        :src="`${dir_image + infoArt.co_art}.jpg`"
                      >
                      </v-img>
                    
                    </v-sheet>
                  </v-col>
                  <v-col cols="12" sm="7" md="5" lg="10" class="pb-0 pt-3">
                    <v-row>
                      <v-col cols="12">
                        <span class="caption blue-grey--text text--darken-3 d-block">Nombre:</span>
                        <span class="text-h5 primary--text font-weight-bold" v-text="infoArt.art_des"></span>
                      </v-col>
                    </v-row>   
                    <v-row>
                      <v-col cols="12">
                        <span class="caption blue-grey--text text--darken-3 d-block">Código:</span>
                        <span class="text-h5 primary--text font-weight-bold" v-text="infoArt.co_art"></span>
                      </v-col>
                    </v-row>                   
                    <v-row>
                      <v-col cols="12">
                        <span class="caption blue-grey--text text--darken-3 d-block">Stock:</span>
                        <span class="text-h5 primary--text font-weight-bold" v-text="infoArt.stock_act"></span>
                      </v-col>
                    </v-row>                   
                  </v-col>                 
                  <v-col cols="12">
                    <v-divider />
                  </v-col>
                </v-row>
                <v-row>             
                  <v-col cols="12" md="6" class="px-4">
                    <v-subheader class="ml-0 pl-0">Detalles</v-subheader>
                    <v-row>
                      <v-col cols="6">
                        <span class="caption blue-grey--text text--darken-3 d-block">Descripción:</span>
                      </v-col>
                      <v-col cols="6">
                        <span class="primary--text font-weight-bold" v-text="infoArt.lin_des"></span>
                      </v-col>
                    </v-row>
                    <v-row class="grey lighten-5 my-2">
                      <v-col cols="6">
                        <span class="caption blue-grey--text text--darken-3 d-block">Sub:</span>
                      </v-col>
                      <v-col cols="6">
                        <span class="primary--text font-weight-bold" v-text="infoArt.subl_des"></span>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6">
                        <span class="caption blue-grey--text text--darken-3 d-block">Categoría:</span>
                      </v-col>
                      <v-col cols="6">
                        <span class="primary--text font-weight-bold" v-text="infoArt.co_cat"></span>
                      </v-col>
                    </v-row>
                    <v-row class="grey lighten-5 my-2">
                      <v-col cols="6">
                        <span class="caption blue-grey--text text--darken-3 d-block">Proveedor:</span>
                      </v-col>
                      <v-col cols="6">
                        <span class="primary--text font-weight-bold" v-text="infoArt.co_prov"></span>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6">
                        <span class="caption blue-grey--text text--darken-3 d-block">Peso:</span>
                      </v-col>
                      <v-col cols="6">
                        <span class="primary--text font-weight-bold" v-text="infoArt.peso"></span>
                      </v-col>
                    </v-row>
                    <v-row class="grey lighten-5 my-2">
                      <v-col cols="6">
                        <span class="caption blue-grey--text text--darken-3 d-block">Unid. Venta:</span>
                      </v-col>
                      <v-col cols="6">
                        <span class="primary--text font-weight-bold" v-text="infoArt.uni_venta"></span>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" md="6" class="px-4">
                    <v-subheader class="ml-0 pl-0">Precio de Venta</v-subheader>
                    <v-row>
                      <v-col cols="6">
                        <span class="caption blue-grey--text text--darken-3 d-block">Precio de Venta 2:</span>
                      </v-col>
                      <v-col cols="6">
                        <span class="primary--text font-weight-bold">
                          {{simbolo}} {{infoArt.prec_vta2 | FormatCurrency}}
                        </span>
                      </v-col>
                    </v-row>
                    <v-row class="grey lighten-5 my-2">
                      <v-col cols="6">
                        <span class="caption blue-grey--text text--darken-3 d-block">Precio de Venta 3:</span>
                      </v-col>
                      <v-col cols="6">
                        <span class="primary--text font-weight-bold">
                          {{simbolo}} {{infoArt.prec_vta3 | FormatCurrency}}
                        </span>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6">
                        <span class="caption blue-grey--text text--darken-3 d-block">Precio de Venta 4:</span>
                      </v-col>
                      <v-col cols="6">
                        <span class="primary--text font-weight-bold">
                         {{simbolo}}  {{infoArt.prec_vta4 | FormatCurrency}}
                        </span>
                      </v-col>
                    </v-row>
                    <v-row class="grey lighten-5 my-2">
                      <v-col cols="6">
                        <span class="caption blue-grey--text text--darken-3 d-block">Precio de Venta 5:</span>
                      </v-col>
                      <v-col cols="6">
                        <span class="primary--text font-weight-bold">
                          {{simbolo}} {{infoArt.prec_vta5 | FormatCurrency}}
                        </span>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
               </v-col>
            </v-row>       
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <template v-if="$hasPermission('inventario.editar')">
      <modal-imagenes v-model="modalImages" :product="infoArt" @update="getArticulo"/>
    </template>
  </v-container>
</template>
<script>
import { get } from 'vuex-pathify'
export default {
  name: 'DetailsArticulo',
  components:{
    ModalImagenes: () => import(
      /* webpackChunkName: "modal-imagenes" */
      './Components/ModalImagenes.vue'
    ),
  },
  data: () => ({
    loadArt: false,
    currency: '',
    infoArt: {},
    modalImages: false,
    dir_image: process.env.VUE_APP_BASE_INVENTARIO,
  }),
  created() {
    this.currency = this.moneda;
    this.getArticulo();
  },
  methods:{
    async getArticulo() {
      this.loadArt = true
      this.infoArt = {}
      try {
        const { data } = await this.$api.get(`articulos/${this.codArt}/show?moneda=${this.currency}`);

        this.infoArt = data.data[0];
      } catch (error) {
        this.$root.$showAlert(
          'Lo siento, hubo un error al intentar obtener los datos del Articulo.',
          'error'
        )
      }
      this.loadArt = false;     
    },
  },
  computed:{
    codArt: get('route/params@cod'),
    moneda: get('route/params@moneda'),
    simbolo(){
      return this.currency === 'usd' ? '$' : 'Bs.';
    } 
  }
}
</script>
<style lang="sass">

.v-carousel__controls > .v-item-group > .theme--light.v-btn.v-btn--icon
  color: #003b49 !important
  & .v-carousel__controls__item .v-icon
    opacity: 1 !important

</style>