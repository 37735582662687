<template>
  <v-container
    id="bancos"
    fluid
    tag="section"
    class="pa-1"
  >
    <main-header />
    <v-row>
      <v-col cols="12" class="pb-1">
        <v-card tile>
          <v-card-title>
            <v-row>
              <v-col 
                cols="12" 
                class="d-flex align-center"
                :class="{'flex-row': $vuetify.breakpoint.smAndUp}">
                <search-expand 
                  v-model="search" 
                  :load="loadingBancos"
                  placeholder="Buscar banco"
                />
                <template v-if="$vuetify.breakpoint.smAndUp">
                  <v-btn
                    color="icono"
                    small
                    outlined
                    class="ml-md-2 ml-lg-2 ml-sm-2"
                    @click="getBancos"
                  >               
                    <v-icon 
                      size="22" 
                      :left="$vuetify.breakpoint.mdAndUp" 
                      v-text="'mdi-update'"
                    />             
                    <span v-if="$vuetify.breakpoint.mdAndUp">Actualizar</span>          
                  </v-btn> 
                  <v-btn
                    v-if="$hasPermission('administracion.bancos.crear')"
                    small
                    color="icono"
                    class="ml-2"
                    dark
                    @click="crearBanco"
                  >
                    <v-icon
                      size="22" 
                      :left="$vuetify.breakpoint.mdAndUp" 
                    >
                      mdi-plus
                    </v-icon>
                    Crear
                  </v-btn>
                </template>
              </v-col>
            </v-row>
            <v-row v-if="$vuetify.breakpoint.xsOnly" class="mt-2">
              <v-col cols="6" class="d-flex align-center">
                <v-btn
                  color="icono"
                  small
                  block
                  outlined
                  class="ml-md-2 ml-lg-2"
                  @click="getBancos"
                >               
                  <v-icon 
                    size="22" 
                    left 
                    v-text="'mdi-update'"
                  />             
                  <span>Actualizar</span>          
                </v-btn> 
              </v-col>
              <v-col cols="6" class="d-flex align-center">
                <v-btn
                  small
                  color="icono"
                  class="ml-md-2 ml-lg-2"
                  dark
                  block
                  @click="crearBanco"
                >
                  <v-icon
                    size="22" 
                    left
                  >
                    mdi-plus
                  </v-icon>
                  Crear
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <v-divider />
          <v-card-text class="pa-0">
            <bancos-data
              :datos="bancos"
              :search="search"
              :loading-data="loadingBancos"   
              @update="editBanco"           
            />                
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <modal-banco 
      v-model="showGestion" 
      :action="actionBan" 
      :data="bankData" 
      @procesado="getBancos"
    />

  </v-container>
</template>
<script>
export default {
  name: 'Bancos',
  components: {
    BancosData: () => import(
      /* webpackChunkName: "bancos-data" */
      './BancosData.vue'
    ),
    ModalBanco: () => import(
      /* webpackChunkName: "modal-banco" */
      './ModalBanco.vue'
    )
  },
  data() {
    return {
      bancos: [],
      loadingBancos: false,
      loadSesion: false,
      search: '',
      actionBan: 'crear',
      showGestion: false,
      bankData: {},
    }
  },
  created() {
    this.getBancos();
  },
  methods:{
    async getBancos() {
      try {
        this.loadingBancos = true;
        const { data } = await this.$api.get('administracion/bancos');

        this.bancos = data.data;
      } catch (error) {
        this.$root.$showAlert(
          'Lo siento, hubo un error al intentar obtener los bancos.',
          'error'
        )
      }
      this.loadingBancos = false;
    },
    crearBanco() {
      this.bankData = {}
      this.actionBan = 'crear'
      this.showGestion = true
    },
    editBanco(data) {
      this.bankData = {}
      this.actionBan = 'editar'
      this.bankData = {...data}
      this.showGestion = true
    },
  },
}
</script>
