<template>
  <v-container
    id="Resumen_cobranza"
    fluid
    tag="section"
    class="pa-1"
  >
    <v-row>
      <v-col cols="12">
        <main-header /> 
        <v-row>
          <v-col cols="12" class="pt-0">
            <v-card tile>
              <v-card-title>
                <v-row>
                  <v-col cols="12" sm="6" md="6" class="d-flex align-center">
                    <consultar-rango-fecha all-items @consultar="solicitarCxcGeneral" />
                  </v-col>
                  <v-col 
                    cols="12" 
                    class="d-flex justify-end align-center"
                    sm="6" 
                    md="6"
                  >
                    <search-expand 
                      v-model="search_cobranza" 
                      :load="loadCobranza"
                      placeholder="Buscar"
                      :expand="!$vuetify.breakpoint.xsOnly"
                    />
                    <v-btn
                      color="icono"
                      class="mx-2"
                      outlined
                      small
                      :disabled="loadCobranza"
                      @click="getCobranzas"
                    >               
                      <v-icon size="22" :left="!$vuetify.breakpoint.smAndDown">mdi-update</v-icon>             
                      <span v-if="!$vuetify.breakpoint.smAndDown">Actualizar</span>        
                    </v-btn>
                    <div class="d-flex align-center ml-1">
                      <v-btn-toggle
                        v-model="monedaC"
                        dense
                        color="primary"
                        borderless
                      >
                        <v-btn small :min-width="53" class="px-2" value="bolivares">
                          <span class="font-weight-bold display blue-grey--text">
                            Bs.
                          </span>
                        </v-btn>

                        <v-btn small :min-width="53" class="px-2" value="dolares">
                          <span class="font-weight-bold display blue-grey--text">
                            $
                          </span>
                        </v-btn>
                      </v-btn-toggle>
                    </div>
                    <v-btn
                      color="primary"
                      class="mx-2"
                      small
                      @click="showFilters = !showFilters"
                    >               
                      <v-icon size="22" :left="!$vuetify.breakpoint.smAndDown">mdi-sort-variant</v-icon>             
                      <span v-if="!$vuetify.breakpoint.smAndDown">Filtros</span>          
                    </v-btn>
                  </v-col>
                </v-row> 
              </v-card-title>
              <v-card-text class="px-0">
                <v-row>
                  <v-col cols="12" class="pb-0">
                    <v-divider />
                  </v-col>
                </v-row>   
                <v-expand-transition>
                  <div v-if="showFilters">
                    <v-row class="px-5 py-3">
                      <v-col cols="12" class="pb-0">
                        <span class="caption text-uppercase d-block font-weight-bold">
                          Tipos de Precios
                        </span>            
                      </v-col>
                      <v-col cols="4" sm="3" md="2">
                        <v-checkbox
                        v-model="selected_price"
                          label="Lista Precio 1"
                          color="icono"
                          value="1"
                          hide-details
                          class="mt-0 pt-0"
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="4" sm="3" md="2">
                        <v-checkbox
                        v-model="selected_price"
                          label="Lista Precio 2"
                          color="icono"
                          value="2"
                          hide-details
                          class="mt-0 pt-0"
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="4" sm="3" md="2">
                        <v-checkbox
                        v-model="selected_price"
                          label="Lista Precio 3"
                          color="icono"
                          value="3"
                          hide-details
                          class="mt-0 pt-0"
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="4" sm="3" md="2">
                        <v-checkbox
                        v-model="selected_price"
                          label="Lista Precio 4"
                          color="icono"
                          value="4"
                          hide-details
                          class="mt-0 pt-0"
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="4" sm="3" md="2">
                        <v-checkbox
                        v-model="selected_price"
                          label="Lista Precio 5"
                          color="icono"
                          value="5"
                          hide-details
                          class="mt-0 pt-0"
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="12" class="pb-0">
                        <span class="caption text-uppercase d-block font-weight-bold">
                          Rango de Fechas {{rango_inicio}} {{rango_final}}
                        </span>            
                      </v-col>
                      <v-col cols="12" 
                        class="d-flex flex-row align-end"
                        :class="{'flex-row': $vuetify.breakpoint.smAndUp, 'flex-column ': $vuetify.breakpoint.xsOnly}"
                        >
                        <div>
                          <label-form text="Inicio" required/>
                          <input-fecha 
                            v-model="rango_inicio" 
                            color="blue-grey lighten-5"
                            />
                        </div>
                        <span class="blue-grey--text font-weight-medium mx-4 mt-3 caption">-</span>
                        <div>
                          <label-form text="Final" required/>
                          <input-fecha 
                            v-model="rango_final" 
                            color="deep-orange lighten-5"
                            :disabled="!rango_inicio" 
                            :min="rango_inicio"                
                          /> 
                        </div>
                        <div>
                          <v-btn
                            color="blue-grey lighten-5"
                            class="mx-2"
                            depressed
                            @click="resetDate"
                          >                          
                            Limpiar        
                          </v-btn>
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                </v-expand-transition>                        
                <v-row>
                  <v-col cols="12">
                    <v-toolbar color="header-data" dense flat>
                      <v-row>
                        <v-col cols="1" class="d-flex flex-row align-center px-0" v-if="!$vuetify.breakpoint.mobile">
                          <a href="" class="text-decoration-none" @click.prevent="activeSort('campo2')">
                            <span class="display" :class="{'font-weight-bold': columnActived('campo2') }">Pedido</span>
                            <template v-if="columnActived('campo2')">
                              <v-fade-transition>
                                <v-icon size="20" class="ml-1" color="blue-grey" v-text="orderSort ? 'mdi-sort-variant' : 'mdi-sort-reverse-variant'"/>
                              </v-fade-transition>
                            </template>
                            <v-icon v-else color="blue-grey lighten-3">mdi-menu-swap</v-icon>
                          </a>
                        </v-col>
                        <v-col cols="6" sm="4" md="4" lg="4" class="d-flex flex-row align-center">
                          <a href="" class="text-decoration-none" @click.prevent="activeSort('cli_des')">
                            <span class="display" :class="{'font-weight-bold': columnActived('cli_des')}" v-text="'Cliente'"></span>
                            <template v-if="columnActived('cli_des')">
                              <v-icon size="20" class="ml-1" color="blue-grey" v-text="orderSort ? 'mdi-sort-variant' : 'mdi-sort-reverse-variant'"/>
                            </template>
                            <v-icon color="blue-grey lighten-3" v-else>mdi-menu-swap</v-icon>
                          </a>
                        </v-col>
                        <v-col cols="4" sm="4" md="3" lg="3" class="d-flex flex-row align-center" v-if="$vuetify.breakpoint.smAndUp">
                          <a href="" class="text-decoration-none" @click.prevent="activeSort('ven_des')">
                            <span class="display" :class="{'font-weight-bold': columnActived('ven_des')}" v-text="'Vendedor'"></span>
                            <template v-if="columnActived('ven_des')">
                              <v-icon size="20" class="ml-1" color="blue-grey" v-text="orderSort ? 'mdi-sort-variant' : 'mdi-sort-reverse-variant'"/>
                            </template>
                            <v-icon color="blue-grey lighten-3" v-else>mdi-menu-swap</v-icon>
                          </a>
                        </v-col>
                        <v-col cols="6" sm="2" md="2" class="d-flex flex-row justify-center align-center">
                          <a href="" class="text-decoration-none" @click.prevent="activeSort('saldo')">
                            <span class="display" :class="{'font-weight-bold': columnActived('saldo')}">Saldo</span>
                            <template v-if="columnActived('saldo')">
                               <v-icon size="20" class="ml-1" color="blue-grey" v-text="orderSort ? 'mdi-sort-variant' : 'mdi-sort-reverse-variant'"/>
                            </template>
                            <v-icon v-else color="blue-grey lighten-3">mdi-menu-swap</v-icon>
                          </a>
                        </v-col>
                        <!-- <v-col cols="1" class="d-flex flex-row justify-center align-center" v-if="!$vuetify.breakpoint.mobile">
                          <span class="display">Precio Venta</span>
                        </v-col>
                        <v-col cols="6" md="2" lg="2" class="d-flex flex-row align-center" v-if="!$vuetify.breakpoint.mobile">
                          <span class="display">Recibido</span>
                        </v-col> -->
                        <v-col cols="6" md="2" lg="2" class="d-flex flex-row px-0 align-center px-0" v-if="!$vuetify.breakpoint.mobile">
                          <a href="" class="text-decoration-none" @click.prevent="activeSort('dias_vencida')">
                            <span class="display" :class="{'font-weight-bold': columnActived('dias_vencida')}">Días Vencida</span>
                            <template v-if="columnActived('dias_vencida')">
                               <v-icon size="20" class="ml-1" color="blue-grey" v-text="orderSort ? 'mdi-sort-variant' : 'mdi-sort-reverse-variant'"/>
                            </template>
                            <v-icon v-else color="blue-grey lighten-3">mdi-menu-swap</v-icon>
                          </a>
                        </v-col>
                      </v-row>
                    </v-toolbar>
                    <v-data-iterator
                      :items="cuentasxcobrarG"
                      :items-per-page="10"
                      :search="search_cobranza"
                      :loading="loadCobranza"
                      loading-text="Buscando Cuentas por Cobrar..."
                      :footer-props="{itemsPerPageText: 'Mostrar'}"
                      :sort-by="sortBy"
                      :sort-desc="orderSort"
                    >
                      <template v-slot:default="{ items }">
                        <v-expansion-panels focusable hover>
                          <template v-for="(cxc,i) in items">
                            <item-cxc-general :cuenta="cxc" :currency="simbolo" :key="'cxcgeneral-'+i" />                
                          </template>
                        </v-expansion-panels>
                      </template>
                      <template v-slot:loading>
                        <v-skeleton-loader
                          type="list-item-two-line, list-item, list-item-two-line"
                        ></v-skeleton-loader>
                      </template>
                      <template v-slot:no-data>
                        <div class="text-center pa-4">
                          <span class="blue-grey--text font-italic">No se encontraron Cuentas por Cobrar en {{monedaC}}</span>
                        </div>
                      </template>
                    </v-data-iterator>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
         </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import moment from 'moment'
export default {
  name: 'Cobranzas',
  components:{
    ItemCxcGeneral: () => import(
      /* webpackChunkName: "data-cxc-general" */
      './Components/ItemCxcGeneral.vue'
    ),
    InputFecha: () => import(
      /* webpackChunkName: "input-fecha" */
      '@/views/Reportes/Components/InputFecha.vue'
    ),
    ConsultarRangoFecha: () => import(
      /* webpackChunkName: "consultar-rango-fecha" */
      '@/widgets/ConsultarRangoFecha.vue'
    ),
  },
  data: () =>({
    search_cobranza: '',
    loadCobranza: false,
    cobranzas: [],
    monedaC: 'dolares',
    selected_price: [],
    rango_inicio: '',
    rango_final: '',
    showFilters: false,
    pageData: 1,
    sortBy: '',
    orderSort: true,
    fecha_cxcG_ini: '',
    fecha_cxcG_fin: '',
  }),
  computed: {
    cuentasxcobrarG () {
      return this.cobranzas[`cxc_${this.monedaC}`] ? this.cobranzas[`cxc_${this.monedaC}`].map(function (item) {
        const recibido = new moment(item.fecha_recibido);
        const hoy = new moment();
        return {
          ...item,
          retraso: hoy.diff(recibido, 'day')
        }
      }).filter (item => {
        let [moneda, precio] = item.TipoPrecio != null ? item.TipoPrecio.toString().split('') : ['',''];
        return this.selected_price.length > 0 ? this.selected_price.includes(precio) : item
      }).filter(item => {
        return this.rango_inicio != '' && this.rango_final != '' ? moment(item.fecha_recibido).isBetween(this.rango_inicio, this.rango_final, undefined, '[]') : item ;
      }) : [];
    },
    simbolo () {
      return this.monedaC === 'dolares' ? '$' : 'Bs.'
    },    
  },
  created () {
    this.fecha_cxcG_ini = moment().startOf('week').day(1).format("YYYY-MM-DD");
    this.fecha_cxcG_fin = moment().format('YYYY-MM-DD');
    this.getCobranzas()
  },
  methods: {
    async getCobranzas () {
      this.loadCobranza = true;
      this.cobranzas= [];
      try {
        const { data } = await this.$api.get(`reportes/cobranzas?fecha_inicio=${this.fecha_cxcG_ini}&fecha_final=${this.fecha_cxcG_fin}`);
        this.cobranzas = data;
      } catch (error) {
         this.$root.$showAlert(
          'Lo siento, hubo un error al intentar obtener las Cobranzas Solicitadas.',
          'error',
        )
      }
      this.loadCobranza = false;
    },
    solicitarCxcGeneral(fechas) {
      this.fecha_cxcG_ini = fechas.ini;
      this.fecha_cxcG_fin = fechas.fin;
      this.getCobranzas();
    },
    resetDate () {
      this.rango_inicio = ''
      this.rango_final = ''
    },
    activeSort(column){
      this.sortBy = column;
      this.orderSort = !this.orderSort;
    },
    columnActived(column){
      return column === this.sortBy
    }
  }
}
</script>