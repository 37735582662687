<template>
    <v-card tile>
        <v-overlay
          :value="loadingHorario"
          color="white"
          absolute
          opacity=".8"
        >
          <v-progress-circular
            indeterminate
            size="64"
            color="primary"
          ></v-progress-circular>
        </v-overlay>
        <v-card-text>
          <v-list>
            <v-list-item>
              <v-list-item-content class="text-center">
                <span class="text-h4 font-weight-bold primary--text">Horario de Visita</span>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <validation-observer ref="HORARIO_FORM" tag="div">
            <v-row class="d-block flex" style="display: flex;">
                <div class="flex">
                <v-col cols="6">
                    <label-form text="Fecha de inicio" required />
                    <validation-provider
                        v-slot="{ errors }"
                        name="Fecha"
                        vid="fecha"
                        rules="required"
                        >
                    <fecha-input
                        v-model="horarioInfo.fecha_inicio"
                        :min="horarioInfo.fecha_inicio || min_date_input"
                        color="blue-grey lighten-5"
                        :error="errors[0]"
                        width-input="220px"
                    />
                        <!-- :min="min_date_input"
                        :picker-initial="min_date_input" -->
                    </validation-provider>
                </v-col>
                <v-col cols="6">
                    <label-form text="Vendedor" required />
                    <v-select
                    v-model="selected"
                    :hint="`${sellersList.ven_des}, ${sellersList.co_ven}`"
                    :items="sellersList"
                    item-text="ven_des"
                    item-value="co_ven"
                    label="Seleccionar vendedor"
                    return-object
                    single-line
                    dense
                    solo
                    ></v-select>
                </v-col>
            </div>
            </v-row>

            <v-row class="d-block">
              <v-col cols="12" class="pt-0">
              <label-form text="Frecuencia de Visita" required />
                <validation-provider name="Frecuencia" vid="horarioInfo.frecuencia" rules="required" v-slot="{ errors }">
                  <v-radio-group
                    v-model="horarioInfo.frecuencia"
                    row
                    :error-messages="errors[0]"
                    class="mt-1"
                  >
                    <v-radio
                      label="Semanal"
                      value="semanal"
                      color="icono"
                    />
                    <v-radio
                      label="Quincinal"
                      value="quincenal"
                      color="icono"
                    />
                    <v-radio
                      label="Mensual"
                      value="mensual"
                      color="icono"
                    />
                  </v-radio-group>
                </validation-provider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="pt-0">
                <label-form text="Seleccione los días de la Semana que puede ser visitado" required/>
                <v-row class="mt-4">
                  <v-col cols="4" class="py-0">
                    <v-switch
                      v-model="horarioInfo.lunes"
                      inset
                      dense
                      class="mt-0 pt-1"
                      color="icono"
                      true-value="1"
                      false-value="0"
                      :error="validateDay"
                      @change="validateDay = false"
                    >
                      <template #label>
                        <span class="primary--text mt-n1">Lunes</span>
                      </template>
                    </v-switch>
                  </v-col>
                  <v-col cols="4" class="py-0">
                    <v-switch
                      v-model="horarioInfo.martes"
                      inset
                      dense
                      class="mt-0 pt-1"
                      color="icono"
                      true-value="1"
                      false-value="0"
                      :error="validateDay"
                      @change="validateDay = false"
                    >
                      <template #label>
                        <span class="primary--text mt-n1">Martes</span>
                      </template>
                    </v-switch>
                  </v-col>
                  <v-col cols="4" class="py-0">
                    <v-switch
                      v-model="horarioInfo.miercoles"
                      inset
                      dense
                      class="mt-0 pt-1"
                      color="icono"
                      true-value="1"
                      false-value="0"
                      :error="validateDay"
                      @change="validateDay = false"
                    >
                      <template #label>
                        <span class="primary--text mt-n1">Miércoles</span>
                      </template>
                    </v-switch>
                  </v-col>
                  <v-col cols="4" class="py-0">
                    <v-switch
                      v-model="horarioInfo.jueves"
                      inset
                      dense
                      class="mt-0 pt-1"
                      color="icono"
                      true-value="1"
                      false-value="0"
                      :error="validateDay"
                      @change="validateDay = false"
                    >
                      <template #label>
                        <span class="primary--text mt-n1">Jueves</span>
                      </template>
                    </v-switch>
                  </v-col>
                  <v-col cols="4" class="py-0">
                    <v-switch
                      v-model="horarioInfo.viernes"
                      inset
                      dense
                      class="mt-0 pt-1"
                      color="icono"
                      value="1"
                      false-value="0"
                      true-value="1"
                      :error="validateDay"
                      @change="validateDay = false"
                    >
                      <template #label>
                        <span class="primary--text mt-n1">Viernes</span>
                      </template>
                    </v-switch>
                  </v-col>
                  <v-col cols="4" class="py-0">
                    <v-switch
                      v-model="horarioInfo.sabado"
                      inset
                      dense
                      class="mt-0 pt-1"
                      color="icono"
                      true-value="1"
                      false-value="0"
                      :error="validateDay"
                      @change="validateDay = false"
                    >
                      <template #label>
                        <span class="primary--text mt-n1">Sábado</span>
                      </template>
                    </v-switch>
                  </v-col>
                  <v-col cols="4" class="py-0">
                    <v-switch
                      v-model="horarioInfo.domingo"
                      inset
                      dense
                      class="mt-0 pt-1"
                      color="icono"
                      true-value="1"
                      false-value="0"
                      :error="validateDay"
                      @change="validateDay = false"
                    >
                      <template #label>
                        <span class="primary--text mt-n1">Domingo</span>
                      </template>
                    </v-switch>
                  </v-col>
                  <v-col
                    v-if="validateDay"
                    cols="12"
                    class="py-0 my-0"
                  >
                    <span class="red--text px-0 caption">Debe seleccionar mínimo un día de la Semana</span>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </validation-observer>
        </v-card-text>
        <v-card-actions class="d-flex justify-end py-3">
          <v-btn
            tile
            outlined
            color="blue-grey"
            class="px-6 mx-3"
            :small="$vuetify.breakpoint.mobile"
        
            :to="{name: 'Cliente', params: { cod: co_cli }}"
          >
            Cancelar
          </v-btn>
          <v-btn
            tile
            color="success"
            class="px-8"
            :small="$vuetify.breakpoint.mobile"
            @click="addHorario"
          >
            <v-icon dark left>mdi-account-edit-outline</v-icon>
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
   
  </template>
  <script>
  import { get } from 'vuex-pathify'
  import moment from 'moment'
    export default {
    name:'ModalHorario',

    created() {
      this.getSellers();
    },

    components:{
       FechaInput: () => import(
        /* webpackChunkName: "fecha-input" */
        '@/widgets/FechaInput.vue'
      )
    },
    
    props:{
      value: Boolean,
      dataVisita:{
        type: Object,
        default: () => ({}),
      },
      dataVisitaPortafolio:{
        type: Boolean,
        default: () => (false),
      },
    },
    
    data () {
      return {
        selected: '',
        co_cli: this.$route.params.co_cli,
        portafolio: this.$route.params.portafolio,
        modalShow: this.value,
        loadingHorario: false,
        frecuencia: '',
        horarioInfo: {
          frecuencia: 'semanal',
          lunes: '0',
          martes: '0',
          miercoles: '0',
          jueves: '0',
          viernes: '0',
          sabado: '0',
          domingo: '0',
          fecha_inicio: moment().format('YYYY-MM-DD'),
        },
        sellersListOriginal: [],
        sellersList: [],
        days:[
          {day: 'Lunes', value: 'lunes'},
          {day: 'Martes', value: 'martes'},
          {day: 'Miércoles', value: 'mie'},
          {day: 'Jueves', value: 'jue'},
          {day: 'Viernes', value: 'vie'},
          {day: 'Sábado', value: 'sab'},
          {day: 'Domingo', value: 'dom'},
        ],
        validateDay: false,
        min_date_input: moment().format('YYYY-MM-DD'),
      }
    },
    /*
    watch: {
      modalShow (val, oldVal) {
        if (val === oldVal) return
  
        this.$emit('input', val)
      },
      value (val, oldVal) {
        if (val === oldVal) return
        //console.log('dataVisita: ',this.dataVisita);
        this.modalShow = val
        this.horarioInfo = Object.assign({},this.dataVisita);
      },
      // horarioInfo(value){
      //   this.validateDay = this.validateDiaSemana(value);
      // }
    },
    */
    methods: {
      cerrar () {
;       this.modalShow = false;
        this.horarioInfo = '';
        this.$refs.HORARIO_FORM.reset();
      },

        async getSellers() {
            this.loadingPermisos = true;
            await this.$api.get('users')
            .then((data) =>{
                this.sellersListOriginal = data.data.data;

                this.sellersListOriginal.map((seller) => {
                    if (seller.user != null){
                        this.sellersList.push(seller);
                        //console.log(seller);
                    }
                })
            })
            .catch((error) => {
                this.$root.$showAlert('Lo siento, hubo un error al intentar obtener los permisos.',
                error)
            });
            //console.log(this.sellersList.length);
            this.loadingPermisos = false;
        },
        
      validateDiaSemana (data) {
        let {frecuencia, id, co_cli, fecha_inicio, ...dias} = data;
        return Object.values(dias).length > 0 ? Object.values(dias).some(item => item != 0) : false;
      },
      async addHorario () {

        const valid = await this.$refs.HORARIO_FORM.validate();
        const validDay = this.validateDiaSemana(this.horarioInfo);
        this.validateDay = !validDay;

        this.horarioInfo.co_cli = this.co_cli;
        this.horarioInfo.portafolio = this.portafolio;
        this.horarioInfo.co_ven = this.selected.co_ven;

        if(this.selected.co_ven === undefined){
            this.loadingHorario = false;
            this.$root.$showAlert('Lo sentimos, debe escoger un vendedor para completar el proceso.',
                'error');
            return;
        };

        if(valid && validDay) {
          try {
            this.loadingHorario= true;
            /*
            delete this.horarioInfo.created_at;
            delete this.horarioInfo.updated_at;
            delete this.horarioInfo.ven_des;
            delete this.horarioInfo.id;
            */

            //console.log(this.horarioInfo); return;
            const {data} = await this.$api.post(`clientes/${this.horarioInfo.co_cli}/v2/visitas`, this.horarioInfo);
            
            //this.cerrar();
            this.$parent.$emit('change');
            this.$root.$showAlert('El horario del Cliente se actualizó Satisfactoriamente', 'success');
            this.$router.push({name: 'Cliente', params: { cod: this.co_cli, tab: 'datalles' }});
            //this.$router.push({name: 'Clientes'});
          } catch (error) {
            this.$root.$showAlert(
              'Lo sentimos, hubo un error al intentar actualizar el Horario de Visita.',
                'error'
              );
              this.cerrar();
          }
          this.loadingHorario = false;
          this.validateDay = false;
        }
      },
    },
    computed: {
      //id_cliente: get('route/params@cod'),
      co_ven: get('route/params@co_ven'),
    },
  }
  </script>
  <style lang="sass">
  .z-index-lg
    z-index: 1000 !important
  </style>
  