<template>
  <v-container
    id="clientes"
    fluid
    tag="section"
    class="pa-1"
  >
    <v-row>
      <v-col cols="12" sm="12" md="12" class="pt-5">
        <main-header>
        </main-header>
        <v-card tile>
          <v-card-text class="pa-0 ma-0">
            <v-row class="py-1 px-4">
              <!-- <v-col cols="12" sm="12" md="5" class="d-flex align-center">
              </v-col> -->
              <v-col cols="12" sm="12" class="pt-0 d-flex flex-wrap flex-row align-center">
                <div class="mr-2 mt-2" style="min-width: 350px;">
                  <search-expand 
                    v-model="searchClient" 
                    :load="loadingClients"
                    placeholder="Buscar Cliente"
                  />
                </div>
                <div class="mt-2">
                  <columns-views :columns="headersClient" /> 
                </div>    
                <v-btn
                  color="primary"
                  class="ml-2 mt-2"
                  outlined
                  small
                  @click="getClients"
                >               
                  <v-icon size="22" left>mdi-update</v-icon>             
                  Actualizar        
                </v-btn>  
                <btn-horario v-model="filtros.horario" />      
                <btn-geolocalizacion v-model="filtros.geo" />      
                <btn-estatus v-model="filtros.estatus" />      
              </v-col>
              <!-- <v-col 
                cols="12" 
                sm="4" 
                md="6" 
                class="d-flex align-center"
                :class="{'justify-end': $vuetify.breakpoint.smAndUp, 'justify-center': $vuetify.breakpoint.xsOnly }"
                v-if="$vuetify.breakpoint.smAndUp">     

              </v-col> -->
            </v-row>
            <v-divider class="mt-3" />
            <v-row class="pa-0 ma-0">
              <v-col cols="12" class="pa-0 ma-0">
                <data-clients 
                  :data="itemsClients"
                  :search="searchClient"
                  :headers="headersClient"
                  :loadingData="loadingClients"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: 'clientes',
  components: { 
    DataClients: () => import(
      /* webpackChunkName: "data-clients" */
      './Components/DataClients.vue'
    ),
    BtnHorario: () => import(
      /* webpackChunkName: "btn-horario" */
      './Components/Filtros/BtnHorario.vue'
    ),
    BtnGeolocalizacion: () => import(
      /* webpackChunkName: "btn-horario" */
      './Components/Filtros/BtnGeolocalizacion.vue'
    ),
    BtnEstatus: () => import(
      /* webpackChunkName: "btn-estatus" */
      './Components/Filtros/BtnEstatus.vue'
    ),
    columnsViews: () => import(
      /* webpackChunkName: "column-views" */
      '@/widgets/ColumnsViews'
    ),
    ViewMaps: () => import(
      /* webpackChunkName: "view-maps" */
      '@/widgets/ViewMaps'
    )
   },
  data: () => ({
    center: { lat: 8.955582, lng: -65.3220065 },
    searchClient: '',
    filtersClient: false,
    loadingClients: false,
    view_map: false,
    clients:[],
    filtros:{
      geo: '',
      estatus: '',
      horario: '',
    },
    headersClient: [
      { text: 'Cod.', value: 'co_cli', class: 'header-data', width: '100', active: true, },
      { text: 'Cliente', value: 'cli_des', class: 'header-data', active: true,},
      { text: 'Vendedor', value: 'ven_des', class: 'header-data', active: true,},
      { text: '', value: 'info', class: 'header-data', active: true, },       
      { text: '', value: 'actions', class: 'header-data', width: '80', active: true, },
    ],
  }),
  created(){
    this.getClients();
  },
  methods:{
    async getClients() {
      try {
        this.loadingClients = true;
        const { data } = await this.$api.get('clientes');
        this.clients = data.data;   
      } catch (error) {
        this.$root.$showAlert(
          'Lo siento, hubo un error al intentar obtener los Clientes.',
          'error'
        )
      }
      this.loadingClients = false;
    }
  },
  computed:{
    markers() {
      return this.clients.length > 0 ? this.clients.filter(cli => cli.lat != null).map(item => ({
        name: item.cli_des,
        position: {
          lat: parseFloat(item.lat.trim()),
          lng: parseFloat(item.lng.trim()),
        }
      })) : []
    },
    itemsClients () {
      let data = [];
      data = this.clients.map((item) => {
        return {
          ...item,
          horario: item.visita_programadas ? 'Frecuencia registrada' : 'Sin frecuencia',
          estatus: item.inactivo.toString(),
          geo: item.lat !== null && item.lng !== null ? '1' : '0',
        }
      })

      const CustomFiltersKeys = Object.keys(this.filtros)
      return data.filter((item) => {
        return CustomFiltersKeys.every(keyFiltro => {
          if(this.filtros[keyFiltro].length === 0) {
            return true;
          }
          return typeof this.filtros[keyFiltro] === 'object' 
            ? this.filtros[keyFiltro].includes(item[keyFiltro]) 
            : (this.filtros[keyFiltro].toString()).toLowerCase().includes((item[keyFiltro].toString()).toLowerCase())
        })
      })
    },
  }
}
</script>