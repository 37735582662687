<template>
<div>
  <v-card
    tile
    color="transparent"
    :flat="$vuetify.breakpoint.mobile || view === 'grid'"
  >
    <v-card-text class="pa-0">
      <v-data-iterator
        :items="data"
        item-key="co_art"
        :items-per-page="10"
        :search="search"
        :loading="loading"
        :disable-pagination="loading"
        :footer-props="{itemsPerPageText: 'Mostrar'}"
        :custom-filter="gridSearch"
        class="mt-2"
        v-if="$vuetify.breakpoint.mobile || view === 'grid'"
      >
        <template v-slot:default="{ items }">
          <v-row>
            <template v-for="(art,i) in items">
              <mobile-inventario
                :type="view"
                :articulo="art"
                :moneda="moneda"
                :pVenta="art[pventa]"
                :colorPrecio="colorPventa"
                :key="'mobileart-'+i">
                <template #action>
                  <v-btn
                    color="white"
                    text
                    :to="{name: 'Articulo', params: {cod: art.co_art.trim(), moneda: moneda === '$' ? 'usd' : 'bs'}}"
                  >
                    Ver articulo
                    <v-icon size="20" right>mdi-arrow-right</v-icon>
                  </v-btn>
                </template>
              </mobile-inventario>
            </template>
          </v-row>
        </template>
        <template #loading>
          <v-row>
            <v-col cols="12" sm="6" md="4" v-for="load in 3" :key="load">
              <v-skeleton-loader
                type="card"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
        </template>
      </v-data-iterator>
      <v-data-table
        :headers="dataheader"
        :items="data"
        :search="search"
        :loading="loading"
        :items-per-page="10"
        :disable-pagination="loading"
        :custom-filter="customSearch"
        loading-text="Obteniendo Data... Por favor espere."
        v-else
      >
        <!-- <template v-slot:header.co_art="{ header }">
          <span :class="{'font-weight-bold': sort === header.value }">{{header.text}}</span>
          <template v-if="sort === header.value">
            <v-btn icon small @click="changeSort">
              <v-icon
                small
                v-text="OrderDesc ? 'mdi-arrow-up-thick' : 'mdi-arrow-down-thick'"
              ></v-icon>
            </v-btn>
          </template>
        </template>
        <template v-slot:header.art_des="{ header }">
          <span :class="{'font-weight-bold': sort === header.value }">{{header.text}}</span>
           <template v-if="sort === header.value">
            <v-btn icon small @click="changeSort">
              <v-icon
                small
                v-text="OrderDesc ? 'mdi-arrow-up-thick' : 'mdi-arrow-down-thick'"
              ></v-icon>
            </v-btn>
          </template>
        </template>
        <template v-slot:header.prec_vta3="{ header }">
          <span class="text-center" :class="{'font-weight-bold': sort === header.value }">{{header.text}}</span>
           <template v-if="sort === header.value">
            <v-btn icon small @click="changeSort">
              <v-icon
                small
                v-text="OrderDesc ? 'mdi-arrow-up-thick' : 'mdi-arrow-down-thick'"
              ></v-icon>
            </v-btn>
          </template>
        </template>
        <template v-slot:header.stock_act="{ header }">
          <span :class="{'font-weight-bold': sort === header.value }">{{header.text}}</span>
           <template v-if="sort === header.value">
            <v-btn icon small @click="changeSort">
              <v-icon
                small
                v-text="OrderDesc ? 'mdi-arrow-up-thick' : 'mdi-arrow-down-thick'"
              ></v-icon>
            </v-btn>
          </template>
        </template> -->
        <template v-slot:item.art_des="{ item }">
          <div class="d-flex my-3">
            <div class="d-flex-inline">
              <v-avatar size="44" class="mr-2" rounded>
                <!-- <v-img
                  contain
                  :src="
                    require(`@/assets/Products/Product_${parseInt(Math.random() * (4 - 1) + 1)}.jpg`)"
                /> -->
                <v-img
                  contain
                  :src="`${dir_image + item.co_art}.jpg`"
                />
              </v-avatar>
            </div>
            <div class="d-flex flex-column justify-start">
              <span class="primary--text font-weight-medium" v-text="item.art_des" />
              <span class="text-subtitle-2" v-text="item.lin_des" />
            </div>
          </div>
        </template>
        <template v-slot:item.prec_vta3="{ item }">
          <div class="d-flex justify-center">
            <span class="font-weight-bold" :style="colorPventa">{{moneda}} {{item[pventa] | FormatCurrency}}</span>
          </div>
        </template>
        <template v-slot:item.actions="{ item }">
            <!-- v-if="$hasPermission('ruta-vendedores.clientes.frecuencia') || $hasPermission('ruta-vendedores.clientes.geolocalizacion')" -->
           <v-menu
            bottom
            left
            min-width="200"
            offset-y
            origin="top right"
            transition="scale-transition"
          >
            <template v-slot:activator="{ attrs, on }">
              <v-btn
                plain
                small
                color="icono"
                class="px-0"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon size="20" color="icono">mdi-dots-horizontal</v-icon>
              </v-btn>
            </template>
            <v-list
              class="py-0"
            >
              <v-list-item-group
                color="primary"
              >
                <template v-if="$hasPermission('inventario.editar')">
                  <v-list-item class="py-2" @click="actionImagen(item)">
                    <v-list-item-icon class="my-2 ml-1 mr-3 align-self-center">
                      <v-icon left color="blue-grey">mdi-image-multiple</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Editar Imagen</v-list-item-title>
                  </v-list-item>
                  <v-divider />
                </template>
                <v-list-item
                  class="py-2"
                   :to="{name: 'Articulo', params: {cod: item.co_art.trim(), moneda: moneda === '$' ? 'usd' : 'bs'}}"
                >
                  <v-list-item-icon class="my-2 ml-1 mr-3 align-self-center">
                    <v-icon left color="blue-grey">mdi-arrow-right</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Ver detalles</v-list-item-title>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
          <!-- <div class="actions--style text-right">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                  <v-btn
                  depressed
                    class="mx-2 pa-2"
                    min-width="23px"
                    height="31px"
                    v-bind="attrs"
                    v-on="on"
                    :to="{name: 'Articulo', params: {cod: item.co_art.trim(), moneda: moneda === '$' ? 'usd' : 'bs'}}"
                  >
                    <v-icon size="18">mdi-arrow-right</v-icon>
                  </v-btn>
              </template>
              <span>Ver Detalle</span>
            </v-tooltip>
          </div> -->
        </template>
        <template #loading>
        <v-skeleton-loader
            type="table-tbody"
          ></v-skeleton-loader>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
    <modal-imagenes
      v-model="toggleImagen"
      :product="infoProduct"
    />
</div>
</template>
<script>
export default {
  name: 'ListTable',
  components:{
    MobileInventario: () => import(
      /* webpackChunkName: "modal-account" */
      './MobileInventario.vue'
    ),
     ModalImagenes: () => import(
      /* webpackChunkName: "modal-imagenes" */
      './ModalImagenes.vue'
    ),
  },
  props:{
    headers: {
      type: Array,
      default: () => ([]),
    },
    data: {
      type: Array,
      default: () => ([]),
    },
    pagination: {
      type: Object,
      default: () => ({}),
    },
    search: String,
    loading: Boolean,
    view: String,
    moneda: String,
    sort: String,
    OrderDesc:{
      type: Boolean,
      default: false
    },
    pventa:{
      type: String,
      default: 'prec_vta3'
    }

  },
  data: () => ({
    optionsArt: {},
    dir_image: process.env.VUE_APP_BASE_INVENTARIO,
    colors_pventa:{
      prec_vta2: '#24404E',
      prec_vta3: '#00CAE3',
      prec_vta4: '#009688',
      prec_vta5: '#795548',
    },
    page: 2,
    toggleImagen: false,
    infoProduct: {}
  }),
  // watch:{
  //   optionsArt(value){
  //     this.$emit('change', value);
  //   }
  // },
  methods: {
    startPage(page) {
      this.$vuetify.goTo('number', 0);
    },
    changePag(page) {
      this.$emit('change', page);
    },
    changeSort() {
      this.$emit('changeOrder', !this.OrderDesc);
    },
    reemplazarAcentos (cadena) {
      var chars= {
        á: 'a', é: 'e', í: 'i', ó: 'o', ú: 'u',
        à: 'a', è: 'e', ì: 'i', ò: 'o', ù: 'u',
        Á: 'A', É: 'E', Í: 'I', Ó: 'O', Ú: 'U',
        À: 'A', È: 'E', Ì: 'I', Ò: 'O', Ù: 'U' }

      var expr=/[áàéèíìóòúùñ]/ig;
      return cadena.replace(expr, function (e) { return chars[e] });
    },
    customSearch(value, searchInput, item) {
      const convertSearch = this.reemplazarAcentos(searchInput)
      const convertValue = typeof value === 'string' ? this.reemplazarAcentos(value) : value

      return value != null &&
          searchInput != null &&
          typeof value === 'string' &&
          convertValue.toString().toLocaleUpperCase().indexOf(convertSearch.toString().toLocaleUpperCase()) !== -1
    },
    gridSearch(items, inputSearch) {
      return items.filter(item => Object.values(item).some(key => {
        if(key === 'art_des')
          console.log({key}, this.customSearch(key, inputSearch, item))

        return this.customSearch(key, inputSearch, item)
      }
      ));
    },
    actionImagen (item) {
      this.toggleImagen = true;
      this.infoProduct = { ...item }
    }
  },
  computed: {
    dataheader() {
      return this.headers.filter(item => item.active)
    },
    colorPventa() {
      return {
        color: `${this.colors_pventa[this.pventa]} !important`
      }
    }
  }
}
</script>
