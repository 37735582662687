import api from '../util/request.js'

export const logs = async ({dateIni, dateEnd, ambiente}) => {
  try {
    const { data } = await api.get(`logs/${ambiente === 'web' ? ambiente : ''}`, {
      params: {
        fecha_inicio: dateIni,
        fecha_final: dateEnd,
      },
    })
    return data.data
  } catch (error) {
    return Promise.reject(error)
  }
}
